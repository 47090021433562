import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { RefundConfirmWidget } from "@/widgets/refund/RefundConfirmWidget";

export function RefundConfirm() {
  const { _id } = useParams<{ _id: string }>();

  useEffect(() => {
    document.title = "Буцаалтын хуудас биелүүлэх";
  }, []);

  return (
    <div className="page">
      <RefundConfirmWidget _id={_id} />
    </div>
  );
}
