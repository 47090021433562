import moment from "moment";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  Space,
  Button,
  Table,
  Popconfirm,
  Tag,
  Tooltip,
  DatePicker,
  Select,
  Popover,
} from "antd";
import Search from "antd/lib/input/Search";
import {
  EyeOutlined,
  EditOutlined,
  UserOutlined,
  DeleteOutlined,
  UnlockOutlined,
  WalletOutlined,
  FileTextOutlined,
  PlusCircleOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { debounce } from "ts-debounce";

import { SearchCtx } from "@/context/UserSearchProvider";
import { Role, Status, User, UserType } from "@/graphql";

import { useUsers } from "./useUsers";
import { AddOrderWidget } from "../order/modal/create";

import { Option } from "./modal/create";
import { UnlockAccountWidget } from "./modal/recoverOtp";
import { EditUserPortfolioWidget } from "./modal/portfolio";

const { RangePicker } = DatePicker;

export function UserWidget() {
  const history = useHistory();

  const [orderUser, setOrderUser] = useState<User | null>(null);
  const [showOrder, setShowOrder] = useState<boolean>(false);
  const [portfolioUser, setPortfolioUser] = useState<User | null>(null);
  const [showPortfolio, setShowPortfolio] = useState<boolean>(false);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);

  const [recoverOtpUser, setRecoverOtpUser] = useState<User | null>(null);

  const {
    type,
    role,
    search,
    status,
    dateRange,
    setType,
    setRole,
    setSearch,
    setStatus,
    setDateRange,
  } = useContext(SearchCtx);
  const {
    data,
    loading,
    page,
    limit,
    setPage,
    setLimit,
    getUsers,
    deleteUser,
    resetBalance,
  } = useUsers();

  const handleChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearch(value);
      setPage(0);
    },
    500
  );

  return (
    <>
      <EditUserPortfolioWidget
        user={portfolioUser}
        show={showPortfolio}
        setShow={setShowPortfolio}
      />
      <AddOrderWidget
        user={orderUser}
        show={showOrder}
        setShow={setShowOrder}
        getOrders={getUsers}
      />
      <UnlockAccountWidget user={recoverOtpUser} setUser={setRecoverOtpUser} />
      <div className="page-header">
        <h3>Харилцагчдын жагсаалт</h3>
        <div className="page-header-buttons">
          <Popover
            placement="bottom"
            content={
              <div style={{ display: "flex" }}>
                <Space style={{ marginRight: 10 }}>
                  <RangePicker
                    allowClear
                    defaultValue={
                      dateRange
                        ? [
                            moment(dateRange.startDate),
                            moment(dateRange.endDate),
                          ]
                        : null
                    }
                    onChange={(values) => {
                      const [startDate, endDate] = values ?? [];
                      setDateRange(
                        startDate && endDate
                          ? {
                              startDate: startDate?.toDate().getTime(),
                              endDate: endDate.toDate().getTime(),
                            }
                          : null
                      );
                      setPage(0);
                    }}
                    disabled={loading}
                  />
                </Space>
                <Space style={{ marginRight: 10, display: "block" }}>
                  <Select
                    showSearch
                    allowClear
                    style={{ width: 180 }}
                    placeholder="Төрлөөр хайх"
                    onChange={(e) => {
                      setType(e);
                      setPage(0);
                    }}
                    defaultValue={type}
                  >
                    {Object.values(UserType).map((type) => (
                      <Option key={type} value={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Space>
                <Space style={{ marginRight: 10, display: "block" }}>
                  <Select
                    showSearch
                    allowClear
                    style={{ width: 180 }}
                    placeholder="Эрхээр хайх"
                    defaultValue={role}
                    onChange={(e) => {
                      setRole(e);
                      setPage(0);
                    }}
                  >
                    {Object.values(Role).map((role) => (
                      <Option key={role} value={role}>
                        {role}
                      </Option>
                    ))}
                  </Select>
                </Space>
              </div>
            }
            title="Дэлгэрэнгүй хайлт"
            trigger="click"
            open={showAdvancedFilter}
            onOpenChange={() => setShowAdvancedFilter(!showAdvancedFilter)}
          >
            <Button type="dashed" icon={<FileSearchOutlined />}>
              Дэлгэрэнгүй хайлт
            </Button>
          </Popover>

          <Space style={{ marginRight: 10, marginLeft: 10, display: "block" }}>
            <Select
              showSearch
              allowClear
              style={{ width: 200 }}
              placeholder="KYC статусаар хайх"
              defaultValue={status}
              onChange={(e) => {
                setStatus(e);
                setPage(0);
              }}
            >
              {Object.values(Status).map((type) => (
                <Option key={type} value={type}>
                  {type === Status.Confirmed
                    ? "БАТАЛГААЖСАН"
                    : type === Status.Notrequested
                    ? "ХҮСЭЛТ ИЛГЭЭГЭЭГҮЙ"
                    : type === Status.Pending
                    ? "ХҮЛЭЭГДЭЖ БАЙНА"
                    : "ТАТГАЛЗСАН "}
                </Option>
              ))}
            </Select>
          </Space>
          <Space style={{ marginRight: 10 }}>
            <Search
              allowClear
              defaultValue={search}
              placeholder="Хайх утгаа оруулна уу."
              onChange={handleChange}
            />
          </Space>
          <Space>
            <Button
              type="primary"
              icon={<UserOutlined />}
              onClick={() => history.push("/users/add")}
            >
              Хэрэглэгч нэмэх
            </Button>
          </Space>
        </div>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data?.users ?? []}
            size="small"
            loading={loading}
            pagination={{
              current: page + 1,
              pageSize: limit,
              total: Number(data?.totalPages) * limit,
              showSizeChanger: true,
              onChange: (page, pageSize) => {
                setPage(page - 1);
                setLimit(pageSize);
              },
            }}
            bordered
            columns={[
              {
                title: "Утасны дугаар",
                dataIndex: "phoneNo",
                key: "phoneNo",
                render: (text) => (
                  <span className="text_ellipse">{text ? `${text}` : "-"}</span>
                ),
              },
              {
                title: "Овог",
                dataIndex: "lastName",
                key: "lastName",
                render: (text) => (
                  <span className="text_ellipse">{text ?? "-"}</span>
                ),
              },
              {
                title: "Нэр",
                dataIndex: "firstName",
                key: "firstName",
                render: (text) => (
                  <span className="text_ellipse">{text ?? "-"}</span>
                ),
              },
              {
                title: "Цахим хаяг",
                dataIndex: "email",
                key: "email",
                render: (text) => <span>{text ?? "-"}</span>,
              },
              {
                title: "KYC төлөв",
                dataIndex: "kycConfirmed",
                key: "kycConfirmed",
                render: (_, record) => {
                  if (record.kycInfo.status === Status.Confirmed) {
                    return <Tag color="green">БАТАЛГААЖСАН</Tag>;
                  }
                  if (record.kycInfo.status === Status.Notrequested) {
                    return <Tag>ХҮСЭЛТ ИЛГЭЭГЭЭГҮЙ</Tag>;
                  }
                  if (record.kycInfo.status === Status.Pending) {
                    return <Tag color="blue">ХҮЛЭЭГДЭЖ БАЙНА</Tag>;
                  }
                  if (record.kycInfo.status === Status.Rejected) {
                    return <Tag color="red">ТАТГАЛЗСАН</Tag>;
                  }
                },
              },
              {
                title: "Хэрэглэгчийн эрх",
                dataIndex: "role",
                key: "role",
                render: (text) => <Tag>{text}</Tag>,
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: 250,
                render: (_, record) => {
                  return (
                    <Space
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Link to={`/users/group-details/${record._id}`}>
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          icon={<EyeOutlined />}
                        />
                      </Link>
                      <Link to={`/users/edit/${record._id}`}>
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          icon={<EditOutlined />}
                        />
                      </Link>
                      <Popconfirm
                        disabled={loading}
                        title="Та устгахдаа итгэлтэй байна уу?"
                        okText="Тийм"
                        cancelText="Үгүй"
                        onConfirm={() => {
                          if (record._id) {
                            deleteUser(record._id);
                          }
                        }}
                      >
                        <Button
                          size="middle"
                          shape="circle"
                          disabled={loading}
                          danger
                          type="primary"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                      <Popconfirm
                        disabled={loading}
                        title="Та хэрэглэгчийн данс устгахдаа итгэлтэй байна уу?"
                        okText="Тийм"
                        cancelText="Үгүй"
                        onConfirm={() => {
                          if (record._id) {
                            resetBalance(record._id);
                          }
                        }}
                      >
                        <Button
                          size="middle"
                          shape="circle"
                          disabled={loading}
                          danger
                          type="primary"
                          icon={<WalletOutlined />}
                        />
                      </Popconfirm>
                      <Tooltip title="Захиалга нэмэх">
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          onClick={() => {
                            // @ts-expect-error Removing unused fields from schema
                            setOrderUser(record);
                            setShowOrder(true);
                          }}
                          icon={<PlusCircleOutlined />}
                        />
                      </Tooltip>
                      <Tooltip title="Багц өөрчлөх">
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          onClick={() => {
                            // @ts-expect-error Removing unused fields from schema
                            setPortfolioUser(record);
                            setShowPortfolio(true);
                          }}
                          icon={<FileTextOutlined />}
                        />
                      </Tooltip>
                      <Tooltip title="OTP сэргээх">
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          // @ts-expect-error Removing unused fields from schema
                          onClick={() => setRecoverOtpUser(record)}
                          icon={<UnlockOutlined />}
                        />
                      </Tooltip>
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
}
