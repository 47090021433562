import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Space } from "antd";
import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";

import useEditFaq from "./useEditFaq";

export const { TextArea } = Input;

export const EditFAQWidget = () => {
  const history = useHistory();
  const { data, loading, form, editFaq } = useEditFaq();
  useEffect(() => {
    if (data) {
      form.setFields([
        {
          name: "question",
          value: data?.question,
        },
        {
          name: "answer",
          value: data?.answer,
        },
      ]);
    }
  }, [form, data]);

  return (
    <>
      <div className="page-header">
        <h3>Асуулт хариулт засах</h3>
        <Space>
          <Button
            type="dashed"
            icon={<ArrowLeftOutlined />}
            onClick={() => history.push("/faqs")}
          >
            Буцах
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => form.validateFields().then(editFaq)}
          >
            Хадгалах
          </Button>
        </Space>
      </div>
      <div className="page-body">
        <Form
          form={form}
          onFinish={editFaq}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item label="Асуулт" name="question" required>
            <TextArea disabled={loading} />
          </Form.Item>
          <Form.Item label="Хариулт" name="answer" required>
            <TextArea disabled={loading} />
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
