import moment from "moment";
import { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
  Spin,
} from "antd";

import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import sdk from "@/sdk";
import { PrepareDividendReportMutationVariables } from "@/graphql";
import {
  PendingDividendState,
  DistributeDividendsModal,
} from "./DistributeDividendsModal";

export function CreateStockDividendPage() {
  const history = useHistory();
  const { data: stocks, isLoading: isLoadingStocks } = useQuery({
    queryKey: ["stocks"],
    queryFn: () => sdk.getAllStocksList(),
    select: (data) => data.getStocks.stocks,
  });

  const {
    mutate: prepareDividendReport,
    isLoading: isPreparingDividendReport,
  } = useMutation({
    mutationFn: (data: PrepareDividendReportMutationVariables) =>
      sdk.prepareDividendReport(data),
    onSuccess: (data) => window.open(data.prepareDividendReport.reportUrl),
  });

  const [pendingDividendState, setPendingDividendState] =
    useState<PendingDividendState | null>(null);

  const [form] = Form.useForm();
  return (
    <div className="page">
      <DistributeDividendsModal
        pendingDividend={pendingDividendState}
        onClose={() => setPendingDividendState(null)}
      />
      <div className="page-header">
        <h3>Хувьцааны ноогдол ашиг тараах</h3>
        <Button
          type="dashed"
          icon={<ArrowLeftOutlined />}
          onClick={() => {
            history.replace("/stocks/dividends");
          }}
        >
          Буцах
        </Button>
      </div>
      <main className="page-body">
        <Form
          style={{ marginTop: 12 }}
          form={form}
          onFinish={(values) =>
            prepareDividendReport({
              stockId: values.stockId,
              recordDate: values.recordDate.format("YYYY-MM-DD"),
              dividend: values.amount,
            })
          }
        >
          <Form.Item label="Хувьцаа" name="stockId" required>
            <Select
              disabled={isPreparingDividendReport}
              notFoundContent={
                isLoadingStocks ? <Spin size="small" /> : undefined
              }
            >
              {stocks?.map((stock) => (
                <Select.Option key={stock._id} value={stock._id}>
                  {stock.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Тасалбар болгох огноо" name="recordDate" required>
            <DatePicker
              disabledDate={(date) => date.isAfter(moment())}
              disabled={isPreparingDividendReport}
            />
          </Form.Item>
          <Form.Item label="Нэгж хувьцаанд ноогдох дүн" name="amount" required>
            <InputNumber
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              addonAfter="$"
              disabled={isPreparingDividendReport}
            />
          </Form.Item>
          <Form.Item>
            <Row gutter={6}>
              <Col>
                <Button
                  type="dashed"
                  htmlType="submit"
                  loading={isPreparingDividendReport}
                >
                  Урьдчилсан тайлан үүсгэх
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="button"
                  onClick={async () => {
                    const { amount, recordDate, ...state } =
                      await form.validateFields();
                    setPendingDividendState({
                      ...state,
                      dividend: amount,
                      recordDate: recordDate.format("YYYY-MM-DD"),
                    });
                  }}
                >
                  Ноогдол ашиг тараах
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </main>
    </div>
  );
}
