import { Button, Form, Modal } from "antd";
import { Dispatch } from "react";
import { User } from "../../../../graphql";
import { useRecoverOtp } from "./useRecoverOtp";

export const UnlockAccountWidget = ({
  user,
  setUser,
}: {
  user: User | null;
  setUser: Dispatch<User | null>;
}) => {
  const { loading, recoverOtp } = useRecoverOtp({
    username: user?.phoneNo ?? user?.email ?? "",
  });
  return (
    <Modal
      width={500}
      title="Хэрэглэгч сэргээх"
      visible={!!user}
      onCancel={() => setUser(null)}
      footer={[
        <Button key="back" disabled={loading} onClick={() => setUser(null)}>
          Болих
        </Button>,
        <Button
          key="submit"
          disabled={loading}
          type="primary"
          onClick={() => recoverOtp()}
        >
          Сэргээх
        </Button>,
      ]}
    >
      <Form layout="vertical" autoComplete="off">
        <Form.Item label="Хэрэглэгч" required>
          <span>
            {user?.phoneNo} - {user?.email}
          </span>
        </Form.Item>
      </Form>
    </Modal>
  );
};
