import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import BlueDivider from "../../../components/BlueDivider";

import { ImagePicker } from "../../../components/ImagePicker";
import { Image, StockType } from "../../../graphql";
import { Option } from "../../user/modal/create";
import { useEditStock } from "../edit/useEditStock";

import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const EditStockWidget = ({ _id }: { _id: string }) => {
  const { form, image, loading, editStock, setImage } = useEditStock({
    selectedStockId: _id!,
  });
  return (
    <>
      <div className="page-header">
        <h3>Үнэт цаас нэмэх</h3>
        <div className="page-header-buttons">
          <Link to="/stocks">
            <Button icon={<ArrowLeftOutlined />} className="back-button">
              Буцах
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-body">
        <Form
          form={form}
          onFinish={editStock}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={11}>
              <BlueDivider text="Мэдээлэл" styles={{ marginBottom: "30px" }} />
              <Form.Item
                label="Нэр (Монгол)"
                name="name_mn"
                rules={[{ required: true, message: "Нэр оруулна уу " }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Нэр (Англи)"
                name="name_en"
                rules={[{ required: true, message: "Нэр оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Тайлбар (Монгол)"
                name="description_mn"
                rules={[{ required: true, message: "Тайлбар оруулна уу" }]}
              >
                <Input.TextArea disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Тайлбар (Англи)"
                name="description_en"
                rules={[{ required: true, message: "Тайлбар оруулна уу" }]}
              >
                <Input.TextArea disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Дэлгэрэнгүй холбоос"
                name="url"
                rules={[
                  {
                    required: false,
                    message: "Дэлгэрэнгүй холбоос оруулна уу",
                  },
                ]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Symbol"
                name="symbol"
                rules={[{ required: true, message: "Symbol оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Төрөл"
                name="type"
                rules={[{ required: true, message: "Төрөл оруулна уу" }]}
              >
                <Select disabled={loading} loading={loading}>
                  {Object.values(StockType).map((stockType) => (
                    <Option key={stockType} value={stockType}>
                      {stockType}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Button
                key="submit"
                icon={<PlusOutlined />}
                disabled={loading}
                type="primary"
                style={{ marginTop: "65px" }}
                onClick={() => form.validateFields().then(editStock)}
              >
                Үнэт цаас нэмэх
              </Button>
            </Col>
            <Col
              span={1}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Divider type="vertical" style={{ height: "100%" }}></Divider>
            </Col>
            <Col span={11}>
              <BlueDivider text="Зураг" styles={{ marginBottom: "30px" }} />
              <ImagePicker
                setImage={setImage}
                image={image}
                handler={(value: Image) => setImage({ path: value.path })}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};
