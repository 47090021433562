import { Dispatch } from "react";

import { Col, Modal, Row } from "antd";
import { WithdrawalRequest } from "@/graphql";

type RefundProps = {
  user: WithdrawalRequest | null;
  setUser: Dispatch<WithdrawalRequest | null>;
};

export function RefundUserDetailModal({ setUser, user }: RefundProps) {
  return (
    <Modal
      title="Хэрэглэгчийн буцаалтын хүсэлт"
      open={!!user}
      onCancel={() => setUser(null)}
      onOk={() => setUser(null)}
    >
      <Col>
        <div style={{ marginBottom: "20px" }}>
          <Row
            style={{
              marginBottom: 10,
              fontSize: 13,
              paddingBottom: 5,
              textTransform: "uppercase",
              borderBottom: "solid 1px #ededed",
              fontWeight: "bold",
            }}
          >
            Хувьцаа
          </Row>
          {!!user?.stocks && user.stocks.length > 0 ? (
            user.stocks.map((value, index) => (
              <Row
                style={{
                  background: "#f6f6f6",
                  border: "solid 1px #ededed",
                  width: "100%",
                  justifyContent: "space-between",
                  borderRadius: 8,
                  marginBottom: 4,
                  paddingLeft: 10,
                  paddingRight: 10,
                  height: 50,
                  alignItems: "center",
                }}
                key={index}
              >
                <Col style={{ color: "#000", fontSize: 14, fontWeight: "600" }}>
                  {value.stock.name}
                </Col>

                <Col style={{ color: "red", fontSize: 14, fontWeight: "600" }}>
                  {value.quantity.toFixed(4)}
                </Col>
              </Row>
            ))
          ) : (
            <div>No data</div>
          )}
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Row
            style={{
              marginBottom: 10,
              fontSize: 13,
              paddingBottom: 5,
              textTransform: "uppercase",
              borderBottom: "solid 1px #ededed",
              fontWeight: "bold",
            }}
          >
            Үл хөдлөх
          </Row>
          {!!user?.estate && user.estate.length > 0 ? (
            user.estate.map((value, index) => (
              <Row
                style={{
                  background: "#00000050",
                  width: "100%",
                  justifyContent: "space-between",
                  borderRadius: 8,
                  marginBottom: 4,
                  paddingLeft: 4,
                  paddingRight: 4,
                  height: 50,
                  alignItems: "center",
                }}
                key={index}
              >
                <Col style={{ color: "#FFF", fontSize: 14, fontWeight: "600" }}>
                  {value.estate.bankName}
                </Col>
                <Col style={{ color: "red", fontSize: 14, fontWeight: "600" }}>
                  <Row>{value.amount.toFixed(2)}</Row>
                </Col>
              </Row>
            ))
          ) : (
            <div>No data</div>
          )}
        </div>
        <div style={{ marginBottom: "20px" }}>
          <Row
            style={{
              marginBottom: 10,
              fontSize: 13,
              paddingBottom: 5,
              textTransform: "uppercase",
              borderBottom: "solid 1px #ededed",
              fontWeight: "bold",
            }}
          >
            Бонд
          </Row>
          {!!user?.bonds && user.bonds.length > 0 ? (
            user?.bonds.map((value, index) => (
              <Row
                style={{
                  background: "#00000050",
                  width: "100%",
                  justifyContent: "space-between",
                  borderRadius: 8,
                  marginBottom: 4,
                  paddingLeft: 4,
                  paddingRight: 4,
                  height: 50,
                  alignItems: "center",
                }}
                key={index}
              >
                <Col style={{ color: "#FFF", fontSize: 14, fontWeight: "600" }}>
                  {value.bond.name}
                </Col>
                <Col style={{ color: "red", fontSize: 14, fontWeight: "600" }}>
                  <Row>{value.amount.toFixed(2)}</Row>
                </Col>
              </Row>
            ))
          ) : (
            <div>No data</div>
          )}
        </div>
      </Col>
    </Modal>
  );
}
