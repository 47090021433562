import {
  Input,
  message,
  Form,
  Button,
  DatePicker,
  Popconfirm,
  Col,
  Space,
  Row,
  InputNumber,
} from "antd";
import moment from "moment";
import { Dispatch, useCallback, useEffect, useState } from "react";
import {
  PlusOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { ChooseSaving } from "../../../components/ChooseSaving";
import { Currency, GetOrderListQuery, GetSavingsQuery } from "../../../graphql";
import { formatInput } from "../../../utils/formatters";

export type SavingForm = {
  _id?: string;
  accountNo: string;
  bankName: string;
  currency: Currency;
  balance: number;
  interestRate: number;
  amount: number;
  fee: number;
  duration: string;
};

const OrderConfirmSavings = ({
  data,
  next,
  back,
  savingForm,
  savingsData,
  setSavingForm,
}: {
  savingsData: GetSavingsQuery["getSavings"] | undefined;
  data: GetOrderListQuery["getOrderList"] | undefined;
  savingForm: SavingForm[];
  setSavingForm: Dispatch<SavingForm[]>;
  next: () => void;
  back: () => void;
}) => {
  const [savings, setSavings] = useState<SavingForm[]>();
  const [isChooseSaving, setIsChooseSaving] = useState(false);

  useEffect(() => {
    if (savingForm) setSavings(savingForm);
  }, [savingForm]);

  const addSaving = useCallback(
    (param: SavingForm) => {
      if (savings) {
        const finded = savings?.find((b) => b?._id === param?._id);
        if (finded) {
          message.warn("Сонгогдсон бонд байна.", 2);
          return;
        } else {
          setSavings([...savings!, param]);
        }
      } else {
        setSavings([param]);
      }
    },
    [savings]
  );

  const deleteSaving = (id: string | undefined) => {
    if (id && savings && savings.length !== 0) {
      const result = savings?.filter((b) => b._id !== id);
      setSavings([...result]);
    }
  };

  const handleChange = useCallback(
    (index: number, value: string, name: string) => {
      if (savings) {
        if (name === "amount") {
          let result = [...savings];
          result[index].amount = Number(value);
          setSavings(result);
        }
        if (name === "fee") {
          let result = [...savings];
          result[index].fee = Number(value);
          setSavings(result);
        }
      }
    },
    [savings]
  );

  const onBack = useCallback(() => {
    if (savings) setSavingForm(savings);
    back();
  }, [back, savings, setSavingForm]);

  const submit = useCallback(() => {
    if (savings && savings.length !== 0) {
      setSavingForm(savings);
    }
    next();
  }, [next, savings, setSavingForm]);

  return (
    <div style={{ padding: "24px 0 0 0" }}>
      <ChooseSaving
        show={isChooseSaving}
        setShow={setIsChooseSaving}
        savings={savingsData}
        submit={(saving: SavingForm) => addSaving(saving)}
      />
      <Row gutter={[48, 24]}>
        <Col span={24}>
          <Space align="end">
            <div>
              <p>Нийт худалдаж авах үнэ</p>
              <Input
                value={formatInput({
                  balance: data?.totalPurchases.saving.amount ?? 0,
                })}
                disabled={true}
              />
            </div>
            <Button
              onClick={() => setIsChooseSaving(true)}
              icon={<PlusOutlined />}
              type="primary"
            >
              Үл хөдлөх хөрөнгө нэмэх
            </Button>
          </Space>
        </Col>
        {savings?.map((deposit, index) => (
          <Col md={8} lg={6}>
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <p style={{ margin: "0 0 5px 0" }}>Банкны нэр</p>
                <Input value={deposit?.bankName ?? "хоосон"} disabled={true} />
              </Col>
              <Col span={24}>
                <p style={{ margin: "0 0 5px 0" }}>Дансны дугаар</p>
                <Input value={deposit?.accountNo ?? "хоосон"} disabled={true} />
              </Col>
              <Col span={24}>
                <p style={{ margin: "0 0 5px 0" }}>Хүү (%)</p>
                <Input
                  value={deposit?.interestRate.toFixed(2) ?? 0}
                  disabled={true}
                />
              </Col>
              <Col span={24}>
                <p style={{ margin: "0 0 5px 0" }}>Худалдаж авсан үнэ</p>
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  value={deposit?.amount}
                  onChange={(value) =>
                    handleChange(index, value?.toString() ?? "0", "amount")
                  }
                />
              </Col>
              <Col span={24}>
                <p style={{ margin: "0 0 5px 0" }}>Шимтгэл дүн</p>
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  value={deposit?.fee}
                  onChange={(value) =>
                    handleChange(index, value?.toString() ?? "0", "fee")
                  }
                />
              </Col>
              <Col span={24} style={{ textAlign: "right" }}>
                <Popconfirm
                  title="Та устгахдаа итгэлтэй байна уу?"
                  okText="Тийм"
                  cancelText="Үгүй"
                  onConfirm={() => {
                    deleteSaving(deposit._id);
                  }}
                >
                  <Button danger type="primary" icon={<DeleteOutlined />}>
                    Устгах
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        ))}
        <Col span={24} style={{ textAlign: "right" }}>
          <Form.Item>
            <Space>
              <Button
                type="dashed"
                onClick={onBack}
                icon={<ArrowLeftOutlined />}
              >
                Буцах
              </Button>
              <Button type="primary" onClick={submit}>
                Үргэлжлүүлэх
                <ArrowRightOutlined />
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
export default OrderConfirmSavings;
