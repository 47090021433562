import { useCallback, useEffect, useState } from "react";
import { GetOrderListQuery } from "../../graphql";
import sdk from "../../sdk";

export const useGetOrderList = ({ _id }: { _id: string }) => {
  const [data, setData] = useState<GetOrderListQuery["getOrderList"]>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const getOrderList = useCallback(() => {
    setLoading(true);
    sdk
      .getOrderList({ _id })
      .then((data) => setData(data.getOrderList))
      .catch((err) => {
        setError(err.response?.errors[0]?.message);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [_id]);

  useEffect(() => {
    getOrderList();
  }, [getOrderList]);

  return {
    data,
    loading,
    error,
  };
};
