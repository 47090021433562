import moment from "moment";
import invariant from "ts-invariant";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { Button, Col, Divider, Row, Space, Table } from "antd";
import { ArrowLeftOutlined, EyeOutlined } from "@ant-design/icons";

import { FullLoading } from "@/components/FullLoading";
import { formatBalance } from "@/utils/formatters";
import { useSearchParam } from "@/utils/url-state";
import { OrderType, PurchaseDataFragment } from "@/graphql";

import { HistoryModal } from "./historyModal";
import { useGetBalance } from "./useGetBalance";

export function BalanceHistoryWidget({ _id }: { _id: string }) {
  const { loading, data, error } = useGetBalance({ _id });

  const [purchaseId, { set: setPurchaseId, unset: unsetPurchaseId }] =
    useSearchParam("purchase");
  const activePurchase = useMemo<PurchaseDataFragment | null>(() => {
    if (!data) {
      return null;
    }
    if (!purchaseId) {
      return null;
    }
    return (
      data.balanceHistory.find((purchase) => purchase._id === purchaseId) ??
      null
    );
  }, [data, purchaseId]);
  return (
    <>
      <HistoryModal data={activePurchase} onClose={unsetPurchaseId} />
      <div className="page-header">
        <h3>
          {data &&
            `${data?.user.phoneNo} (${data?.user.chosenPortfolio?.index}-р багц) -
          Дансны түүх`}
        </h3>
        <Space>
          <Link to="/balances">
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Буцах
            </Button>
          </Link>
        </Space>
      </div>
      {loading}
      <div className="page-body">
        <Row justify="center">
          {loading ? (
            <FullLoading />
          ) : (
            <>
              <Col span={6}>
                <span className="question_title">Нийт хөрөнгийн дүн</span>
                <p>{formatBalance({ balance: data?.netAssetValue ?? 0 })} ₮</p>
              </Col>
              <Col span={6}>
                <span className="question_title">Нийт бэлэн мөнгө</span>
                <p>{formatBalance({ balance: data?.cashAmount ?? 0 })} ₮</p>
              </Col>
              <Col span={6}>
                <span className="question_title">Битүүмжлэгдсэн дүн</span>
                <p>{formatBalance({ balance: data?.holdAmount ?? 0 })} ₮</p>
              </Col>
              <Col span={6}>
                <span className="question_title">Нийт шимтгэлийн дүн</span>
                <p>{formatBalance({ balance: data?.fee ?? 0 })} ₮</p>
              </Col>
              <Col span={24}>
                <Divider orientation="left">Захиалгын түүх</Divider>
              </Col>
              <Col span={24}>
                <Table
                  pagination={false}
                  dataSource={data?.orderHistory?.reverse() ?? []}
                  loading={loading}
                  size="small"
                  bordered
                  columns={[
                    {
                      title: "Өмнөх үлдэгдэл",
                      dataIndex: "beforeBalance",
                      key: "beforeBalance",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "Захиалгын дүн",
                      dataIndex: "amount",
                      key: "amount",
                      render: (text, record) => (
                        <span
                          style={{
                            color:
                              record.type === OrderType.Income
                                ? "green"
                                : "red",
                          }}
                        >
                          {record.type === OrderType.Income ? "+" : "-"}{" "}
                          {formatBalance({ balance: text })} ₮
                        </span>
                      ),
                    },
                    {
                      title: "Дараах үлдэгдэл",
                      dataIndex: "afterBalance",
                      key: "afterBalance",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "Огноо",
                      dataIndex: "createdAt",
                      key: "createdAt",
                      render: (text) => (
                        <span>
                          {moment(text).format("YYYY/MM/DD HH:mm:ss")}
                        </span>
                      ),
                    },
                    {
                      title: "Төлөв",
                      dataIndex: "status",
                      key: "status",
                    },
                  ]}
                />
              </Col>
              <Col span={24}>
                <Divider orientation="left">Дансны түүх</Divider>
              </Col>
              <Col span={24}>
                <Table
                  pagination={false}
                  dataSource={data?.balanceHistory ?? []}
                  loading={loading}
                  size="small"
                  bordered
                  columns={[
                    {
                      title: "Дансны дүн",
                      dataIndex: "title",
                      key: "title",
                    },
                    {
                      title: "Огноо",
                      dataIndex: "createdAt",
                      key: "createdAt",
                      render: (text) => (
                        <span>
                          {moment(text).format("YYYY/MM/DD HH:mm:ss")}
                        </span>
                      ),
                    },
                    {
                      title: "Хөрөнгө оруулсан дүн",
                      dataIndex: "principleAmount",
                      key: "principleAmount",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "Шимтгэл",
                      dataIndex: "fee",
                      key: "fee",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      width: 50,
                      dataIndex: "button",
                      key: "button",
                      render: (_, record) => (
                        <Button
                          type="primary"
                          shape="circle"
                          onClick={() => {
                            invariant(record._id, "record._id is required");
                            setPurchaseId(record._id);
                          }}
                        >
                          <EyeOutlined />
                        </Button>
                      ),
                    },
                  ]}
                />
              </Col>
            </>
          )}
        </Row>
      </div>
      {error}
    </>
  );
}
