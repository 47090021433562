import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { GetImagesQuery } from "../../graphql";
import sdk from "../../sdk";

export const useImages = () => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetImagesQuery>();

  const getImages = useCallback(() => {
    setLoading(true);
    sdk
      .getImages({
        page,
        limit: 30,
      })
      .then((images) => setData(images))
      .catch((err) => {
        setLoading(false);
        message.warn(err);
      })
      .finally(() => setLoading(false));
  }, [page]);
  const deleteImage = (id: string) => {
    sdk
      .deleteImage({ _id: id })
      .then(() => {
        message.success("Амжилттай устгагдлаа");
        getImages();
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getImages();
  }, [getImages, setPage]);
  return {
    loading,
    data: data?.getImages,
    page,
    setPage,
    getImages,
    deleteImage,
  };
};
