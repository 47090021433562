import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
} from "antd";
import { Dispatch } from "react";
import { Role, UserType } from "../../../graphql";
import { useEditUser } from "./useEditUser";

export const { Option } = Select;
const { TabPane } = Tabs;

export const AddUserWidget = ({
  show,
  getUsers,
  setShow,
}: {
  show: boolean;
  getUsers: () => void;
  setShow: Dispatch<boolean>;
}) => {
  const { form, loading, addUser } = useEditUser({ getUsers });

  return (
    <Modal
      width={800}
      title="Хэрэглэгч үүсгэх"
      visible={show}
      onCancel={() => setShow(false)}
      footer={[
        <Button key="back" disabled={loading} onClick={() => setShow(false)}>
          Болих
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={loading}
          onClick={() => form.validateFields().then(addUser)}
        >
          Нэмэх
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" autoComplete="off" onFinish={addUser}>
        <Tabs tabPosition="left" defaultActiveKey="1">
          <TabPane tab="Хувийн мэдээлэл" key="1">
            <Row gutter={24} justify="center">
              <Col span={12}>
                <Form.Item label="Овог" name="lastName" required>
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Нэр" name="firstName" required>
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Цахим шуудан" name="email" required>
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Утасны дугаар" name="phoneNo" required>
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="role" label="Хэрэглэгчийн эрх" required>
                  <Select disabled={loading} style={{ width: "100%" }}>
                    {Object.values(Role).map((item) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Төрсөн огноо" name="birthDate">
                  <DatePicker style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="type" label="Хэрэглэгчийн төрөл" required>
                  <Select disabled={loading} style={{ width: "100%" }}>
                    {Object.values(UserType).map((item) => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Дансны мэдээлэл" key="2">
            <Row justify="center">
              <Col span={16}>
                <Form.Item label="Банкны нэр" name="bankName" required>
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="БД Код" name="bdCode" required>
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="Дансны нэр" name="accountName" required>
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="Дансны дугаар" name="accountNo" required>
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Нууц үг" key="3">
            <Row justify="center">
              <Col span={16}>
                <Form.Item label="Нууц үг" name="password" required>
                  <Input.Password disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  label="Нууц үг давтах"
                  name="verifyPassword"
                  required
                >
                  <Input.Password disabled={loading} />
                </Form.Item>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};
