import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BalanceSavingsHistoryWidget } from "../../../widgets/balance/detail/detailHistory/savings";

export interface BalanceHistoryParams {
  _id: string;
  index: string;
}

export const BalanceDetailsSavingsHistoryPage = () => {
  const { _id, index } = useParams<BalanceHistoryParams>();
  useEffect(() => {
    document.title = "Хадгаламж дэлгэрэнгүй түүх";
  }, []);
  return (
    <div className="page">
      <BalanceSavingsHistoryWidget _id={_id} index={index} />
    </div>
  );
};
