import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SurveyDetailWidget } from "../../widgets/survey/detail";
import { PortfolioParamsType } from "../portfolio/detail";

export const SurveyDetailPage = () => {
  const { _id } = useParams<PortfolioParamsType>();
  useEffect(() => {
    document.title = "Судалгааны дэлгэрэнгүй";
  }, []);
  return (
    <div className="page">
      <SurveyDetailWidget _id={_id} />
    </div>
  );
};
