import { Button, Form, Input, Modal } from "antd";
import { Dispatch, useCallback, useEffect } from "react";
import { NullableAnswer } from "./SelectSurvey";

export const SelectAnswer = ({
  answer,
  answerIndex,
  setAnswer,
  onSubmit,
}: {
  answer: NullableAnswer | null;
  answerIndex: number;
  setAnswer: Dispatch<NullableAnswer | null>;
  onSubmit: ({ data, index }: { data: NullableAnswer; index: number }) => void;
}) => {
  const [form] = Form.useForm();

  const submit = useCallback(
    (data) => {
      onSubmit({
        data: {
          value: data.value_mn,
          localizedValue: {
            en: data.value_en,
            mn: data.value_mn,
          },
          durationPoints: data.durationPoints,
          riskPoints: data.riskPoints,
        },
        index: answerIndex,
      });
      setAnswer(null);
    },
    [answerIndex, onSubmit, setAnswer]
  );

  useEffect(() => {
    if (answer) {
      form.setFields([
        {
          name: "value_mn",
          value: answer.value,
        },
        {
          name: "value_en",
          value: answer.localizedValue?.en,
        },
        {
          name: "durationPoints",
          value: answer.durationPoints,
        },
        {
          name: "riskPoints",
          value: answer.riskPoints,
        },
      ]);
    }
  }, [answer, form, setAnswer]);

  return (
    <Modal
      width={700}
      title="Хариулт"
      visible={!!answer}
      onCancel={() => setAnswer(null)}
      footer={[
        <Button key="back" onClick={() => setAnswer(null)}>
          Болих
        </Button>,
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => form.validateFields().then(submit)}
        >
          Хадгалах
        </Button>,
      ]}
    >
      <Form form={form} onFinish={submit} layout="vertical">
        <Form.Item label="Хариулт (Монгол)" name="value_mn" required>
          <Input />
        </Form.Item>
        <Form.Item label="Хариулт (Англи)" name="value_en" required>
          <Input />
        </Form.Item>
        <Form.Item label="Хугацаа" name="durationPoints" required>
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Эрсдлийн үнэлгээ" name="riskPoints" required>
          <Input type="number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
