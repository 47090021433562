import { Modal, Table } from "antd";
import moment from "moment";
import { Dispatch, useEffect } from "react";
import { BalanceStockHistory } from "../../graphql";
import { formatBalance } from "../../utils/formatters";

export const BalanceStockHistoryModal = ({
  data,
  setData,
}: {
  data: BalanceStockHistory[] | undefined;
  setData: Dispatch<BalanceStockHistory[] | undefined>;
}) => {
  useEffect(() => {
    console.log(data?.length);
  }, [data]);

  return (
    <Modal
      width={1600}
      title="Өөрчлөлтийн түүх"
      visible={!!data}
      onCancel={() => setData(undefined)}
      footer={false}
    >
      <Table
        size="small"
        bordered
        scroll={{ x: 2000 }}
        dataSource={data?.sort((a, b) => b.createdAt - a.createdAt) ?? []}
        columns={[
          {
            title: "Огноо",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => (
              <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span>
            ),
          },
          {
            title: "Тоо ширхэг",
            dataIndex: "totalAmount",
            key: "totalAmount",
            render: (text) => <span>{text.toFixed(6)}</span>,
          },
          {
            title: "Нийт үлдэгдэл",
            dataIndex: "totalPrice",
            key: "totalPrice",
            render: (text) => <span>{formatBalance({ balance: text })} ₮</span>,
          },

          {
            title: "Нэгж үнэ",
            dataIndex: "unitPrice",
            key: "unitPrice",
            render: (text) => <span>{formatBalance({ balance: text })} ₮</span>,
          },

          {
            title: "Шимтгэл",
            dataIndex: "fee",
            key: "fee",
            render: (text) => <span>{formatBalance({ balance: text })} ₮</span>,
          },
        ]}
      />
    </Modal>
  );
};
