import React, { useEffect } from "react";
import { GroupDetailsWidget } from "../../../widgets/user/group_details";

import { useParams } from "react-router-dom";

export interface GroupDetailsParams {
  _id: string;
}
export const GroupDetailsPage = () => {
  const { _id } = useParams<GroupDetailsParams>();

  useEffect(() => {
    document.title = "Дэлгэрэнгүй мэдээлэл";
  }, []);

  return (
    <div className="page">
      <GroupDetailsWidget _id={_id} />
    </div>
  );
};
