import moment from "moment";
import { Link } from "react-router-dom";
import { Button, Table } from "antd";

import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import sdk from "@/sdk";

export function StockDividendsPage() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const { data, isLoading } = useQuery({
    queryKey: ["dividends", { page, limit }],
    queryFn: () => sdk.getDividendReports({ page, limit }),
    select: (data) => data.dividendReports,
  });
  return (
    <main className="page">
      <div className="page-header">
        <h3>Хувьцааны ноогдол ашиг</h3>
        <Link to="/stocks/dividends/create">
          <Button type="primary">Ноогдол ашиг тараах</Button>
        </Link>
      </div>
      <div className="page-body">
        <Table
          rowKey="id"
          loading={isLoading}
          pagination={{
            pageSize: limit,
            total: Number(data?.totalPages) * limit,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setPage(page - 1);
              setLimit(pageSize);
            },
          }}
        >
          <Table.Column
            title="Хувьцаа"
            dataIndex="stock"
            render={(value) => value.symbol}
          />
          <Table.Column
            title="Тасалбар болох огноо"
            dataIndex="recordDate"
            render={(value) => moment(value).format("YYYY-MM-DD")}
          />
          <Table.Column title="Ноогдол ашиг" dataIndex="amount" />
          <Table.Column title="Нийт олгосон дүн" dataIndex="totalAmount" />
          <Table.Column
            title="Тараасан огноо"
            dataIndex="createdAt"
            render={(value) => moment(value).format("YYYY-MM-DD")}
          />
        </Table>
      </div>
    </main>
  );
}
