import invariant from "ts-invariant";
import { message } from "antd";
import {
  startAuthentication,
  browserSupportsWebAuthn,
} from "@simplewebauthn/browser";
import type { PublicKeyCredentialRequestOptionsJSON } from "@simplewebauthn/types";

import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

import sdk, { client } from "@/sdk";
import { User } from "@/graphql";
import { emailRegex } from "@/utils/validation";
import { usePersistedState } from "@/hooks/usePersistedState";

export const useLogin = () => {
  const [loading, setLoading] = useState<
    "webauthn" | "otp" | "challenge" | false
  >(false);

  const [username, setUsername] = useState<string | null>(null);
  const [otpChallenge, setOtpChallenge] = useState<string | null>(null);
  const [webauthnChallenge, setWebauthnChallenge] =
    useState<PublicKeyCredentialRequestOptionsJSON | null>(null);

  const requestOtp = async (email: string) => {
    setLoading("otp");
    if (!email || !emailRegex.test(email)) {
      message.warn("И-мейл хаяг оруулна уу.", 2);
      setLoading(false);
      return;
    }

    try {
      const { requestOtp } = await sdk.requestOtp({ username: email });
      if (requestOtp.sent) {
        setOtpChallenge(requestOtp.challenge);
        setWebauthnChallenge(null);
      } else {
        message.warn(
          `Хүлээгээд дахин оролдоно уу (${requestOtp.retryAfter} секунд)`,
          2
        );
      }
    } finally {
      setLoading(false);
    }
  };
  const getWebauthnChallenge = useCallback(
    async (username: string): Promise<boolean> => {
      const { webauthnLoginChallenge } = await sdk.webauthnLoginChallenge({
        username,
      });
      if (webauthnLoginChallenge) {
        setWebauthnChallenge(JSON.parse(webauthnLoginChallenge));
        return true;
      }
      return false;
    },
    []
  );

  const getLoginChallenge = useCallback(async (username: string) => {
    setLoading("challenge");
    setUsername(username);
    try {
      if (
        !browserSupportsWebAuthn() ||
        !(await getWebauthnChallenge(username))
      ) {
        await requestOtp(username);
      }
    } catch (err) {
      setLoading(false);
      message.warn((err as any).response?.errors[0]?.message, 2);
    } finally {
      setLoading(false);
    }
  }, []);

  const history = useHistory();
  const setProfile = usePersistedState<User | null>(null, "user")[1];

  const proceedAfterLogin = useCallback(
    ({ user, token }: { user: User; token: string }) => {
      setProfile(user);
      localStorage.setItem("auth", token);
      if (import.meta.env.DEV) {
        client.setHeader("Authorization", `Bearer ${token}`);
      }
      history.push("/");
    },
    [history, setProfile]
  );
  const submitWebauthn = useCallback(
    async (silent: boolean = false) => {
      setLoading("webauthn");
      try {
        invariant(username, "username must be defined");
        invariant(webauthnChallenge, "webauthn challenge must be defined");
        const response = await startAuthentication(webauthnChallenge);
        const { webauthnLogin: result } = await sdk.webauthnLogin({
          username,
          response: JSON.stringify(response),
        });
        // @ts-expect-error Removing unused fields from schema
        proceedAfterLogin(result);
      } catch (err) {
        setLoading(false);
        if (!silent) {
          message.warn(
            (err as any).response?.errors[0]?.message ?? (err as Error).message,
            2
          );
        }
      } finally {
        setLoading(false);
      }
    },
    [username, webauthnChallenge, proceedAfterLogin]
  );
  useEffect(() => {
    // Automatically try to sign in w webauthn if challenge is present
    if (webauthnChallenge) {
      submitWebauthn(true);
    }
  }, [webauthnChallenge]);

  const submit = useCallback(
    async (values) => {
      setLoading("otp");
      try {
        const { login: result } = await sdk.login({
          ...values,
          challenge: otpChallenge,
        });
        // @ts-expect-error Removing unused fields from schema
        proceedAfterLogin(result);
      } catch (err) {
        setLoading(false);
        message.warn((err as any).response?.errors[0]?.message, 2);
      } finally {
        setLoading(false);
      }
    },
    [otpChallenge, proceedAfterLogin]
  );
  return {
    loading,
    otpChallenge,
    webauthnChallenge,
    submit,
    requestOtp,
    submitWebauthn,
    getLoginChallenge,
  };
};
