import { Form, message } from "antd";
import { useCallback, useEffect, useState } from "react";

import sdk from "../../sdk";

export const useEditBond = ({
  selectedBondId,
}: {
  selectedBondId?: string;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const getBond = useCallback(() => {
    setLoading(true);
    sdk
      .getBond({ _id: selectedBondId! })
      .then((dat) => {
        form.setFields([
          {
            name: "name_mn",
            value: dat.getBond.localizedName?.mn ?? dat.getBond.name,
          },
          {
            name: "name_en",
            value: dat.getBond.localizedName?.en ?? dat.getBond.name,
          },
          {
            name: "symbol",
            value: dat.getBond.symbol,
          },
          {
            name: "interestRate",
            value: dat.getBond.interestRate,
          },
          { name: "duration", value: dat.getBond.duration },
          {
            name: "active",
            value: dat.getBond.active,
          },
        ]);
      })
      .catch((err) => {
        setLoading(false);
        message.warn(err.message);
      })
      .finally(() => setLoading(false));
  }, [form, selectedBondId]);

  const editBond = useCallback(
    (bond) => {
      setLoading(true);
      if (selectedBondId) {
        sdk
          .editBond({
            _id: selectedBondId,
            bond: {
              name: bond.name_mn,
              localizedName: {
                en: bond.name_en,
                mn: bond.name_mn,
              },
              active: bond.active ?? false,
              duration: bond.duration,
              interestRate: Number(bond.interestRate),
            },
          })
          .then((result) => {
            if (result) {
              message.success("Амжилттай засагдлаа", 2);
            }
            setLoading(false);
          })
          .catch((err) => {
            message.warn(err.response?.errors[0]?.message, 2);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    },
    [selectedBondId]
  );
  const addBond = useCallback(
    (bond) => {
      setLoading(true);
      sdk
        .addBond({
          bond: {
            name: bond.name_mn ?? bond.name_en,
            localizedName: {
              en: bond.name_en,
              mn: bond.name_mn,
            },
            symbol: bond.symbol,
            active: bond.active ?? false,
            duration: bond.duration,
            interestRate: Number(bond.interestRate),
          },
        })
        .then((result) => {
          if (result) {
            message.success("Амжилттай нэмэгдлээ", 2);
          }
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [form]
  );

  useEffect(() => {
    if (selectedBondId) {
      getBond();
    }
  }, [getBond, selectedBondId]);

  return {
    form,
    loading,
    addBond,
    editBond,
  };
};
