import { Button, Col, Row, Space, Table } from "antd";
import moment from "moment";
import { ArrowLeftOutlined, CheckOutlined } from "@ant-design/icons";

import { BondForm } from "./bonds";
import { SavingForm } from "./savings";
import { formatAccountNo, formatAmount } from "../../../utils/formatters";
import { AddStockPurchaseInput } from "../../../graphql";

const OrderConfirmEnd = ({
  uploadStock,
  savingForm,
  submit,
  back,
  bondForm,
  usdRate,
}: {
  usdRate: number | undefined;
  savingForm: SavingForm[];
  uploadStock: AddStockPurchaseInput[] | undefined;
  submit: () => void;
  back: () => void;
  bondForm: BondForm[];
}) => {
  return (
    <Row gutter={[24, 24]} style={{ padding: "24px 0 0 0" }}>
      <Col span={24}>
        <h3>Долларын ханш: {usdRate}</h3>
        <h3 style={{ margin: "12px 0" }}>Үнэт цаас/Хавсралт файлын өгөгдөл/</h3>
        <Table
          pagination={false}
          dataSource={uploadStock ?? []}
          size="small"
          columns={[
            {
              title: "Тэмдэгт",
              dataIndex: "symbol",
              key: "symbol",
            },
            {
              title: "Тэмдэгт",
              dataIndex: "quantity",
              key: "quantity",
            },
            {
              title: "Нэгжийн үнэ",
              dataIndex: "unitPrice",
              key: "unitPrice",
              render: (text) => <span>{formatAmount({ amount: text })}</span>,
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <h3 style={{ margin: "12px 0" }}>Бонд</h3>
        <Table
          pagination={false}
          dataSource={bondForm ?? []}
          size="small"
          columns={[
            {
              title: "Худалдаж авсан дүн",
              dataIndex: "amount",
              key: "amount",
              render: (text) => <span>{formatAmount({ amount: text })} ₮</span>,
            },
            {
              title: "Бондын нэр",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Жилийн хүү (%)",
              dataIndex: "interestRate",
              key: "interestRate",
            },
            {
              title: "Эхлэх огноо",
              dataIndex: "startDate",
              key: "startDate",
              render: (text) => (
                <span>
                  {moment(text).format("YYYY/MM/DD HH:mm:ss").toString()}
                </span>
              ),
            },
            {
              title: "Дуусах огноо",
              dataIndex: "endDate",
              key: "endDate",
              render: (text) => (
                <span>
                  {moment(text).format("YYYY/MM/DD HH:mm:ss").toString()}
                </span>
              ),
            },
            {
              title: "Шимтгэл дүн",
              dataIndex: "fee",
              key: "fee",
              render: (text) => <>{formatAmount({ amount: text })} ₮</>,
            },
          ]}
        />
      </Col>

      <Col span={24}>
        <h3 style={{ margin: "12px 0" }}>Үл хөдлөх хөрөнгө</h3>
        <Table
          pagination={false}
          dataSource={savingForm ?? []}
          size="small"
          columns={[
            {
              title: "Худалдаж авсан дүн",
              dataIndex: "amount",
              key: "amount",
              render: (text) => <>{formatAmount({ amount: text })} ₮</>,
            },
            {
              title: "Банкны нэр",
              dataIndex: "bankName",
              key: "bankName",
            },
            {
              title: "Дансны дугаар",
              dataIndex: "accountNo",
              key: "accountNo",
              render: (text) => (
                <span>{formatAccountNo({ accountNo: text })}</span>
              ),
            },
            {
              title: "Жилийн хүү (%)",
              dataIndex: "interestRate",
              key: "interestRate",
            },
            {
              title: "Эхлэх огноо",
              dataIndex: "startDate",
              key: "startDate",
              render: (text) => (
                <span>
                  {moment(text).format("YYYY/MM/DD HH:mm:ss").toString()}
                </span>
              ),
            },
            {
              title: "Дуусах огноо",
              dataIndex: "endDate",
              key: "endDate",
              render: (text) => (
                <span>
                  {moment(text).format("YYYY/MM/DD HH:mm:ss").toString()}
                </span>
              ),
            },
            {
              title: "Шимтгэл дүн",
              dataIndex: "fee",
              key: "fee",
              render: (text) => <>{formatAmount({ amount: text })} ₮</>,
            },
          ]}
        />
      </Col>
      <Col span={24} style={{ textAlign: "right" }}>
        <Space>
          <Button type="dashed" onClick={back} icon={<ArrowLeftOutlined />}>
            Буцах
          </Button>
          <Button
            type="primary"
            onClick={() => {
              window.scrollTo(0, 0);
              submit();
            }}
          >
            Баталгаажуулах
            <CheckOutlined />
          </Button>
        </Space>
      </Col>
    </Row>
  );
};

export default OrderConfirmEnd;
