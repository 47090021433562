import React, { CSSProperties } from "react";

const BlueDivider = ({
  text,
  styles,
}: {
  text: string;
  styles?: CSSProperties;
}) => {
  return (
    <div style={{ position: "relative", ...styles }}>
      <h1 className="blue-border" style={{ color: "#699BFF" }}>
        {text}
      </h1>
      <div
        style={{
          position: "absolute",
          bottom: -1,
          width: "30px",
          height: "3px",
          borderRadius: "2px",
          backgroundColor: "#699BFF",
        }}
      ></div>
    </div>
  );
};

export default BlueDivider;
