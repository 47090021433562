import { Button, Image, Pagination, Popconfirm, Spin } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import { AddImageWidget } from "./modal";
import { useImages } from "./useImages";

export const ImageWidget = () => {
  const [show, setShow] = useState(false);
  const { data, loading, getImages, deleteImage, setPage, page } = useImages();

  return (
    <div className="image-widget">
      <AddImageWidget show={show} setShow={setShow} getImages={getImages} />
      <div className="image-widget-button">
        <Button
          type="primary"
          disabled={loading}
          onClick={() => setShow(true)}
          icon={<UploadOutlined />}
        >
          Зураг хуулах
        </Button>
      </div>
      <div className="image-container">
        {loading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Spin />
          </div>
        )}
        {!loading &&
          data?.images?.map((image) => {
            return (
              <div key={image._id} className="image">
                <Image
                  height="200px"
                  width="100%"
                  style={{ objectFit: "contain" }}
                  src={
                    import.meta.env.PROD
                      ? `/images/${image.path}`
                      : `http://avtamata.com/images/${image.path}`
                  }
                  alt={image._id!}
                />
                <Popconfirm
                  disabled={loading}
                  title="Та устгахдаа итгэлтэй байна уу?"
                  okText="Тийм"
                  cancelText="Үгүй"
                  onConfirm={() => {
                    deleteImage(image._id!);
                  }}
                >
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    danger
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </div>
            );
          })}
      </div>
      {!loading && data && (
        <Pagination
          current={page + 1}
          defaultCurrent={0}
          total={Number(data?.totalPages! * 20)}
          defaultPageSize={20}
          onChange={(value) => setPage(value - 1)}
        />
      )}
    </div>
  );
};
