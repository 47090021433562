import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import mnMN from "antd/es/locale/mn_MN";

import "antd/dist/antd.css";

import "./index.css";

import { App } from "./App";

Sentry.init({
  dsn: "https://a631c8dc8dcc7fd810fe0f11bc4f9715@o4504151139155968.ingest.us.sentry.io/4506958649098240",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0.3,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/(dashboard\.)?(staging\.)?avtamata\.com\/graphql/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <Router basename={import.meta.env.BASE_URL}>
    <Switch>
      <ConfigProvider locale={mnMN}>
        <App />
      </ConfigProvider>
    </Switch>
  </Router>,
  document.getElementById("root")
);
