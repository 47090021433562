import { Button, Form, Input, Modal, Popconfirm } from "antd";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { SelectAnswer } from "./SelectAnswer";
import { NullableQuestion } from "../widgets/survey/create";

export type NullableAnswer = {
  localizedValue: {
    en?: string | null;
    mn?: string | null;
  };
  value: string | null;
  durationPoints: number | null;
  riskPoints: number | null;
};

export const SelectSurvey = ({
  question,
  questionIndex,
  setQuestion,
  onSubmit,
  loading,
}: {
  question: NullableQuestion | null;
  questionIndex: number;
  setQuestion: Dispatch<NullableQuestion | null>;
  onSubmit: ({
    question,
    index,
  }: {
    question: NullableQuestion;
    index: number;
  }) => void;
  loading: boolean;
}) => {
  const [form] = Form.useForm();
  const [answers, setAnswers] = useState<(NullableAnswer | null)[]>([
    {
      riskPoints: null,
      durationPoints: null,
      value: null,
      localizedValue: { en: null, mn: null },
    },
  ]);
  const [selectedAnswer, setSelectedAnswer] = useState<NullableAnswer | null>(
    null
  );
  const [answerIndex, setAnswerIndex] = useState<number>(0);
  const submit = useCallback(
    (data) => {
      onSubmit({
        question: {
          question: data.question_mn,
          localizedQuestion: {
            en: data.question_en,
            mn: data.question_mn,
          },
          answers,
        },
        index: questionIndex,
      });
      setQuestion(null);
    },
    [onSubmit, answers, questionIndex, setQuestion]
  );

  useEffect(() => {
    if (question) {
      form.setFields([
        {
          name: "question_mn",
          value: question.question,
        },
        {
          name: "question_en",
          value: question.localizedQuestion?.en,
        },
      ]);
      setAnswers(
        question.answers ?? [
          {
            riskPoints: null,
            durationPoints: null,
            value: null,
          },
        ]
      );
    }
  }, [question, form]);

  const addAnswer = () => {
    const answer: NullableAnswer = {
      localizedValue: { en: null, mn: null },
      riskPoints: null,
      durationPoints: null,
      value: null,
    };
    const newAnswers = [...answers, answer];
    setAnswers(newAnswers);
  };

  const deleteAnswer = (index: number) => {
    if (answers.length > 1) {
      answers.splice(index, 1);
      setAnswers([...answers]);
    } else {
      updateAnswer({ data: null, index });
    }
  };

  const updateAnswer = ({
    data,
    index,
  }: {
    data: NullableAnswer | null;
    index: number;
  }) => {
    const newAnswers = [...answers];
    console.log(data, index);
    newAnswers[index] = data;
    console.log(newAnswers);
    setAnswers(newAnswers);
  };

  return (
    <Modal
      width={700}
      title="Асуулт"
      visible={!!question}
      onCancel={() => setQuestion(null)}
      footer={[
        <Button key="back" disabled={loading} onClick={() => setQuestion(null)}>
          Болих
        </Button>,
        <Button
          key="submit"
          disabled={loading}
          htmlType="submit"
          type="primary"
          onClick={() => form.validateFields().then(submit)}
        >
          Хадгалах
        </Button>,
      ]}
    >
      <SelectAnswer
        answer={selectedAnswer}
        answerIndex={answerIndex}
        setAnswer={setSelectedAnswer}
        onSubmit={(data) => updateAnswer(data)}
      />
      <Form form={form} onFinish={submit} layout="vertical">
        <Form.Item label="Асуулт (Монгол)" name="question_mn" required>
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item label="Асуулт (Англи)" name="question_en" required>
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item label="Хариултууд" required>
          {answers.map((answer, index) => (
            <div>
              <Button
                style={{
                  margin: "5px 5px 5px 0",
                  width: "90%",
                  textAlign: "left",
                }}
                onClick={() => {
                  setSelectedAnswer(answer);
                  setAnswerIndex(index);
                }}
              >
                {answer?.value ?? "хоосон"}
              </Button>
              <Popconfirm
                disabled={loading}
                title="Та устгахдаа итгэлтэй байна уу?"
                okText="Тийм"
                cancelText="Үгүй"
                onConfirm={() => deleteAnswer(index)}
              >
                <Button
                  size="middle"
                  disabled={loading}
                  type="dashed"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </div>
          ))}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            style={{ marginTop: 10 }}
            onClick={addAnswer}
            icon={<PlusOutlined />}
          >
            Хариулт нэмэх
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
