import { useEffect } from "react";
import { UserDeletionRequestWidget } from "../../widgets/userDeletionRequest";

export const UserDeletionRequestPage = () => {
  useEffect(() => {
    document.title = "Хэрэглэгч устгах хүсэлтүүд";
  }, []);
  return (
    <div className="page">
      <UserDeletionRequestWidget />
    </div>
  );
};
