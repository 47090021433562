import { Spin } from "antd";

export const FullLoading = () => {
  return (
    <div className="loading">
      <div>
        <Spin size="large"></Spin>
      </div>
    </div>
  );
};
