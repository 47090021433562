import { useQuery } from "@tanstack/react-query";

import sdk from "@/sdk";
import { GetBalanceByUserIdQuery } from "@/graphql";

export function useTabBalanceHistory({ _id }: { _id: string }) {
  const { isLoading, data, error } = useQuery(["balance", "user:" + _id], {
    queryFn: () => sdk.getBalanceByUserId({ _id }),
    select: (data: GetBalanceByUserIdQuery) => data.getBalanceByUserId,
  });
  return {
    data,
    error,
    loading: isLoading,
  };
}
