import { Button, Form, Input, Modal, Select } from "antd";
import { Dispatch, useEffect, useCallback } from "react";

import { Stock } from "../graphql";
import { NullablePortfolioStock, Option } from "../widgets/portfolio/create";

export const SelectStock = ({
  stock,
  stockIndex,
  stocks,
  loading,
  setStock,
  onSubmit,
}: {
  stock: NullablePortfolioStock | null;
  stockIndex: number;
  stocks: Omit<Stock, "unitPrice">[] | null;
  loading: boolean;
  setStock: Dispatch<NullablePortfolioStock | null>;
  onSubmit: ({
    data,
    index,
  }: {
    data: NullablePortfolioStock | null;
    index: number;
  }) => void;
}) => {
  const [form] = Form.useForm();
  const submit = useCallback(
    (data) => {
      onSubmit({
        data: {
          stock: stocks?.filter((stock) => data.stock === stock._id)[0]!,
          value: data.stockValue,
        },
        index: stockIndex,
      });
      setStock(null);
    },
    [onSubmit, setStock, stocks, stockIndex]
  );

  useEffect(() => {
    if (stock) {
      form.setFields([
        {
          name: "stock",
          value: stock?.stock?._id,
        },
        {
          name: "stockValue",
          value: stock.value,
        },
      ]);
    }
  }, [stock, form]);

  return (
    <Modal
      width={700}
      title="Үнэт цаас"
      visible={!!stock}
      onCancel={() => setStock(null)}
      footer={[
        <Button key="back" disabled={loading} onClick={() => setStock(null)}>
          Болих
        </Button>,
        <Button
          key="submit"
          disabled={loading}
          htmlType="submit"
          type="primary"
          onClick={() => form.validateFields().then(submit)}
        >
          Нэмэх
        </Button>,
      ]}
    >
      <Form form={form} onFinish={submit} layout="vertical">
        <Form.Item
          label="Үнэт цаас"
          name="stock"
          rules={[{ required: true, message: "Үнэт цаас оруулна уу" }]}
        >
          <Select disabled={loading} loading={loading}>
            {stocks?.map((stock) => (
              <Option key={stock._id!} value={stock._id!}>
                {stock.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Үнэт цаасын эзлэх хувь"
          name="stockValue"
          rules={[
            { required: true, message: "Үнэт цаасын эзлэх хувь оруулна уу" },
          ]}
        >
          <Input type="number" disabled={loading} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
