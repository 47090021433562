import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { BalanceBondHistoryWidget } from "../../../widgets/balance/detail/detailHistory/bond";
export interface BalanceBondHistoryParams {
  _id: string;
  index: string;
}
export const BalanceDetailsBondHistoryPage = () => {
  useEffect(() => {
    document.title = "Бонд дэлгэрэнгүй түүх";
  }, []);
  const { _id, index } = useParams<BalanceBondHistoryParams>();
  return (
    <div className="page">
      <BalanceBondHistoryWidget _id={_id} index={index} />
    </div>
  );
};
