import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AddPortfolioWidget } from "../../widgets/portfolio/create";
import { PortfolioParamsType } from "./detail";

export const AddPortfolioPage = () => {
  const { _id } = useParams<PortfolioParamsType>();
  useEffect(() => {
    document.title = _id === "create" ? "Багц нэмэх" : "Багц засах";
  }, [_id]);
  return (
    <div className="page">
      <AddPortfolioWidget _id={_id} />
    </div>
  );
};
