import { useEffect } from "react";
import { SavingWidget } from "../../widgets/saving";

export const SavingPage = () => {
  useEffect(() => {
    document.title = "Хадгаламж";
  }, []);
  return (
    <div className="page">
      <SavingWidget />
    </div>
  );
};
