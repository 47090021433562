import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { AddSurveyWidget } from "../../widgets/survey/create";
import { PortfolioParamsType } from "../portfolio/detail";

export const AddSurveyPage = () => {
  const { _id } = useParams<PortfolioParamsType>();
  useEffect(() => {
    document.title = _id === "create" ? "Судалгаа нэмэх" : "Судалгаа засах";
  }, [_id]);
  return (
    <div className="page">
      <AddSurveyWidget _id={_id} />
    </div>
  );
};
