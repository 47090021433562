import { message } from "antd";
import { useCallback, useState } from "react";
import sdk from "../../../sdk";

export const useEditKYC = ({
  selectedKYCId,
  getUsers,
}: {
  selectedKYCId: string | null;
  getUsers?: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const editKYC = useCallback(
    (values) => {
      setLoading(true);
      if (selectedKYCId) {
        sdk
          .editKYC({
            _id: selectedKYCId,
            status: values.status,
            description: values.description ?? null,
          })
          .then(() => {
            getUsers?.();
            message.success("Амжилттай засагдлаа", 2);
            setLoading(false);
          })
          .catch((err) => {
            message.warn(err.response?.errors[0]?.message, 2);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    },
    [getUsers, selectedKYCId]
  );
  return {
    loading,
    editKYC,
  };
};
