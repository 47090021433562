import { useState } from "react";
import { Button, Col, Divider, Row, Space, Table } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { formatAccountNo, formatBalance } from "../../../utils/formatters";

import { useGetBalance } from "./useGetBalance";
import { FullLoading } from "../../../components/FullLoading";
import { BalanceStockHistoryModal } from "../../../components/modal/BalanceStockHistory";
import { SavingBondHistoryModal } from "../../../components/modal/SavingBondHistory";

export const BalanceDetailWidget = ({ _id }: { _id: string }) => {
  const { loading, data, error } = useGetBalance({ _id });
  const [stockHistory, setStockHistory] = useState<any>();
  const [savingBondHistory, setSavingBondHistory] = useState<any>();

  return (
    <>
      <BalanceStockHistoryModal data={stockHistory} setData={setStockHistory} />
      <SavingBondHistoryModal
        data={savingBondHistory}
        setData={setSavingBondHistory}
      />

      <div className="page-header">
        <h3>
          {data &&
            `${data.user.phoneNo} (${data.user.chosenPortfolio?.index}-р багц) -
          Дансны дэлгэрэнгүй`}
        </h3>
        <Space>
          <Link to="/balances">
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Буцах
            </Button>
          </Link>
        </Space>
      </div>
      {loading}
      <div className="page-body">
        <Row justify="center">
          {loading ? (
            <FullLoading />
          ) : (
            <>
              <Col span={6}>
                <span className="question_title">Нийт хөрөнгө</span>
                <p>{formatBalance({ balance: data?.netAssetValue ?? 0 })} ₮</p>
              </Col>
              <Col span={6}>
                <span className="question_title">Бэлэн мөнгө</span>
                <p>{formatBalance({ balance: data?.cashAmount ?? 0 })} ₮</p>
              </Col>
              <Col span={6}>
                <span className="question_title">Битүүмжлэгдсэн дүн</span>
                <p>{formatBalance({ balance: data?.holdAmount ?? 0 })} ₮</p>
              </Col>
              <Col span={6}>
                <span className="question_title">Нийт шимтгэл</span>
                <p>{formatBalance({ balance: data?.fee ?? 0 })} ₮</p>
              </Col>
              <Col span={24}>
                <Divider orientation="left">Үнэт цаас</Divider>
              </Col>
              <Col span={24}>
                <Table
                  dataSource={data?.stocks ?? []}
                  loading={loading}
                  pagination={false}
                  size="small"
                  bordered
                  columns={[
                    {
                      title: "Нэр",
                      dataIndex: "name",
                      key: "name",
                      render: (_, record) => <span>{record.stock.name}</span>,
                    },
                    {
                      title: "Тоо ширхэг",
                      dataIndex: "totalAmount",
                      key: "totalAmount",
                      render: (text) => <span>{text.toFixed(6)}</span>,
                    },
                    {
                      title: "Нийт үлдэгдэл",
                      dataIndex: "totalPrice",
                      key: "totalPrice",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "Ширхэгийн үнэ",
                      dataIndex: "unitPrice",
                      key: "unitPrice",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "Шимтгэл",
                      dataIndex: "fee",
                      key: "fee",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "",
                      dataIndex: "action",
                      key: "action",
                      width: 90,
                      render: (_, __, index) => (
                        <Link to={"/balancedetail/stock/" + _id + "/" + index}>
                          <Button type="primary" shape="round">
                            Түүх
                          </Button>
                        </Link>
                      ),
                    },
                  ]}
                />
              </Col>

              <Col span={24}>
                <Divider orientation="left">Бонд</Divider>
              </Col>
              <Col span={24}>
                <Table
                  dataSource={data?.bonds ?? []}
                  loading={loading}
                  pagination={false}
                  size="small"
                  bordered
                  columns={[
                    {
                      title: "Нэр",
                      dataIndex: "name",
                      key: "name",
                      render: (_, record) => <span>{record.bond.name}</span>,
                    },
                    {
                      title: "Хуримтлагдсан хүү",
                      dataIndex: "accumulatedAmount",
                      key: "accumulatedAmount",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "Хөрөнгө оруулалт",
                      dataIndex: "principleAmount",
                      key: "principleAmount",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "Нийт үлдэгдэл",
                      dataIndex: "estimatedTotalEarnings",
                      key: "estimatedTotalEarnings",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "Шимтгэл",
                      dataIndex: "fee",
                      key: "fee",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "",
                      dataIndex: "action",
                      key: "action",
                      width: 90,
                      render: (_, __, index) => (
                        <Link to={"/balancedetail/bond/" + _id + "/" + index}>
                          <Button type="primary" shape="round">
                            Түүх
                          </Button>
                        </Link>
                      ),
                    },
                  ]}
                />
              </Col>

              <Col span={24}>
                <Divider orientation="left">Хадгаламж</Divider>
              </Col>
              <Col span={24}>
                <Table
                  dataSource={data?.savings ?? []}
                  loading={loading}
                  pagination={false}
                  size="small"
                  bordered
                  columns={[
                    {
                      title: "Банкны нэр",
                      dataIndex: "bankName",
                      key: "bankName",
                      render: (_, record) => (
                        <span>{record.saving.bankName}</span>
                      ),
                    },
                    {
                      title: "Данс",
                      dataIndex: "accountNo",
                      key: "accountNo",
                      render: (_, record) => (
                        <span>
                          {formatAccountNo({
                            accountNo: record.saving.accountNo,
                          })}
                        </span>
                      ),
                    },
                    {
                      title: "Хуримтлагдсан хүү",
                      dataIndex: "accumulatedAmount",
                      key: "accumulatedAmount",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "Нийт үлдэгдэл",
                      dataIndex: "principleAmount",
                      key: "principleAmount",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "Шимтгэл",
                      dataIndex: "fee",
                      key: "fee",
                      render: (text) => (
                        <span>{formatBalance({ balance: text })} ₮</span>
                      ),
                    },
                    {
                      title: "",
                      dataIndex: "action",
                      key: "action",
                      width: 90,
                      render: (_, __, index) => (
                        <Link
                          to={"/balancedetail/savings/" + _id + "/" + index}
                        >
                          <Button type="primary" shape="round">
                            Түүх
                          </Button>
                        </Link>
                      ),
                    },
                  ]}
                />
              </Col>
            </>
          )}
        </Row>
      </div>
      {error}
    </>
  );
};
