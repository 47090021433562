import { Form, message } from "antd";
import { useCallback, useState } from "react";
import sdk from "../../../sdk";

export const useEditConfig = ({
  selectedConfigId,
  getConfigs,
}: {
  selectedConfigId?: string;
  getConfigs: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const editConfig = useCallback(
    (values) => {
      setLoading(true);
      if (selectedConfigId) {
        sdk
          .editConfig({
            _id: selectedConfigId,
            config: Object.assign(values, { value: Number(values.value) }),
          })
          .then(() => {
            getConfigs();
            message.success("Амжилттай засагдлаа", 2);
            setLoading(false);
          })
          .catch((err) => {
            message.warn(err.response?.errors[0]?.message, 2);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    },
    [getConfigs, selectedConfigId]
  );
  return { form, loading, editConfig };
};
