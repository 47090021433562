import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { OrderListStatus } from "../../graphql";
import { OrderListWidget } from "../../widgets/orderlist";
import { TabPane } from "../Order";

export const OrderListPage = () => {
  const [status, setStatus] = useState<OrderListStatus>(
    OrderListStatus.Inprogress
  );
  useEffect(() => {
    document.title = "Захиалгын хуудас";
  }, []);
  return (
    <div className="page">
      <Tabs
        defaultActiveKey="1"
        onChange={(e) => setStatus(e as OrderListStatus)}
      >
        {Object.values(OrderListStatus)
          .reverse()
          .map((orderListStatus) => (
            <TabPane tab={orderListStatus} key={orderListStatus}>
              <OrderListWidget status={status} />
            </TabPane>
          ))}
      </Tabs>
    </div>
  );
};
