import { SearchCtx } from "@/context/UserSearchProvider";
import {
  Button,
  Form,
  Modal,
  Select,
  InputNumber,
  message,
  Input,
  Popconfirm,
} from "antd";
import { Dispatch, useContext, useEffect } from "react";
import { debounce } from "ts-debounce";

import { User } from "../../../graphql";
import { Option } from "../../user/modal/create";
import { useUsers } from "../../user/useUsers";
import { useEditOrder } from "./useEditOrder";

export const AddOrderWidget = ({
  show,
  setShow,
  user,
  getOrders,
}: {
  user: User | null;
  show: boolean;
  setShow: Dispatch<boolean>;
  getOrders: () => void;
}) => {
  const { loading, form, result, addOrder } = useEditOrder({
    selectedOrderId: null,
    getOrders,
  });

  const { data } = useUsers();
  const { setSearch } = useContext(SearchCtx);

  const handleChange = debounce((value: string) => {
    setSearch(value);
  }, 500);

  useEffect(() => {
    if (result) {
      setShow(false);
    }
  }, [result, setShow]);

  useEffect(() => {
    if (show) {
      if (user) {
        form.setFields([{ name: "userId", value: user._id }]);
      }
    }
  }, [form, user, show]);

  return (
    <Modal
      width={500}
      title="Цэнэглэлт"
      visible={show}
      onCancel={() => setShow(false)}
      footer={[
        <Button key="back" disabled={loading} onClick={() => setShow(false)}>
          Болих
        </Button>,
        <Popconfirm
          title="Та цэнэглэхдээ итгэлтэй байна уу?"
          onConfirm={() =>
            form.validateFields().then((res) => {
              if (typeof res.amount !== "number") {
                message.warn("Дүн буруу байна.");
                return;
              }
              addOrder(res);
            })
          }
        >
          <Button key="submit" disabled={loading} type="primary">
            Цэнэглэх
          </Button>
        </Popconfirm>,
      ]}
    >
      <Form
        form={form}
        onFinish={addOrder}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item name="userId" label="Хэрэглэгч" required>
          <Select
            showSearch
            allowClear
            loading={loading}
            disabled={loading || !!user}
            style={{ width: "100%" }}
            onSearch={(value) => handleChange(value)}
            filterOption={(input, option) => {
              return (
                option!.props.children
                  .toString()
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option!.props.value
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              );
            }}
          >
            {data?.users?.map((item) => (
              <Option key={item._id} value={item._id!}>
                {item.email} - {item.phoneNo}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Дүн" name="amount" required>
          <InputNumber
            style={{ width: "100%" }}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            disabled={loading}
          />
        </Form.Item>
        <Form.Item label="Гүйлгээний утга" name="description" required>
          <Input style={{ width: "100%" }} disabled={loading} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
