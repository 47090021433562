import moment, { Moment } from "moment";
import { Modal, DatePicker } from "antd";

import { useState } from "react";

type RangeValue = [Moment | null, Moment | null] | null;

interface DateRangeModalProps {
  title: string;
  show: boolean;
  loading?: boolean;
  onClose: () => void;
  onSubmit: (startDate: Moment, endDate: Moment) => void;
}

const { RangePicker } = DatePicker;

export function DateRangeModal({
  title,
  show,
  loading,
  onClose,
  onSubmit,
}: DateRangeModalProps) {
  const [dateRange, setDateRange] = useState<RangeValue>(null);
  return (
    <Modal
      title={title}
      open={show}
      confirmLoading={loading}
      onOk={async () => {
        if (dateRange?.[0] && dateRange?.[1]) {
          onSubmit(dateRange[0], dateRange[1]);
        }
      }}
      onCancel={() => {
        onClose();
        setDateRange(null);
      }}
    >
      <RangePicker
        onChange={setDateRange}
        ranges={{
          "Энэ сар": [moment().startOf("month"), moment().endOf("month")],
          "Энэ долоо хоног": [moment().startOf("week"), moment().endOf("week")],
        }}
      />
    </Modal>
  );
}
