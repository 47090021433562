import { useQuery } from "@tanstack/react-query";
import { Col, Row, Statistic } from "antd";

import sdk from "@/sdk";
import { formatInput } from "@/utils/formatters";

function DashboardWidget() {
  const { data, isLoading } = useQuery({
    queryKey: ["dashboard-data"],
    queryFn: () => sdk.getDashboardData(),
    select: (data) => data.getDashboardData,
  });
  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <div className="chrat-paper">
          <Statistic
            loading={isLoading}
            title="Нийт хэрэглэгчдийн тоо"
            value={data?.totalUser}
          />
        </div>
      </Col>
      <Col span={6}>
        <div className="chrat-paper">
          <Statistic
            loading={isLoading}
            title="Нийт хөрөнгө оруулалт"
            value={formatInput({ balance: data?.totalInvestment ?? 0 }) + "₮"}
          />
        </div>
      </Col>
      <Col span={6}>
        <div className="chrat-paper">
          <Statistic
            loading={isLoading}
            title="Хүлээгдэж буй захиалгын тоо"
            value={data?.totalWaitingOrders}
          />
        </div>
      </Col>
    </Row>
  );
}

export default DashboardWidget;
