import { useCallback, useState } from "react";

import sdk from "../../sdk";

export function useDownloadOrderList(id: string) {
  const [loading, setLoading] = useState(false);
  const startDownload = useCallback(async () => {
    setLoading(true);
    try {
      const {
        getOrderList: { fileUrl },
      } = await sdk.downloadOrderList({ id });

      const anchor = document.createElement("a");
      anchor.href = fileUrl ?? "";
      anchor.download =
        new URL(fileUrl ?? "").pathname.split("/").at(-1) ?? "download.xlsx";

      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } finally {
      setLoading(false);
    }
  }, [id]);
  return { loading, startDownload };
}
