import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { EditBondWidget } from "../../../widgets/bond/edit";
export interface BondParams {
  _id: string;
}

export const EditBondsPage = () => {
  useEffect(() => {
    document.title = "Бонд засах";
  }, []);
  const { _id } = useParams<BondParams>();
  return (
    <div className="page">
      <EditBondWidget _id={_id} />
    </div>
  );
};
