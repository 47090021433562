import { Form, message } from "antd";
import { useCallback, useState } from "react";

import sdk from "../../sdk";
import { NullablePortfolioStock } from "./create";

export const useEditPortfolio = ({
  selectedPortfolioId,
  getPortfolios,
  active,
}: {
  selectedPortfolioId?: string;
  getPortfolios?: () => void;
  active: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState<(NullablePortfolioStock | null)[]>([
    { value: null, stock: null },
  ]);
  const [form] = Form.useForm();

  const editPortfolio = useCallback(
    (values) => {
      setLoading(true);
      if (selectedPortfolioId) {
        sdk
          .editPortfolio({
            _id: selectedPortfolioId,
            portfolio: {
              active,
              name: values.name_mn,
              description: values.description_mn,
              index: Number(values.index),
              riskLevel: Number(values.riskLevel),
              duration: values.duration,
              bond: { value: Number(values.bond) },
              saving: { value: Number(values.saving) },
              stocks: stocks
                .map((stock) => ({
                  stock: stock?.stock?._id!,
                  value: Number(stock?.value),
                }))
                .filter((stock) => stock.value && stock.stock),
              localizedName: {
                en: values.name_en,
                mn: values.name_mn,
              },
              localizedDescription: {
                en: values.description_en,
                mn: values.description_mn,
              },
            },
          })
          .then((result) => {
            if (result) {
              getPortfolios?.();
              message.success("Амжилттай засагдлаа", 2);
            }
            setLoading(false);
          })
          .catch((err) => {
            message.warn(err.response?.errors[0]?.message, 2);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    },
    [active, getPortfolios, selectedPortfolioId, stocks]
  );
  const addPortfolio = useCallback(
    (values) => {
      setLoading(true);
      sdk
        .addPortfolio({
          portfolio: {
            active,
            name: values.name_mn,
            description: values.description_mn,
            index: Number(values.index),
            riskLevel: Number(values.riskLevel),
            duration: values.duration,
            bond: { value: Number(values.bond) },
            saving: { value: Number(values.saving) },
            stocks: stocks
              .map((stock) => ({
                stock: stock?.stock?._id!,
                value: Number(stock?.value),
              }))
              .filter((stock) => stock.value && stock.stock),
            localizedName: {
              en: values.name_en,
              mn: values.name_mn,
            },
            localizedDescription: {
              en: values.description_en,
              mn: values.description_mn,
            },
          },
        })
        .then((result) => {
          if (result) {
            getPortfolios?.();
            message.success("Амжилттай нэмэгдлээ", 2);
          }
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [active, form, getPortfolios, stocks]
  );

  return {
    form,
    stocks,
    loading,
    setStocks,
    addPortfolio,
    editPortfolio,
  };
};
