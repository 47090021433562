import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { GetGroupActivitiesQuery } from "../../graphql";
import sdk from "../../sdk";

export const useGroupActivity = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] =
    useState<GetGroupActivitiesQuery["getGroupActivities"]>();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const getGroupActivities = useCallback(() => {
    setLoading(true);
    sdk
      .getGroupActivities({ page, limit })
      .then((data) => setData(data.getGroupActivities))
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [page, limit]);

  const deleteGroupActivity = (id: string) => {
    setLoading(true);
    sdk
      .deleteGroupActivity({ _id: id })
      .then(() => {
        message.success("Амжилттай устгагдлаа");
        getGroupActivities();
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const sendGroupActivity = (id: string) => {
    setLoading(true);
    sdk
      .sendGroupActivity({ _id: id })
      .then(() => {
        message.success("Амжилттай илгээгдлээ");
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getGroupActivities();
  }, [getGroupActivities, page]);
  return {
    data,
    loading,
    getGroupActivities,
    deleteGroupActivity,
    sendGroupActivity,
    setPage,
    limit,
    setLimit,
  };
};
