import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table, message } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import sdk from "@/sdk";
import { formatBalance } from "@/utils/formatters";

import { useGetBalance } from "../useGetBalance";

export function BalanceBondHistoryWidget({
  _id,
  index,
}: {
  _id: string;
  index: string;
}) {
  const { data, loading } = useGetBalance({ _id });
  const bondIndex = parseInt(index);

  const [loadingRecordId, setLoadingRecordId] = useState<string | null>(null);

  const history = useHistory();
  return (
    <>
      <div className="page-header">
        <h3>Бонд дэлгэрэнгүй түүх</h3>
        <div className="page-header-buttons">
          <Button
            icon={<ArrowLeftOutlined />}
            className="back-button"
            onClick={() => history.goBack()}
          >
            Буцах
          </Button>
        </div>
      </div>

      <div className="page-body">
        <Table
          bordered
          size="small"
          loading={loading}
          dataSource={
            data?.bonds?.[bondIndex]?.history?.sort(
              (a, b) => b.createdAt - a.createdAt
            ) ?? []
          }
          rowKey={(record) => record._id}
          columns={[
            {
              title: "Огноо",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (text) => (
                <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span>
              ),
            },
            {
              title: "Нийт үлдэгдэл",
              dataIndex: "principleAmount",
              key: "principleAmount",
              render: (text) => (
                <span>{formatBalance({ balance: text })} ₮</span>
              ),
            },
            {
              title: "Шимтгэл",
              dataIndex: "fee",
              key: "fee",
              render: (text) => (
                <span>{formatBalance({ balance: text })} ₮</span>
              ),
            },
            {
              title: "Үйлдэл",
              dataIndex: "action",
              key: "action",
              render: (_, record) => (
                <Button
                  disabled={!!loadingRecordId}
                  loading={loadingRecordId === record._id}
                  onClick={async () => {
                    setLoadingRecordId(record._id);
                    try {
                      const { relatedBondPurchase } =
                        await sdk.relatedBondPurchase({
                          balanceId: _id,
                          historyEntryId: record._id,
                        });
                      if (!relatedBondPurchase?._id) {
                        throw Error("Холбоотой худалдан авалт олдсонгүй");
                      }
                      history.push(
                        `/balanceHistory/${_id}?purchase=${relatedBondPurchase._id}`
                      );
                    } catch (e) {
                      const err = e as any;
                      if ("response" in err) {
                        message.warn(err.response?.errors[0]?.message, 2);
                      } else {
                        message.error(err.message, 2);
                      }
                    } finally {
                      setLoadingRecordId(null);
                    }
                  }}
                >
                  Холбоотой худалдан авалт
                </Button>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}
