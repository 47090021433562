import { createContext, Dispatch, useState } from "react";
import { DateRange, Role, Status, UserType } from "../graphql";
export type SearchContextType = {
  search: string;
  setSearch: Dispatch<string>;
  type: UserType | null;
  setType: Dispatch<UserType | null>;
  status: Status | null;
  setStatus: Dispatch<Status | null>;
  role: Role | null;
  setRole: Dispatch<Role | null>;
  dateRange: DateRange | null;
  setDateRange: Dispatch<DateRange | null>;
};
export const SearchCtx = createContext<SearchContextType>({
  setSearch: () => {},
  search: "",
  type: null,
  setType: () => {},
  status: null,
  setStatus: () => {},
  role: null,
  setRole: () => {},
  dateRange: null,
  setDateRange: () => {},
});

const SearchProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [search, setSearch] = useState<string>("");
  const [type, setType] = useState<UserType | null>(null);
  const [status, setStatus] = useState<Status | null>(null);
  const [role, setRole] = useState<Role | null>(null);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  return (
    <SearchCtx.Provider
      value={{
        search,
        setSearch,
        type,
        setType,
        status,
        setStatus,
        role,
        setRole,
        dateRange,
        setDateRange,
      }}
    >
      {children}
    </SearchCtx.Provider>
  );
};

export default SearchProvider;
