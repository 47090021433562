import { Button, Checkbox, message, Space, Table } from "antd";
import { Link, useHistory } from "react-router-dom";

import {
  ArrowLeftOutlined,
  CheckOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import sdk from "@/sdk";
import { formatAmount } from "@/utils/formatters";
import { useState } from "react";

type RefundDetailWidgetProps = {
  id: string;
};

export function RefundDetailWidget({ id }: RefundDetailWidgetProps) {
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const history = useHistory();
  const { data, isLoading, refetch } = useQuery(
    ["previewListTransfers"],
    async () => sdk.previewWithdrawalOrderListTransfers({ orderListId: id }),
    {
      select: (data) => data.previewWithdrawalOrderListTransfers,
    }
  );

  const { mutate, isLoading: mutateLoading } = useMutation({
    mutationFn: async () =>
      sdk
        .downloadWithdrawalConfirmationReport({ orderListId: id })
        .then((res) => {
          window.open(res.downloadWithdrawalConfirmationReport, "_blank");
        })
        .catch((e) => message.error((e as Error).message)),
  });

  const { mutate: completeMutate, isLoading: completeLoading } = useMutation({
    mutationFn: async () =>
      sdk
        .completeWithdrawalRequestsTransfer({ ids: selectedIds })
        .then((res) => {
          if (res.completeWithdrawalRequestsTransfer.orderListComplete) {
            history.push("/refund");
          }
          refetch();
        })
        .catch((e) => message.error((e as Error).message)),
  });

  return (
    <div>
      <div className="page-header">
        <h3>Буцаалтын дэлгэрэнгүй</h3>
        <Space>
          <Button
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => completeMutate()}
            disabled={completeLoading}
            loading={completeLoading}
          >
            Баталгаажуулах
          </Button>
          <Button
            icon={<FileProtectOutlined />}
            onClick={() => mutate()}
            loading={mutateLoading}
            disabled={mutateLoading}
          >
            Дэлгэрэнгүй эксел татах
          </Button>
          <Link to="/refund">
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Буцах
            </Button>
          </Link>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            size="small"
            bordered
            loading={isLoading}
            dataSource={data}
            columns={[
              {
                title: "",
                dataIndex: "actions",
                key: "actions",
                render: (_, record) => (
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedIds((curr) => [
                          ...curr,
                          record.withdrawalRequestId,
                        ]);
                      } else {
                        setSelectedIds((curr) =>
                          curr.filter((id) => id !== record.withdrawalRequestId)
                        );
                      }
                    }}
                  />
                ),
              },
              {
                title: "Овог",
                dataIndex: "lastName",
                key: "lastName",
                render: (_, record) => <span>{record.lastName}</span>,
              },
              {
                title: "Нэр",
                dataIndex: "firstName",
                key: "firstName",
                render: (_, record) => <span>{record.firstName}</span>,
              },
              {
                title: "Регистерийн дугаар",
                dataIndex: "registerNo",
                key: "registerNo",
                render: (_, record) => <span>{record.registerNo}</span>,
              },
              {
                title: "Банк",
                dataIndex: "bankName",
                key: "bankName",
                render: (_, record) => <span>{record.bankName}</span>,
              },
              {
                title: "Дансны дугаар",
                dataIndex: "bankId",
                key: "bankId",
                render: (_, record) => <span>{record.accountNo ?? ""}</span>,
              },
              {
                title: "Нийт дүн",
                dataIndex: "totalAmount",
                key: "widthdrawalRequestId",
                render: (_, record) => (
                  <span>{formatAmount({ amount: +record.totalAmount })}₮</span>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}
