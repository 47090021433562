import { message } from "antd";
import { useCallback, useState } from "react";
import sdk from "../../sdk";

export const useBalanceDetail = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string | null>(null);

  const generateBalancesDetail = useCallback(() => {
    setLoading(true);
    sdk
      .generateBalancesDetail()
      .then((res) => {
        setData(res.generateBalancesDetail);
        setLoading(false);
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, []);

  return {
    data,
    loading,
    generateBalancesDetail,
  };
};
