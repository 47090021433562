import { jwtDecode } from "jwt-decode";

import { Role } from "../graphql";

export type ParamType = {
  _id: string;
};

export function isLoggedIn(): boolean {
  return !!localStorage.getItem("auth");
}

export function getUserData() {
  let token = localStorage.getItem("auth");
  if (!token) {
    return null;
  }

  try {
    let decodedToken: any = jwtDecode(token);
    // user field could be null, and the typeof operator will return 'object' in this case
    if (typeof decodedToken.user !== "object" || !decodedToken.user) {
      return null;
    }

    const { user } = decodedToken;
    if (
      user.role !== Role.Admin &&
      user.role !== Role.Operator &&
      user.role !== Role.Apex
    ) {
      return null;
    }
    return user;
  } catch {
    return null;
  }
}
