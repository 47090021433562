import { Form, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { Image } from "../../../graphql";
import sdk from "../../../sdk";

export const useEditStock = ({
  selectedStockId,
}: {
  selectedStockId?: string;
}) => {
  const [form] = Form.useForm();
  const [image, setImage] = useState<Image | null>(null);
  const [loading, setLoading] = useState(false);

  const getStock = useCallback(() => {
    setLoading(true);
    if (selectedStockId) {
      sdk
        .getStock({ _id: selectedStockId })
        .then((data) => {
          form.setFields([
            {
              name: "name_mn",
              value: data.getStock.localizedName?.mn,
            },
            {
              name: "name_en",
              value: data.getStock.localizedName?.en,
            },
            {
              name: "description_mn",
              value: data.getStock.localizedDescription?.mn,
            },
            {
              name: "description_en",
              value: data.getStock.localizedDescription?.en,
            },
            {
              name: "url",
              value: data.getStock.url ?? null,
            },
            {
              name: "symbol",
              value: data.getStock.symbol,
            },
            {
              name: "type",
              value: data.getStock.type,
            },
          ]);
          if (data.getStock.image) {
            setImage(data.getStock.image);
          }
        })
        .catch((err) => {
          setLoading(false);
          message.warn(err.message);
        })
        .finally(() => setLoading(false));
    }
  }, [form, selectedStockId]);

  useEffect(() => {
    if (selectedStockId) {
      getStock();
    }
  }, [selectedStockId, getStock]);
  const editStock = useCallback(
    (stock) => {
      if (selectedStockId) {
        setLoading(true);
        sdk
          .editStock({
            _id: selectedStockId,
            stock: {
              symbol: stock.symbol,
              type: stock.type,
              image: image ?? null,
              url: stock.url,
              localizedDescription: {
                mn: stock.description_mn,
                en: stock.description_en,
              },
              localizedName: {
                mn: stock.name_mn,
                en: stock.name_en,
              },
            },
          })
          .then(() => {
            message.success("Амжилттай засагдлаа", 2);
            setLoading(false);
          })
          .catch((err) => {
            message.warn(err.response?.errors[0]?.message, 2);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    },
    [image, selectedStockId]
  );
  const addStock = useCallback(
    (stock) => {
      setLoading(true);
      sdk
        .addStock({
          stock: {
            symbol: stock.symbol,
            type: stock.type,
            image: image ?? null,
            url: stock.url,
            localizedDescription: {
              mn: stock.description_mn,
              en: stock.description_en,
            },
            localizedName: { mn: stock.name_mn, en: stock.name_en },
          },
        })
        .then(() => {
          message.success("Амжилттай нэмэгдлээ", 2);
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [form, image]
  );

  return {
    form,
    image,
    loading,
    addStock,
    editStock,
    setImage,
  };
};
