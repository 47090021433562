import moment from "moment";
import invariant from "ts-invariant";
import { debounce } from "ts-debounce";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  PlusOutlined,
  DeleteOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { Space, Button, Table, Tag, Popconfirm, message } from "antd";

import sdk from "@/sdk";
import { OrderStatus, GetOrdersQuery, OrderType } from "@/graphql";

import { formatBalance } from "@/utils/formatters";

import { OrderFilters } from "./Filters";
import { AddOrderWidget } from "./modal/create";
import { ReturnOrderModal } from "./modal/Return";

export const OrderWidget = ({ status }: { status: OrderStatus }) => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(moment().subtract(3, "months").format()).setHours(0, 0, 0, 0)
  );
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59));
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);

  const advancedOptions = {
    status,
    date: { startDate, endDate },
    search,
  } as const;

  const queryKey = ["orders", page, advancedOptions];
  const { isLoading, data, refetch } = useQuery({
    queryKey,
    queryFn: (): Promise<GetOrdersQuery> =>
      sdk.getOrders({
        page: page,
        limit: 20,
        advanced: advancedOptions,
      }),
    select: (data) => data.getOrders,
  });
  const queryClient = useQueryClient();
  const { isLoading: isDeleting, mutate } = useMutation({
    mutationFn: (id: string) => sdk.removeOrder({ id }),
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
    onSuccess: () => message.success("Амжилттай устгагдлаа.", 2),
  });

  const [returnOrderId, setReturnOrderId] = useState<string | null>(null);
  return (
    <>
      <AddOrderWidget
        user={null}
        show={show}
        setShow={setShow}
        getOrders={refetch}
      />
      <ReturnOrderModal
        orderId={returnOrderId}
        close={() => setReturnOrderId(null)}
      />
      <div className="page-header">
        <h3>Гүйлгээний жагсаалт</h3>
        <OrderFilters
          search={search}
          onSearchChange={debounce(setSearch, 500)}
          startDate={startDate}
          onStartDateChange={setStartDate}
          endDate={endDate}
          onEndDateChange={setEndDate}
        >
          <Space>
            <Button
              loading={isLoading}
              style={{ marginTop: "21px" }}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShow(true)}
            >
              Цэнэглэлт нэмэх
            </Button>
          </Space>
        </OrderFilters>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data?.orders ?? []}
            size="small"
            loading={isLoading}
            pagination={{
              pageSize: 20,
              total: Number(data?.totalPages) * 20,
            }}
            onChange={(pagination) => setPage(Number(pagination?.current) - 1)}
            bordered
            columns={[
              {
                title: "Төлөв",
                dataIndex: "status",
                key: "status",
                render: (text) => <Tag>{text}</Tag>,
              },
              {
                title: "Хүлээн авагчийн утасны дугаар",
                dataIndex: "phoneNo",
                key: "phoneNo",
                render: (_, record) => (
                  <span>{record.user.phoneNo ?? record.user.email}</span>
                ),
              },
              {
                title: "Төрөл",
                dataIndex: "type",
                key: "_id",
                render: (value) => (
                  <span>
                    {value === OrderType.Income ? "Орлого" : "Зарлага"}
                  </span>
                ),
              },
              {
                title: "Гүйлгээний дүн",
                dataIndex: "amount",
                key: "amount",
                render: (text) => (
                  <span>{formatBalance({ balance: text })} ₮</span>
                ),
              },
              {
                title: "Гүйлгээ хийгдсэн огноо",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (text) => (
                  <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span>
                ),
              },
              {
                title: "Үйлдлүүд",
                key: "actions",
                render: (_, record) => {
                  if (record.type === OrderType.Withdrawal) {
                    return;
                  }
                  return (
                    <Space>
                      {record.isReturnable && (
                        <Button
                          type="primary"
                          danger
                          size="middle"
                          shape="circle"
                          icon={<WalletOutlined />}
                          onClick={() => {
                            invariant(record._id, "Order must have an id");
                            setReturnOrderId(record._id);
                          }}
                        />
                      )}
                      <Popconfirm
                        disabled={isDeleting}
                        title="Та устгахдаа итгэлтэй байна уу?"
                        okText="Тийм"
                        cancelText="Үгүй"
                        onConfirm={() => {
                          invariant(record._id, "Order must have an id");
                          mutate(record._id);
                        }}
                      >
                        <Button
                          type="primary"
                          danger
                          size="middle"
                          shape="circle"
                          disabled={isDeleting}
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
