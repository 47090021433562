import { useEffect } from "react";
import { FAQWidget } from "../../widgets/faq";

export const FAQPage = () => {
  useEffect(() => {
    document.title = "Түгээмэл асуулт хариулт";
  }, []);
  return (
    <div className="page">
      <FAQWidget />
    </div>
  );
};
