export const KYCRejectOptions = [
  {
    key: "1",
    description:
      "Таны овог нэр буруу байна. Та шалгаад дахин явуулна уу. Баярлалаа",
  },
  {
    key: "2",
    description:
      "Таны регистр, төрсөн огноо буруу байна. Та шалгаад дахин явуулна уу. Баярлалаа",
  },
  {
    key: "3",
    description:
      "Таны гэрийн хаяг буруу байна. Та шалгаад дахин явуулна уу. Баярлалаа",
  },
  {
    key: "4",
    description:
      "Таны бичиг баримтын зураг шаардлага хангахгүй байна. Та шалгаад дахин явуулна уу. Баярлалаа",
  },
  {
    key: "5",
    description:
      "Таны селфи зураг шаардлага хангахгүй байна. Та шалгаад дахин явуулна уу. Баярлалаа",
  },
];
