import { useEffect } from "react";

import RobotSvg from "../components/svgs/robot.svg";

export const NotfoundPage = () => {
  useEffect(() => {
    document.title = "Not found";
  }, []);
  return (
    <div
      className="page"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img src={RobotSvg} alt="not found" style={{ width: 400 }} />
    </div>
  );
};
