import { useCallback, useEffect, useState } from "react";
import { GetKycByUserQuery, Status } from "../../../graphql";
import sdk from "../../../sdk";
import { Form } from "antd";
export const useGetKYCByUser = (_id: string | null) => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetKycByUserQuery | null>();
  const [status, setStatus] = useState<Status>();
  const [form] = Form.useForm();
  const getKYCByUser = useCallback(() => {
    setLoading(true);
    if (_id) {
      sdk
        .getKYCByUser({ _id })
        .then((data) => {
          setData(data);
          setStatus(data.getKYCByUser.info.status);
          form.setFields([
            {
              name: "status",
              value: data.getKYCByUser.info.status,
            },
            {
              name: "description",
              value: data.getKYCByUser.info.description,
            },
          ]);
        })
        .catch((err) => {
          setError(err.response?.errors[0]?.message);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  }, [_id, form]);
  useEffect(() => {
    getKYCByUser();
  }, [getKYCByUser]);
  return {
    data: data?.getKYCByUser,
    loading,
    error,
    setError,
    setData,
    form,
    status,
    setStatus,
  };
};
