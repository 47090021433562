import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useHistory } from "react-router-dom";

import { Button, message, Space, Steps } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import { WithdrawConfirm } from "./steps/WithdrawConfirm";
import { WithdrawBond } from "./steps/WithdrawBond";
import { WithdrawSavings } from "./steps/WithdrawSavings";
import { WithdrawEnd } from "./steps/WithdrawEnd";

import Excel from "exceljs";

import sdk from "@/sdk";
import {
  ConfirmWithdrawalOrderListMutationVariables,
  WithdrawalOrderListBondTotals,
  WithdrawalOrderListEstateTotals,
  WithdrawalOrderListStockTotals,
} from "@/graphql";
import { FullLoading } from "@/components/FullLoading";

export type WithdrawStockData = {
  Symbol: string;
  Quantity: string;
  Price: string;
  Proceeds: string;
  Commision: string;
};

type ConfirmWidgetProps = {
  _id: string;
};

const tabsMenu = [
  {
    title: "Үнэт цаас",
    index: 0,
  },
  {
    title: "Бонд",
    index: 1,
  },
  {
    title: "Үл хөдлөх хөрөнгө",
    index: 2,
  },
  {
    title: "Баталгаажуулах",
    index: 3,
  },
];

export function RefundConfirmWidget({ _id }: ConfirmWidgetProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const [stockData, setStockData] = useState<WithdrawStockData[]>([]);
  const [dollar, setDollar] = useState(0);

  const { data, isLoading } = useQuery(["getWithdrawal", _id], async () =>
    sdk.withdrawalOrderList({ id: _id })
  );

  const history = useHistory();

  const { mutate, isLoading: mutateLoading } = useMutation({
    mutationFn: async (values: ConfirmWithdrawalOrderListMutationVariables) =>
      sdk
        .confirmWithdrawalOrderList({ ...values })
        .then((res) => {
          if (res.confirmWithdrawalOrderList) {
            history.push("/refund");
          }
        })
        .catch((e) => {
          message.error((e as Error).message, 2);
        }),
  });

  const handleImport = (event: ChangeEvent<HTMLInputElement>) => {
    if (data && data.withdrawalOrderList && event.target.files) {
      const wb = new Excel.Workbook();
      const reader = new FileReader();
      reader.readAsArrayBuffer(event.target.files[0]);
      const stocks = new Set<string>();
      data.withdrawalOrderList.stockTotals.map((value) => {
        if (value.stock.symbol !== "FX") {
          stocks.add(value.stock.symbol);
        }
      });
      reader.onload = () => {
        const buffer = reader.result as ArrayBuffer;
        wb.xlsx.load(buffer).then((workbook) => {
          const obj = [] as WithdrawStockData[];
          workbook.eachSheet((sheet) => {
            sheet.eachRow((row: any, rowIndex: number) => {
              if (rowIndex <= 1) return;
              const find = data.withdrawalOrderList?.stockTotals.find(
                (stock) => stock.stock.symbol === row.values[5]
              );
              if (find) {
                stocks.delete(row.values[5]);
                obj.push({
                  Commision: row.values[12],
                  Price: row.values[10],
                  Proceeds: row.values[11],
                  Quantity: row.values[9],
                  Symbol: row.values[5],
                });
              }
            });
          });
          if (stocks.size === 0) {
            setStockData(obj);
            message.success("Амжилттай хуулагдлаа", 1);
          } else {
            message.warn(
              Array.from(stocks).map((value) => value) + " хувьцаа олдсонгүй",
              2
            );
          }
        });
      };
    }
  };

  const StepsComponent = useMemo(() => {
    const request = data?.withdrawalOrderList;

    if (request) {
      if (currentStep === 0)
        return (
          <WithdrawConfirm
            dollar={dollar}
            setDollar={setDollar}
            setStockData={setStockData}
            handleImport={handleImport}
            stocks={
              typeof request?.stockTotals === "undefined"
                ? undefined
                : (request?.stockTotals as WithdrawalOrderListStockTotals[])
            }
          />
        );
      if (currentStep === 1)
        return (
          <WithdrawBond
            bond={
              typeof request?.bondTotals === "undefined"
                ? undefined
                : (request.bondTotals as WithdrawalOrderListBondTotals[])
            }
          />
        );
      if (currentStep === 2)
        return (
          <WithdrawSavings
            savings={
              typeof request?.estateTotals === "undefined"
                ? undefined
                : (request?.estateTotals as WithdrawalOrderListEstateTotals[])
            }
          />
        );
      return (
        <WithdrawEnd
          stocks={stockData}
          dollar={dollar}
          savings={
            typeof request?.estateTotals === "undefined"
              ? undefined
              : (request?.estateTotals as WithdrawalOrderListEstateTotals[])
          }
          bonds={
            typeof request?.bondTotals === "undefined"
              ? undefined
              : (request.bondTotals as WithdrawalOrderListBondTotals[])
          }
          orderId={_id}
        />
      );
    }
    return <div>Буцаалт олдсонгүй</div>;
  }, [currentStep, data, dollar, stockData]);

  const handleNext = useCallback(() => {
    if (currentStep !== 3) {
      setCurrentStep((curr) => curr + 1);
      return;
    }
    const parseAsDecimal4 = (n: string | number) =>
      typeof n === "string"
        ? n.startsWith("-")
          ? n.substring(1)
          : n
        : n.toFixed(4);
    mutate({
      id: _id,
      confirmationData: {
        usdRate: dollar,
        stocks: stockData.map((value) => ({
          comission: parseAsDecimal4(value.Commision),
          price: parseAsDecimal4(value.Price),
          quantity: parseAsDecimal4(value.Quantity),
          symbol: value.Symbol,
          totalPrice: parseAsDecimal4(value.Proceeds),
        })),
      },
    });
  }, [currentStep]);

  const handleBack = () => {
    setCurrentStep((curr) => curr - 1);
  };

  return (
    <div>
      <div className="page-header">
        <h3>Буцаалт биелүүлэх</h3>
        <Link to="/refund">
          <Button type="dashed" icon={<ArrowLeftOutlined />}>
            Буцах
          </Button>
        </Link>
      </div>
      <Steps current={currentStep} style={{ marginTop: 20 }}>
        {tabsMenu.map((value) => (
          <Steps.Step title={value.title} key={value.index} />
        ))}
      </Steps>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "center",
            paddingTop: 100,
          }}
        >
          <div style={{ width: 50 }}>
            <FullLoading />
          </div>
        </div>
      ) : (
        StepsComponent
      )}

      <Space style={{ marginTop: 20 }}>
        {currentStep > 0 && (
          <Button
            type="dashed"
            onClick={handleBack}
            icon={<ArrowLeftOutlined />}
          >
            Буцах
          </Button>
        )}
        <Button
          type="primary"
          onClick={handleNext}
          loading={mutateLoading}
          disabled={currentStep === 0 ? stockData.length === 0 : false}
        >
          {currentStep === 3 ? "Баталгаажуулах" : "Үргэлжлүүлэх"}
          {currentStep === 3 ? <CheckOutlined /> : <ArrowRightOutlined />}
        </Button>
      </Space>
    </div>
  );
}
