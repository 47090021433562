import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { OrderStatus } from "../graphql";
import { OrderWidget } from "../widgets/order";

export const { TabPane } = Tabs;

export const OrderPage = () => {
  const [status, setStatus] = useState<OrderStatus>(OrderStatus.Confirmed);
  useEffect(() => {
    document.title = "Цэнэглэлт";
  }, []);
  return (
    <div className="page">
      <Tabs defaultActiveKey="1" onChange={(e) => setStatus(e as OrderStatus)}>
        {Object.values(OrderStatus).map((orderStatus) => (
          <TabPane tab={orderStatus} key={orderStatus}>
            <OrderWidget status={status} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};
