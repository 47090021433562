import { Col, Row, Table } from "antd";

import { formatAccountNo, formatAmount } from "@/utils/formatters";
import { WithdrawStockData } from "../RefundConfirmWidget";
import {
  WithdrawalOrderListBondTotals,
  WithdrawalOrderListEstateTotals,
} from "@/graphql";
import { useQuery } from "@tanstack/react-query";
import sdk from "@/sdk";

type WithdrawEndProps = {
  stocks: WithdrawStockData[];
  dollar: number;
  savings?: WithdrawalOrderListEstateTotals[];
  bonds?: WithdrawalOrderListBondTotals[];
  orderId: string;
};

export function WithdrawEnd({
  stocks,
  dollar,
  bonds,
  savings,
  orderId,
}: WithdrawEndProps) {
  const { data, isLoading } = useQuery(
    ["getInterests", orderId],
    async () =>
      sdk.previewWithdrawalOrderListInterests({ orderListId: orderId }),
    {
      select: (data) => data.previewWithdrawalOrderListInterests,
    }
  );
  return (
    <Row gutter={[24, 24]} style={{ padding: "24px 0 0 0" }}>
      <Col span={24}>
        <h3>Долларын ханш: {dollar}</h3>
        <h3 style={{ margin: "12px 0" }}>Үнэт цаас/Хавсралт файлын өгөгдөл/</h3>
        <Table
          pagination={false}
          dataSource={stocks}
          loading={isLoading}
          size="small"
          columns={[
            {
              title: "Тэмдэгт",
              dataIndex: "symbol",
              key: "symbol",
              render: (_, record) => <span>{record.Symbol}</span>,
            },
            {
              title: "Тоо ширхэг",
              dataIndex: "quantity",
              key: "quantity",
              render: (_, record) => <span>{-1 * +record.Quantity}₮</span>,
            },
            {
              title: "Нэгжийн үнэ",
              dataIndex: "Price",
              key: "price",
              render: (_, record) => (
                <span>{formatAmount({ amount: +record.Price })}₮</span>
              ),
            },
            {
              title: "Нийт дүн",
              dataIndex: "proceeds",
              key: "proceeds",
              render: (_, record) => (
                <span>{formatAmount({ amount: -1 * +record.Proceeds })}₮</span>
              ),
            },
            {
              title: "Шимтгэл",
              dataIndex: "commision",
              key: "commision",
              render: (_, record) => (
                <span>
                  {formatAmount({ amount: -1 * +record.Commision })}₮{" "}
                </span>
              ),
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <h3 style={{ margin: "12px 0" }}>Бонд</h3>
        <Table
          pagination={false}
          loading={isLoading}
          dataSource={bonds ?? []}
          rowKey={(item) => item.asset._id + ""}
          size="small"
          columns={[
            {
              title: "Буцаасан дүн",
              dataIndex: "amount",
              key: "amount",
              render: (_, render) => (
                <span>
                  {formatAmount({ amount: render.totalAmount, decimals: 10 })}₮
                </span>
              ),
            },
            {
              title: "Бондын нэр",
              dataIndex: "name",
              key: "name",
              render: (_, record) => <span>{record.asset.name}</span>,
            },
            {
              title: "Жилийн хүү (%)",
              dataIndex: "interestRate",
              key: "interestRate",
              render: (_, record) => <span>{record.asset.interestRate}</span>,
            },
            {
              title: "Нийт олгогдох хүү",
              dataIndex: "startDate",
              key: "startDate",
              render: (_, record) => (
                <span>
                  {
                    data?.bonds.find(
                      (bond) => bond?.assetId === record.asset._id
                    )?.totalInterest
                  }
                </span>
              ),
            },
            {
              title: "Нийт татвар",
              dataIndex: "endDate",
              key: "endDate",
              render: (_, record) => (
                <span>
                  {
                    data?.bonds.find(
                      (bond) => bond?.assetId === record.asset._id
                    )?.totalTax
                  }
                </span>
              ),
            },
            {
              title: "Шимтгэл дүн",
              dataIndex: "fee",
              key: "fee",
              render: (text) => <>{formatAmount({ amount: text })}₮</>,
            },
          ]}
        />
      </Col>

      <Col span={24}>
        <h3 style={{ margin: "12px 0" }}>Үл хөдлөх хөрөнгө</h3>
        <Table
          pagination={false}
          loading={isLoading}
          rowKey={(item) => item.asset._id + ""}
          dataSource={savings ?? []}
          size="small"
          columns={[
            {
              title: "Худалдаж авсан дүн",
              dataIndex: "amount",
              key: "amount",
              render: (_, record) => (
                <>{formatAmount({ amount: record.totalAmount })}₮</>
              ),
            },
            {
              title: "Банкны нэр",
              dataIndex: "bankName",
              key: "bankName",
              render: (_, record) => <span>{record.asset.bankName}</span>,
            },
            {
              title: "Дансны дугаар",
              dataIndex: "accountNo",
              key: "accountNo",
              render: (_, render) => (
                <span>
                  {formatAccountNo({ accountNo: render.asset.accountNo })}
                </span>
              ),
            },
            {
              title: "Жилийн хүү (%)",
              dataIndex: "interestRate",
              key: "interestRate",
              render: (_, render) => <span>{render.asset.interestRate}</span>,
            },
            {
              title: "Нийт олгогдох хүү",
              dataIndex: "startDate",
              key: "startDate",
              render: (_, record) => (
                <span>
                  {
                    data?.estate.find(
                      (state) => state?.assetId === record.asset._id
                    )?.totalInterest
                  }
                </span>
              ),
            },
            {
              title: "Нийт татвар",
              dataIndex: "endDate",
              key: "endDate",
              render: (_, record) => (
                <span>
                  {
                    data?.estate.find(
                      (state) => state?.assetId === record.asset._id
                    )?.totalTax
                  }
                </span>
              ),
            },
            {
              title: "Шимтгэл дүн",
              dataIndex: "fee",
              key: "fee",
              render: (_, render) => <>{formatAmount({ amount: 100 })}₮</>,
            },
          ]}
        />
      </Col>
    </Row>
  );
}
