import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Space,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Dispatch, useCallback, useEffect, useState } from "react";

import { ChooseBond } from "../../../components/ChooseBond";
import { GetBondsQuery, GetOrderListQuery } from "../../../graphql";
import { formatInput } from "../../../utils/formatters";

export type BondForm = {
  _id?: string | undefined;
  name: string;
  symbol: string;
  interestRate: number;
  amount: number;
  fee: number;
};

const OrderConfirmBonds = ({
  data,
  bondsData,
  bondForm,
  next,
  back,
  setBondForm,
}: {
  data: GetOrderListQuery["getOrderList"] | undefined;
  bondsData: GetBondsQuery["getBonds"] | undefined;
  bondForm: BondForm[];
  setBondForm: Dispatch<BondForm[]>;
  next: () => void;
  back: () => void;
}) => {
  const [bonds, setBonds] = useState<BondForm[]>();
  const [isChooseBond, setIsChooseBond] = useState(false);

  useEffect(() => {
    if (bondForm) setBonds(bondForm);
  }, [bondForm]);

  const addBond = useCallback(
    (param: BondForm) => {
      if (bonds) {
        const finded = bonds?.find((b) => b?._id === param?._id);
        if (finded) {
          message.warn("Сонгогдсон бонд байна.", 2);
          return;
        } else {
          setBonds([...bonds!, param]);
        }
      } else {
        setBonds([param]);
      }
    },
    [bonds]
  );

  const deleteBond = (id: string | undefined) => {
    if (id && bonds && bonds.length !== 0) {
      const result = bonds?.filter((b) => b._id !== id);
      setBonds([...result]);
    }
  };

  const handleChange = useCallback(
    (index: number, value: string, name: string) => {
      if (bonds) {
        if (name === "amount") {
          let result = [...bonds];
          result[index].amount = Number(value);
          setBonds(result);
        }
        if (name === "fee") {
          let result = [...bonds];
          result[index].fee = Number(value);
          setBonds(result);
        }
      }
    },
    [bonds]
  );

  const onBack = useCallback(() => {
    if (bonds) setBondForm(bonds);
    back();
  }, [back, bonds, setBondForm]);

  const submit = useCallback(() => {
    if (bonds && bonds.length !== 0) {
      setBondForm(bonds);
    }
    next();
  }, [next, bonds, setBondForm]);

  return (
    <>
      <ChooseBond
        show={isChooseBond}
        setShow={setIsChooseBond}
        bonds={bondsData}
        submit={(param: BondForm) => addBond(param)}
      />
      <Form
        layout="vertical"
        autoComplete="off"
        style={{ padding: "24px 0 0 0" }}
        onFinish={submit}
      >
        <Row gutter={[48, 12]}>
          <Col span={24}>
            <Space align="end">
              <Form.Item label="Нийт худалдаж авах үнэ">
                <Input
                  value={formatInput({
                    balance: data?.totalPurchases.bond.amount ?? 0,
                  })}
                  disabled={true}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  onClick={() => setIsChooseBond(true)}
                  icon={<PlusOutlined />}
                  type="primary"
                >
                  Бонд нэмэх
                </Button>
              </Form.Item>
            </Space>
          </Col>
          {bonds?.map((bond, index) => (
            <Col span={6} key={index}>
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <p style={{ margin: "0 0 5px 0" }}>Бондын нэр</p>
                  <Input value={bond?.name ?? "хоосон"} disabled={true} />
                </Col>
                <Col span={24}>
                  <p style={{ margin: "0 0 5px 0" }}>Худалдаж авсан үнэ</p>
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={bond?.amount}
                    onChange={(value) =>
                      handleChange(index, value?.toString() ?? "0", "amount")
                    }
                  />
                </Col>
                <Col span={24}>
                  <p style={{ margin: "0 0 5px 0" }}>Шимтгэл дүн</p>
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    value={bond?.fee}
                    onChange={(value) =>
                      handleChange(index, value?.toString() ?? "0", "fee")
                    }
                  />
                </Col>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Popconfirm
                    title="Та устгахдаа итгэлтэй байна уу?"
                    okText="Тийм"
                    cancelText="Үгүй"
                    onConfirm={() => deleteBond(bond._id?.toString())}
                  >
                    <Button danger type="primary" icon={<DeleteOutlined />}>
                      Устгах
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          ))}
          <Col span={24} style={{ textAlign: "right" }}>
            <Form.Item>
              <Space>
                <Button
                  type="dashed"
                  onClick={onBack}
                  icon={<ArrowLeftOutlined />}
                >
                  Буцах
                </Button>
                <Button type="primary" onClick={submit}>
                  Үргэлжлүүлэх
                  <ArrowRightOutlined />
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default OrderConfirmBonds;
