import { useState } from "react";
import { message } from "antd";
import { useCallback, useEffect } from "react";
import { GetSurveysQuery } from "../../graphql";
import sdk from "../../sdk";

export const useSurveys = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetSurveysQuery>();

  const getSurveys = useCallback(() => {
    setLoading(true);
    sdk
      .getSurveys()
      .then((data) => setData(data))
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, []);

  const deleteSurvey = (id: string) => {
    setLoading(true);
    sdk
      .deleteSurvey({ _id: id })
      .then(() => {
        message.success("Амжилттай устгагдлаа");
        getSurveys();
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSurveys();
  }, [getSurveys]);
  return {
    data: data?.getSurveys,
    loading,
    getSurveys,
    deleteSurvey,
  };
};
