import { useEffect } from "react";
import { AddActivityWidget } from "../../../widgets/activity/add";

export const AddActivityPage = () => {
  useEffect(() => {
    document.title = "Мэдэгдэл нэмэх";
  }, []);
  return (
    <div className="page">
      <AddActivityWidget />
    </div>
  );
};
