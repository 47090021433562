import { useEffect } from "react";
import UserAddWidget from "../../../widgets/user/add";
export const AddUserPage = () => {
  useEffect(() => {
    document.title = "Хэрэглэгч нэмэх";
  }, []);
  return (
    <div className="page">
      <UserAddWidget />
    </div>
  );
};
