import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ConfirmOrderListWidget from "../../widgets/orderlist/confirm";
import { PortfolioParamsType } from "../portfolio/detail";

export const ConfirmOrderListPage = () => {
  const { _id } = useParams<PortfolioParamsType>();

  useEffect(() => {
    document.title = "Захиалгын хуудас биелүүлэх";
  }, []);

  return (
    <div className="page">
      <ConfirmOrderListWidget _id={_id} />
    </div>
  );
};
