import { Button, Form, Input, Popconfirm, Space, Switch } from "antd";
import { Link } from "react-router-dom";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useSurvey } from "./detail/useSurvey";
import { useEffect } from "react";
import { useEditSurvey } from "./useEditSurvey";
import { useState } from "react";
import { NullableAnswer, SelectSurvey } from "../../components/SelectSurvey";

export type NullableQuestion = {
  question: string | null;
  localizedQuestion: {
    en?: string | null;
    mn?: string | null;
  };
  answers: (NullableAnswer | null)[];
};

export const AddSurveyWidget = ({ _id }: { _id: string }) => {
  const [selectedQuestion, setSelectedQuestion] =
    useState<NullableQuestion | null>(null);
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const { data: survey, loading: fetchinSurvey } = useSurvey({ _id });
  const { addSurvey, editSurvey, loading, form, questions, setQuestions } =
    useEditSurvey(
      _id === "create"
        ? { selectedSurveyId: undefined }
        : { selectedSurveyId: _id }
    );
  useEffect(() => {
    if (_id !== "create" && survey) {
      form.setFields([
        {
          name: "name_mn",
          value: survey.name,
        },
        {
          name: "name_en",
          value: survey.localizedName?.en,
        },
        {
          name: "introText_mn",
          value: survey.introText,
        },
        {
          name: "introText_en",
          value: survey.localizedIntroText?.en,
        },
        {
          name: "completeText_mn",
          value: survey.completeText,
        },
        {
          name: "completeText_en",
          value: survey.localizedCompleteText?.en,
        },
        {
          name: "active",
          value: survey.active,
        },
      ]);
      setQuestions(
        survey.questions.map((question) => ({
          question: question.question,
          answers: question.answers.map((answer) => ({
            value: answer.value,
            localizedValue: {
              en: answer.localizedValue?.en,
              mn: answer.localizedValue?.mn,
            },
            riskPoints: answer.riskPoints,
            durationPoints: answer.durationPoints,
          })),
          localizedQuestion: {
            en: question.localizedQuestion?.en,
            mn: question.localizedQuestion?.mn,
          },
        })) ?? {
          question: null,
          localizedQuestion: {
            en: null,
            mn: null,
          },
          answers: [
            {
              value: null,
              localizedValue: {
                en: null,
                mn: null,
              },
              durationPoints: null,
              riskPoints: null,
            },
          ],
        }
      );
    }
  }, [_id, form, setQuestions, survey]);

  const addQuestion = () => {
    const question: NullableQuestion = {
      question: null,
      localizedQuestion: {
        en: null,
        mn: null,
      },
      answers: [
        {
          localizedValue: { en: null, mn: null },
          value: null,
          durationPoints: null,
          riskPoints: null,
        },
      ],
    };
    const newQuestions = [...questions, question];
    setQuestions(newQuestions);
  };

  const deleteQuestion = (index: number) => {
    if (questions.length > 1) {
      questions.splice(index, 1);
      setQuestions([...questions]);
    } else {
      updateQuestion({ question: null, index });
    }
  };

  const updateQuestion = ({
    question,
    index,
  }: {
    question: NullableQuestion | null;
    index: number;
  }) => {
    const newQuestions = [...questions];
    newQuestions[index] = question;
    setQuestions(newQuestions);
  };
  return (
    <>
      <div className="page-header">
        <h3>{_id === "create" ? "Судалгаа нэмэх" : "Судалгаа засах"}</h3>
        <Space>
          <Link to="/surveys">
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Буцах
            </Button>
          </Link>
        </Space>
      </div>
      <SelectSurvey
        question={selectedQuestion}
        questionIndex={questionIndex}
        setQuestion={setSelectedQuestion}
        onSubmit={({ question, index }) => updateQuestion({ question, index })}
        loading={_id === "create" ? false : fetchinSurvey}
      />
      <Form
        form={form}
        onFinish={_id === "create" ? addSurvey : editSurvey}
        style={{ marginTop: 20 }}
        layout="vertical"
      >
        <Form.Item label="Нэр (Монгол)" name="name_mn" required>
          <Input />
        </Form.Item>
        <Form.Item label="Нэр (Англи)" name="name_en" required>
          <Input />
        </Form.Item>
        <Form.Item label="Intro text (Монгол)" name="introText_mn" required>
          <Input />
        </Form.Item>
        <Form.Item label="Intro text (Англи)" name="introText_en" required>
          <Input />
        </Form.Item>
        <Form.Item
          label="Complete text (Монгол)"
          name="completeText_mn"
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Complete text (Англи)"
          name="completeText_en"
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Идэвхитэй эсэх"
          name="active"
          valuePropName="checked"
          required
        >
          <Switch checked={survey?.active} />
        </Form.Item>
        <Form.Item label="Асуултууд" required>
          {questions.map((question, index) => (
            <div style={{ width: "100%" }}>
              <Button
                style={{
                  margin: "5px 5px 5px 0",
                  minWidth: "50%",
                  textAlign: "left",
                }}
                onClick={() => {
                  setSelectedQuestion(question);
                  setQuestionIndex(index);
                }}
              >
                {question?.question ?? "хоосон"}
              </Button>
              <Popconfirm
                disabled={loading}
                title="Та устгахдаа итгэлтэй байна уу?"
                okText="Тийм"
                cancelText="Үгүй"
                onConfirm={() => deleteQuestion(index)}
              >
                <Button
                  size="middle"
                  disabled={loading}
                  type="dashed"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </div>
          ))}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            style={{ marginTop: 10 }}
            onClick={addQuestion}
            icon={<PlusOutlined />}
          >
            Асуулт нэмэх
          </Button>
        </Form.Item>
        <Button htmlType="submit" type="primary">
          {_id === "create" ? "Нэмэх" : "Засах"}
        </Button>
      </Form>
    </>
  );
};
