import { message } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import Excel from "exceljs";

import {
  AddBondPurchaseInput,
  AddSavingPurchaseInput,
  OrderListStock,
  AddStockPurchaseInput,
  PurchaseType,
} from "../../../graphql";
import sdk from "../../../sdk";
import { useBonds } from "../../bond/useBonds";
import { useSavings } from "../../saving/useSavings";
import { useGetOrderList } from "../useGetOrderList";
import { BondForm } from "./bonds";
import { SavingForm } from "./savings";
import { useHistory } from "react-router-dom";

const useConfirmOrder = ({ _id }: { _id: string }) => {
  const { data, loading: fetchingOrderList } = useGetOrderList({ _id });
  const { data: bonds, loading: fetchingBonds } = useBonds();
  const { data: savings, loading: fetchingSaving } = useSavings();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [usdRate, setUsdRate] = useState<number>();
  const [bondForm, setBondForm] = useState<BondForm[]>();
  const [savingForm, setSavingForm] = useState<SavingForm[]>();

  useEffect(() => {
    setLoading(fetchingBonds);
    setLoading(fetchingOrderList);
    setLoading(fetchingSaving);
  }, [fetchingOrderList, fetchingBonds, fetchingSaving]);

  const stocks = useMemo(() => {
    if (data?.totalPurchases.stocks && data?.totalPurchases.stocks.length > 0) {
      return data.totalPurchases.stocks.map((totalStock) => {
        return {
          stock: {
            _id: totalStock.stock._id!,
            name: totalStock.stock.name,
          },
        };
      }) as OrderListStock[];
    }
  }, [data]);

  const [uploadStock, setUploadStock] = useState<AddStockPurchaseInput[]>();

  const handleImport = (event: any) => {
    const wb = new Excel.Workbook();
    const reader = new FileReader();
    reader.readAsArrayBuffer(event.target.files[0]);
    reader.onload = () => {
      const buffer = reader.result as ArrayBuffer;
      wb.xlsx.load(buffer).then((workbook) => {
        const obj = [] as AddStockPurchaseInput[];
        workbook.eachSheet((sheet) => {
          sheet.eachRow((row: any, rowIndex: number) => {
            if (rowIndex === 1) return;
            const find = data?.totalPurchases.stocks.find(
              (stock) => stock.stock.symbol === row.values[5].trim()
            );
            if (find) {
              obj.push({
                symbol: row.values[3].trim(),
                quantity: row.values[7],
                unitPrice: row.values[8],
              });
            }
          });
        });
        setUploadStock(obj);
        console.log(obj);
        event = null;
        message.success("Амжилттай хуулагдлаа", 1);
      });
    };
  };

  const submit = useCallback(() => {
    let savingVariables = [] as AddSavingPurchaseInput[];
    let bondVariables = [] as AddBondPurchaseInput[];

    savingForm?.forEach((el) =>
      savingVariables.push({
        saving: el._id!,
        amount: el.amount,
        fee: el.fee,
      })
    );
    bondForm?.forEach((el) =>
      bondVariables.push({
        bond: el._id!,
        amount: el.amount,
        fee: el.fee,
      })
    );

    if (usdRate && savingVariables && bondVariables) {
      let unitPrice = 0;
      const stocks = uploadStock?.map((stock) => {
        switch (stock.symbol) {
          case "CUMN":
            unitPrice = 180;
            break;
          case "TUM":
            unitPrice = 514;
            break;
          case "MNDL":
            unitPrice = 104;
            break;
          case "APU":
            unitPrice = 1619;
            break;
          case "XOC":
            unitPrice = 100000;
            break;
          case "REP":
            unitPrice = 100000;
            break;
          case "FX":
            unitPrice = 1;
            break;
          default:
            unitPrice = stock.unitPrice * usdRate;
            break;
        }
        return {
          symbol: stock.symbol,
          quantity: stock.quantity,
          unitPrice,
        };
      });
      setLoading(true);
      sdk
        .confirmOrderList({
          _id: data?._id!,
          totalPurchases: {
            bonds: bondVariables,
            savings: savingVariables,
            stocks: stocks ?? [],
            type: PurchaseType.Exchange,
            usdRate,
          },
        })
        .then(() => {
          message.success("Амжилттай баталгаажлаа.");

          history.push("/orderlists");
        })
        .catch((err) => message.warn(err.response?.errors[0]?.message))
        .finally(() => setLoading(false));
    } else {
      message.success("Форм дутуу бөглөгдсөн байна.");
    }
  }, [savingForm, bondForm, uploadStock, usdRate, data?._id, history]);

  return {
    data,
    bonds,
    savings,
    stocks,
    loading,
    savingForm,
    usdRate,
    bondForm,
    uploadStock,
    setUsdRate,
    setSavingForm,
    setBondForm,
    submit,
    handleImport,
    setUploadStock,
  };
};

export default useConfirmOrder;
