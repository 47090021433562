import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Popconfirm } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";

import { User } from "@/graphql";
import { ProfileModal } from "@/widgets/profile/ProfileModal";
import { usePersistedState } from "@/hooks/usePersistedState";

export function Header() {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [user] = usePersistedState<User | null>(null, "user");
  return (
    <div className="header">
      <ProfileModal setShow={setShow} show={show} user={user} />
      <div className="header_text">
        <span>
          {user?.lastName} {user?.firstName}
        </span>
        <p>{user?.email}</p>
      </div>

      <div className="icon_button" onClick={() => setShow(true)}>
        <UserOutlined />
      </div>

      <Popconfirm
        title="Та гарахдаа итгэлтэй байна уу?"
        okText="Тийм"
        cancelText="Үгүй"
        onConfirm={() => {
          history.push("/logout");
        }}
      >
        <Button
          style={{ backgroundColor: "#f7f7f7", height: 40, width: 40 }}
          size="middle"
          shape="circle"
          color="#f7f7f7"
          icon={<LogoutOutlined />}
        />
      </Popconfirm>
    </div>
  );
}
