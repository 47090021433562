import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { GetBalancesQuery } from "../../graphql";
import sdk from "../../sdk";

export const useBalances = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetBalancesQuery>();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState<string | null>(null);

  const getBalances = useCallback(() => {
    setLoading(true);
    sdk
      .getBalances({
        page,
        limit,
        advanced: { search },
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [page, limit, search]);

  useEffect(() => {
    getBalances();
  }, [getBalances, page]);
  return {
    limit,
    loading,
    data: data?.getBalances,
    search,
    setPage,
    setSearch,
    setLimit,
    getBalances,
  };
};
