import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { GetStocksQuery } from "../../graphql";
import sdk from "../../sdk";

export const useStocks = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetStocksQuery>();
  const [page, setPage] = useState(0);

  const getStocks = useCallback(() => {
    setLoading(true);
    sdk
      .getStocks({ page, limit: 30 })
      .then((data) => setData(data))
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [page]);

  const deleteStock = (id: string) => {
    setLoading(true);
    sdk
      .deleteStock({ _id: id })
      .then(() => {
        message.success("Амжилттай устгагдлаа");
        getStocks();
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getStocks();
  }, [getStocks, page]);
  return {
    data: data?.getStocks,
    loading,
    getStocks,
    deleteStock,
    setPage,
  };
};
