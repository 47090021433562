import { Button, Form, Modal, Select } from "antd";
import { Dispatch } from "react";
import { useCallback } from "react";
import { GetSavingsQuery, Saving } from "../graphql";
import { SavingForm } from "../widgets/orderlist/confirm/savings";
import { Option } from "../widgets/user/modal/create";

export const ChooseSaving = ({
  show,
  setShow,
  savings,
  submit,
}: {
  show: boolean;
  setShow: Dispatch<boolean>;
  savings: GetSavingsQuery["getSavings"] | undefined;
  submit: (bond: SavingForm) => void;
}) => {
  const [form] = Form.useForm();
  const onSubmit = useCallback(
    (values) => {
      if (savings) {
        const selected = savings?.savings?.find(
          (saving: Omit<Saving, "startDate" | "endDate">) =>
            saving._id === values.saving
        );
        submit({
          _id: selected?._id!,
          accountNo: selected?.accountNo!,
          bankName: selected?.bankName!,
          currency: selected?.currency!,
          balance: selected?.balance!,
          interestRate: selected?.interestRate!,
          amount: 0,
          fee: 0,
          // TODO: Allow to choose duration
          duration: "1y",
        });
      }
      form.resetFields();
      setShow(false);
    },
    [form, savings, setShow, submit]
  );
  return (
    <Modal
      width={500}
      title="Үл хөдлөх хөрөнгө нэмэх"
      visible={show}
      onCancel={() => setShow(false)}
      footer={[
        <Button key="back" onClick={() => setShow(false)}>
          Болих
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.validateFields().then(onSubmit)}
        >
          Нэмэх
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item label="Үл хөдлөх хөрөнгө " name="saving" required>
          <Select>
            {savings?.savings?.map((saving) => (
              <Option key={saving._id!} value={saving._id!}>
                {saving.bankName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
