import { message } from "antd";
import { useState } from "react";
import sdk from "../../../sdk";

export const useEditImage = ({ getImages }: { getImages: () => void }) => {
  const [loading, setLoading] = useState(false);

  const addImage = (image: string) => {
    setLoading(true);
    sdk
      .addImage({ image: { path: image } })
      .then((result) => {
        if (result) {
          getImages();
          message.success("Амжилттай хадгалагдлаа");
        }
      })
      .catch(() => {
        setLoading(false);
        message.warn("Алдаа гарлаа дахин оролдоно уу");
      })
      .finally(() => setLoading(false));
  };
  return {
    loading,
    addImage,
  };
};
