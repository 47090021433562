import { message } from "antd";
import { useCallback, useEffect, useState } from "react";

import { GetSavingsQuery } from "../../graphql";
import sdk from "../../sdk";

export const useSavings = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetSavingsQuery["getSavings"]>();
  const [page, setPage] = useState(0);

  const getSavings = useCallback(() => {
    setLoading(true);
    sdk
      .getSavings({ page, limit: 30 })
      .then((data) => setData(data.getSavings))
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [page]);

  const deleteSaving = (id: string) => {
    setLoading(true);
    sdk
      .deleteSaving({ _id: id })
      .then((result) => {
        if (result) {
          message.success("Амжилттай устгагдлаа");
        }
        getSavings();
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSavings();
  }, [getSavings, page]);

  return {
    data,
    loading,
    setPage,
    getSavings,
    deleteSaving,
  };
};
