import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BalanceHistoryWidget } from "../../../widgets/balance/detail/history";
import { PortfolioParamsType } from "../../portfolio/detail";

export const BalanceHistoryPage = () => {
  const { _id } = useParams<PortfolioParamsType>();
  useEffect(() => {
    document.title = "Дансны түүх";
  }, []);
  return (
    <div className="page">
      <BalanceHistoryWidget _id={_id} />
    </div>
  );
};
