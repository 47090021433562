import { useEffect } from "react";
import { ConfigWidget } from "../widgets/config";

export const ConfigPage = () => {
  useEffect(() => {
    document.title = "Тохиргоо";
  }, []);
  return (
    <div className="page">
      <ConfigWidget />
    </div>
  );
};
