import React from "react";
import { useParams } from "react-router-dom";

import { EditSavingsWidget } from "../../../widgets/saving/edit";
export interface SavingsParams {
  _id: string;
}
export const EditSavingsPage = () => {
  const { _id } = useParams<SavingsParams>();
  return (
    <div className="page">
      <EditSavingsWidget _id={_id} />
    </div>
  );
};
