import { useEffect } from "react";

import logoFullImgUrl from "@/assets/logo-full.png";

import { LoginWidget } from "../widgets/login";

export const LoginPage = () => {
  useEffect(() => {
    document.title = "Нэвтрэх";
  }, []);
  return (
    <div className="login-container">
      <div className="login-container-form">
        <div className="login-container-form-logo">
          <img src={logoFullImgUrl} alt="logo" />
        </div>
        <LoginWidget />
      </div>
    </div>
  );
};
