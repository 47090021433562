import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { message } from "antd";

import sdk, { GraphQLError } from "@/sdk";

export const useBonds = () => {
  const [page, setPage] = useState(0);

  const {
    data,
    isLoading: isLoadingBonds,
    refetch,
  } = useQuery(["bonds", page], () => sdk.getBonds({ page, limit: 30 }), {
    select: (data) => data.getBonds,
  });

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation({
    mutationFn: (id: string) => sdk.deleteBond({ _id: id }),
    onSuccess: () => {
      message.success("Амжилттай устгагдлаа");
      queryClient.invalidateQueries(["bonds"]);
    },
    onError: (err) => {
      message.warn((err as GraphQLError).response?.errors[0]?.message, 2);
    },
  });

  return {
    data,
    loading: isLoadingBonds || isLoading,
    getBonds: refetch,
    deleteBond: mutate,
    setPage,
  };
};
