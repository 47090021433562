import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BalanceDetailWidget } from "../../widgets/balance/detail";
import { PortfolioParamsType } from "../portfolio/detail";

export const BalanceDetailPage = () => {
  const { _id } = useParams<PortfolioParamsType>();
  useEffect(() => {
    document.title = "Дансны дэлгэрэнгүй";
  }, []);
  return (
    <div className="page">
      <BalanceDetailWidget _id={_id} />
    </div>
  );
};
