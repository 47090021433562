import { Col, Divider, Row, Table } from "antd";

import { useEditUser } from "../../modal/useEditUser";

import { NoData } from "../../../../components/NoData";
import { FullLoading } from "../../../../components/FullLoading";

export const PortfolioTab = ({ _id }: { _id: string }) => {
  const { userData, loading } = useEditUser({
    selectedUserId: _id,
  });
  return (
    <>
      <div className="detail-widget-container">
        {loading ? (
          <FullLoading />
        ) : (
          (userData?.chosenPortfolio === null ||
            userData?.chosenPortfolio === undefined) && <NoData />
        )}
        <div className="detail-widget-container-content">
          {userData?.chosenPortfolio && (
            <Row>
              <Col span={6}>
                <p className="gray-text">Багцын нэр :</p>
                <p>{userData?.chosenPortfolio?.localizedName?.mn}</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Багцын тайлбар :</p>
                <p>{userData?.chosenPortfolio?.localizedDescription?.mn}</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Багцын дугаар :</p>
                <p>{userData?.chosenPortfolio?.index}</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Эрсдлийн үнэлгээ :</p>
                <p>{userData?.chosenPortfolio?.riskLevel}-р түвшин</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Хугацаа :</p>
                <p>{userData?.chosenPortfolio?.duration}</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Бондын эзлэх хувь :</p>
                <p>{userData?.chosenPortfolio?.bond.value}%</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Үл хөдлөх хөрөнгийн эзлэх хувь :</p>
                <p>{userData?.chosenPortfolio?.saving.value}%</p>
              </Col>
              <Col span={24}>
                <Divider orientation="left" plain>
                  Үнэт цаасны жагсаалт
                </Divider>
              </Col>
              {userData?.chosenPortfolio?.stocks.length !== 0 ? (
                <Col span={24}>
                  <Table
                    dataSource={userData?.chosenPortfolio?.stocks}
                    loading={loading}
                    size="small"
                    bordered
                    columns={[
                      {
                        title: "Эзлэх хувь",
                        dataIndex: "stock",
                        key: "stock",
                        render: (stock, record) => <span>{record.value}%</span>,
                      },
                      {
                        title: "Үнэт цаас нэр",
                        dataIndex: "stock",
                        key: "stock",
                        render: (stock) => <span>{stock.name}</span>,
                      },
                      {
                        title: "Symbol",
                        dataIndex: "stock",
                        key: "stock",
                        render: (stock) => <span>{stock.symbol}</span>,
                      },
                      {
                        title: "Төрөл",
                        dataIndex: "stock",
                        key: "stock",
                        render: (stock) => <span>{stock.type}</span>,
                      },
                    ]}
                  />
                </Col>
              ) : (
                <NoData />
              )}
            </Row>
          )}
        </div>
      </div>
    </>
  );
};
