import { useEffect } from "react";

import { ActivityWidget } from "../../widgets/activity";

export const ActivityPage = () => {
  useEffect(() => {
    document.title = "Мэдэгдэл";
  }, []);
  return (
    <div className="page">
      <ActivityWidget />
    </div>
  );
};
