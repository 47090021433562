import {
  Button,
  Carousel,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Select,
} from "antd";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";

import BlueDivider from "../../../../components/BlueDivider";
import { FullLoading } from "../../../../components/FullLoading";
import { useEditKYC } from "../../modal/useEditKYC";
import { useGetKYCByUser } from "../../modal/useGetKYCByUser";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/lib/carousel";

import sdk from "@/sdk";
import { Status } from "@/graphql";

import nodataImgUrl from "@/assets/nodata.png";

const Option = Select;

function SensitiveDataField({
  label,
  maskedData,
  queryKey,
  queryFn,
}: {
  label: string;
  maskedData?: string;
  queryKey: string[];
  queryFn: () => Promise<string | null>;
}) {
  const [shouldLoadData, setShouldLoadData] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const {
    data: plaintextData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn,
    enabled: shouldLoadData,
  });
  const currentPlaintextValue = plaintextData ?? maskedData;
  return (
    <div className="readonly-field">
      <span>{label}:</span>
      <span>{isRevealed ? currentPlaintextValue : maskedData}</span>
      <Button
        type="link"
        className="ml-auto"
        loading={isLoading && isFetching}
        onClick={() => {
          if (!shouldLoadData) {
            setShouldLoadData(true);
            setIsRevealed(true);
            return;
          }
          setIsRevealed(!isRevealed);
        }}
      >
        {!isRevealed || isLoading ? "Харах" : "Нуух"}
      </Button>
    </div>
  );
}

export const KycTab = ({ _id }: { _id: string }) => {
  const carouselRef = useRef() as React.RefObject<CarouselRef>;
  const { loading, data, error, form } = useGetKYCByUser(_id ?? null);
  const { loading: editing, editKYC } = useEditKYC({
    selectedKYCId: data?._id ?? null,
  });

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FullLoading />
        </div>
      ) : error ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img width="250" src={nodataImgUrl} alt="no-data" />
          <p>Мэдээлэл олдсонгүй</p>
        </div>
      ) : (
        <>
          <Row gutter={24}>
            <Col span={11}>
              <BlueDivider
                text="Хувийн мэдээлэл"
                styles={{ marginBottom: "32px", marginTop: "25px" }}
              />
              <div className="form-flex-container-bordered">
                <span>Овог :</span>

                <span>{data?.user.lastName}</span>
              </div>
              <div className="form-flex-container-bordered">
                <span>Нэр :</span>
                <span>{data?.user.firstName}</span>
              </div>
              <SensitiveDataField
                label="Регистрийн дугаар"
                maskedData={data?.registerNo}
                queryKey={["registerNo", data?._id?.toString() ?? ""]}
                queryFn={() =>
                  sdk
                    .getUserRegisterNo({ kycId: data?._id ?? "" })
                    .then((data) => data.userRegisterNo)
                }
              />
              <SensitiveDataField
                label="ТТД"
                maskedData={data?.taxPayerNo ?? ""}
                queryKey={["taxPayerNo", data?._id?.toString() ?? ""]}
                queryFn={() =>
                  sdk
                    .getUserTaxPayerNo({ kycId: data?._id ?? "" })
                    .then((data) => data.userTaxPayerNo ?? null)
                }
              />
              <div className="form-flex-container-bordered">
                <span>Төрсөн огноо :</span>
                <span>{moment(data?.birthDate).format("YYYY-MM-DD")}</span>
              </div>
              <SensitiveDataField
                label="Гэрийн хаяг"
                maskedData={data?.address.substring(0, 25) ?? ""}
                queryKey={["address", data?._id?.toString() ?? ""]}
                queryFn={() =>
                  sdk
                    .getUserAddress({ kycId: data?._id ?? "" })
                    .then((data) => data.userAddress)
                }
              />
              <div className="form-flex-container-bordered">
                <span>Утасны дугаар :</span>
                <span>{data?.user.phoneNo}</span>
              </div>
              <div style={{ marginTop: 20 }}>
                <Form
                  form={form}
                  onFinish={editKYC}
                  layout="vertical"
                  autoComplete="off"
                >
                  <Form.Item label="Төлөв өөрчлөх:" name="status">
                    <Select
                      style={{ minWidth: 200, width: "100%" }}
                      disabled={editing}
                    >
                      {Object.values(Status).map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Тайлбар" name="description">
                    <Input />
                  </Form.Item>
                </Form>

                <div />
              </div>
              <Button
                style={{
                  display: data ? "block" : "none",
                  alignSelf: "flex-end",
                }}
                key="submit"
                type="primary"
                disabled={loading}
                onClick={() => form.validateFields().then(editKYC)}
              >
                Хадгалах
              </Button>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }}></Divider>
            </Col>
            <Col span={11}>
              <BlueDivider
                text="Зурагнууд"
                styles={{ marginBottom: "32px", marginTop: "25px" }}
              />
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  left: 0,
                  right: 0,
                }}
              >
                <Carousel dots={false} ref={carouselRef}>
                  {data?.imageUrls?.map((imageUrl) => (
                    <div>
                      <Image
                        height="500px"
                        width="100%"
                        style={{ objectFit: "contain" }}
                        src={imageUrl}
                      />
                    </div>
                  ))}
                </Carousel>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    type="dashed"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => carouselRef?.current?.prev()}
                  />
                  <Button
                    type="dashed"
                    icon={<ArrowRightOutlined />}
                    onClick={() => carouselRef?.current?.next()}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
