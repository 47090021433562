import React, { useEffect } from "react";
import { AddSavingsWidget } from "../../../widgets/saving/add";
export const AddSavingsPage = () => {
  useEffect(() => {
    document.title = "Хадгаламж нэмэх";
  }, []);
  return (
    <div className="page">
      <AddSavingsWidget />
    </div>
  );
};
