import { message } from "antd";
import { startRegistration } from "@simplewebauthn/browser";
import { useCallback, useState } from "react";

import sdk from "@/sdk";

export function useWebauthnRegistration() {
  const [loading, setLoading] = useState(false);
  const initiateRegistration = useCallback(async (username: string) => {
    setLoading(true);
    try {
      const { registerWebauthnDeviceChallenge } =
        await sdk.registerWebauthnDevice({ username });
      const registrationResponse = await startRegistration(
        JSON.parse(registerWebauthnDeviceChallenge)
      );
      const { confirmWebauthnDeviceRegistration } =
        await sdk.confirmWebauthnDeviceRegistration({
          name: username,
          response: JSON.stringify(registrationResponse),
        });
      if (!confirmWebauthnDeviceRegistration) {
        throw new Error("Бүртгэхэд алдаа гарлаа");
      }
      message.success("Амжилттай бүртгэгдлээ");
    } catch (e) {
      if (import.meta.env.DEV) {
        console.error(e);
      }
      message.error("Бүртгэхэд алдаа гарлаа");
    } finally {
      setLoading(false);
    }
  }, []);
  return { loading, initiateRegistration };
}
