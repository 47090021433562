import { Dispatch, useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";

import { User } from "@/graphql";
import { useWebauthnRegistration } from "./useWebauthnRegistration";

export function ProfileModal({
  show,
  setShow,
  user,
}: {
  show: boolean;
  setShow: Dispatch<boolean>;
  user: User | null;
}) {
  const { loading, initiateRegistration } = useWebauthnRegistration();
  const [isWebauthnSupported] = useState(browserSupportsWebAuthn);
  return (
    <Modal
      width={350}
      title="Хэрэглэгчийн мэдээлэл"
      open={show}
      onCancel={() => setShow(false)}
      footer={null}
    >
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <div className="profile_row">
            <div>
              <span>Овог</span>
              <p>{user?.firstName}</p>
            </div>
            <div>
              <span>Нэр</span>
              <p>{user?.lastName}</p>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className="profile_row">
            <div>
              <span>Цахим шуудан</span>
              <p>{user?.email}</p>
            </div>
            <div>
              <span>Утасны дугаар</span>
              <p>{user?.phoneNo}</p>
            </div>
          </div>
        </Col>
        <Col span={24}>
          <div className="profile_row" style={{ border: 0 }}>
            <div>
              <span>Эрх</span>
              <p>{user?.role}</p>
            </div>
            <div />
          </div>
        </Col>
        {isWebauthnSupported && (
          <Col span={24}>
            <Row justify="center">
              <Button
                loading={loading}
                onClick={() => {
                  const username = user?.email ?? user?.phoneNo;
                  if (!username) {
                    return;
                  }
                  initiateRegistration(username);
                }}
              >
                Хялбар нэвтрэлт тохируулах
              </Button>
            </Row>
          </Col>
        )}
      </Row>
    </Modal>
  );
}
