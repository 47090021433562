import { useCallback, useEffect, useState } from "react";
import { GetSurveyQuery } from "../../../graphql";
import sdk from "../../../sdk";

export const useSurvey = ({ _id }: { _id: string }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<GetSurveyQuery | null>(null);
  const getSurvey = useCallback(() => {
    setLoading(true);
    if (_id !== "create") {
      sdk
        .getSurvey({ _id })
        .then((data) => setData(data))
        .catch((err) => {
          setError(err.response?.errors[0]?.message);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  }, [_id]);
  useEffect(() => {
    getSurvey();
  }, [getSurvey]);
  return {
    data: data?.getSurvey,
    loading,
    error,
  };
};
