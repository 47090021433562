import * as zip from "@zip.js/zip.js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Radio, Upload, message } from "antd";

import sdk from "@/sdk";
import { Contract, RequestContractUploadMutationVariables } from "@/graphql";

async function parseImagesFromDocx(file: File) {
  const fileReader = new zip.BlobReader(file);
  const zipReader = new zip.ZipReader(fileReader);
  const entries = await zipReader.getEntries();
  const imageEntries = entries.filter(
    (entry) =>
      entry.filename.startsWith("word/media/") &&
      entry.filename.endsWith(".png")
  );
  const images = await Promise.all(
    imageEntries
      // Typing quirks of the zip.js library
      .filter((image) => !!image.getData)
      .map(async (image) => {
        const name = image.filename.split("/").pop()!;
        const blob = await image.getData!(new zip.BlobWriter());
        return { name, src: URL.createObjectURL(blob) };
      })
  );
  return images;
}

export function ContractModel({
  selectedContract,
  onClose,
}: {
  selectedContract: Partial<Contract> | null;
  onClose: () => void;
}) {
  const [file, setFile] = useState<File | null>(null);
  const [availableImages, setAvailableImages] = useState<
    { name: string; src: string }[]
  >([]);
  const beforeFileUpload = useCallback((file: File) => {
    setFile(file);
    parseImagesFromDocx(file).then(setAvailableImages);
    // Upload file manually
    return false;
  }, []);

  const [name, setName] = useState("");
  const [signatureImageName, setSignatureImageName] = useState("");
  useEffect(() => {
    setName(selectedContract?.name ?? "");
    setSignatureImageName("");
    setFile(null);
  }, [selectedContract]);

  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation({
    mutationFn: async ({
      file,
      ...data
    }: RequestContractUploadMutationVariables & { file: File }) => {
      const uploadUrl = await sdk
        .requestContractUpload(data)
        .then((res) => res.requestContractUpload);
      const response = await fetch(uploadUrl, {
        method: "PUT",
        body: file,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
    onSuccess: () => {
      message.success("Гэрээ амжилттай нэмэгдлээ", 2);
      onClose();
    },
    onError: (e) => message.error(`Гэрээ нэмэхэд алдаа гарлаа: ${e}`, 2),
    onSettled: () => queryClient.invalidateQueries(["contracts"]),
  });
  return (
    <Modal
      title="Гэрээ нэмэх"
      open={!!selectedContract}
      onCancel={onClose}
      confirmLoading={isLoading}
      onOk={() => {
        if (isLoading) {
          return;
        }
        if (file && name && signatureImageName) {
          mutate({ name, signatureImageName, file });
        }
      }}
    >
      <Form.Item label="Нэр" required>
        <Input value={name} onChange={(e) => setName(e.target.value)} />
      </Form.Item>
      <Form.Item label="Файл" required>
        <Upload
          accept=".docx"
          maxCount={1}
          beforeUpload={beforeFileUpload}
          onRemove={() => {
            setAvailableImages([]);
            setFile(null);
          }}
          fileList={file ? [{ uid: "file", name: file.name }] : []}
        >
          <Button icon={<UploadOutlined />}>Сонгох</Button>
        </Upload>
      </Form.Item>
      <Form.Item label="Гарын үсэг сонгох" required key={name}>
        <Radio.Group
          value={signatureImageName}
          onChange={(e) => setSignatureImageName(e.target.value)}
        >
          {availableImages.map(({ name, src }) => (
            <Radio value={name} key={name}>
              <img src={src} style={{ maxWidth: "100%", maxHeight: 64 }} />
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </Modal>
  );
}
