import { message } from "antd";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { SearchContextType, SearchCtx } from "@/context/UserSearchProvider";

import sdk from "@/sdk";

export function useUsers() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);

  const { type, role, search, status, dateRange } =
    useContext<SearchContextType>(SearchCtx);

  const userFilters = {
    page,
    limit,
    advanced: {
      role,
      type,
      status,
      search,
      date: dateRange,
    },
  };
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["users", userFilters],
    queryFn: () => sdk.getUsers(userFilters),
    select: (data) => data.getUsers,
  });

  const deleteUserMutation = useMutation({
    mutationFn: (id: string) => sdk.deleteUser({ _id: id }),
    onSuccess: () => {
      message.success("Амжилттай устгагдлаа.", 2);
      refetch();
    },
    onError: (err: any) => message.warn(err.response?.errors[0]?.message, 2),
  });
  const resetBalanceMutation = useMutation({
    mutationFn: (id: string) => sdk.resetBalance({ userId: id }),
    onSuccess: () => {
      message.success("Данс амжилттай устагдлаа.", 2);
      refetch();
    },
    onError: (err: any) => message.warn(err.response?.errors[0]?.message, 2),
  });
  return {
    page,
    limit,
    loading:
      resetBalanceMutation.isLoading ||
      deleteUserMutation.isLoading ||
      isLoading,
    data,
    setPage,
    getUsers: refetch,
    setLimit,
    deleteUser: deleteUserMutation.mutate,
    resetBalance: resetBalanceMutation.mutate,
  };
}
