import { Link } from "react-router-dom";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Switch, Col, Row, Select } from "antd";

import BlueDivider from "../../../components/BlueDivider";
import { useEditBond } from "../useEditBond";

export const { TextArea } = Input;
const { Option } = Select;

export function BondAddWidget() {
  const { form, loading, addBond } = useEditBond({});
  return (
    <>
      <div className="page-header">
        <h3>Бонд нэмэх</h3>
        <div className="page-header-buttons">
          <Link to="/bonds">
            <Button icon={<ArrowLeftOutlined />} className="back-button">
              Буцах
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-body">
        <Form
          form={form}
          onFinish={addBond}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={11}>
              <BlueDivider
                text="Мэдээлэл"
                styles={{ marginBottom: "30px", marginTop: "10px" }}
              />
              <Form.Item
                label="Нэр (Монгол)"
                name="name_mn"
                rules={[{ required: true, message: "Нэрээ оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Нэр (Англи)"
                name="name_en"
                rules={[{ required: true, message: "Нэрээ оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Symbol"
                name="symbol"
                rules={[{ required: true, message: "Symbol оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Хүү (%)"
                name="interestRate"
                rules={[{ required: true, message: "Хүү (%) Оруулна уу" }]}
              >
                <Input type="number" disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Хугацаа"
                name="duration"
                rules={[{ required: true, message: "Хугацаагаа сонгоно уу" }]}
              >
                <Select disabled={loading} loading={loading}>
                  <Option value="1y">1 жил</Option>
                  <Option value="6m">6 сар</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Идэвхтэй эсэх"
                name="active"
                valuePropName="checked"
                required
              >
                <Switch
                  defaultChecked={false}
                  checkedChildren="Идэвхтэй"
                  unCheckedChildren="Идэвхгүй"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="form-flex-container">
          <div />
          <Button
            type="primary"
            key="submit"
            style={{ marginTop: "30px" }}
            icon={<PlusOutlined />}
            onClick={() => form.validateFields().then(() => form.submit())}
          >
            Бонд нэмэх
          </Button>
        </div>
      </div>
    </>
  );
}
