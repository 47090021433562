import { Dispatch } from "react";
import { Button, Form, Input, Modal } from "antd";

import { useEditFAQ } from "./useEditFAQ";

export const AddFAQWidget = ({
  show,
  getFAQs,
  setShow,
}: {
  show: boolean;
  getFAQs: () => void;
  setShow: Dispatch<boolean>;
}) => {
  const { form, loading, addFaq } = useEditFAQ({
    close: () => setShow(false),
    getFAQs,
  });
  return (
    <Modal
      width={1200}
      title="Асуулт нэмэх"
      open={show}
      onCancel={() => setShow(false)}
      footer={[
        <Button key="back" disabled={loading} onClick={() => setShow(false)}>
          Болих
        </Button>,
        <Button
          key="submit"
          disabled={loading}
          type="primary"
          onClick={() => form.validateFields().then(addFaq)}
        >
          Нэмэх
        </Button>,
      ]}
    >
      <Form form={form} onFinish={addFaq} layout="vertical" autoComplete="off">
        <Form.Item label="Асуулт" name="question" required>
          <Input.TextArea disabled={loading} />
        </Form.Item>
        <Form.Item label="Хариулт" name="answer" required>
          <Input.TextArea disabled={loading} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
