import { useKYCs } from "./useKYCs";
import { Space, Button, Table, Popconfirm, Tag } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

export const KYCWidget = () => {
  const { loading, data, page, pageSize, setPage, setPageSize } = useKYCs();

  return (
    <>
      <div className="page-header">
        <h3>KYC жагсаалт</h3>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data?.kycs ?? []}
            size="small"
            loading={loading}
            pagination={{
              pageSize,
              total: Number(data?.totalPages) * pageSize,
            }}
            onChange={(pagination) => {
              if ((pagination?.current ?? 0) - 1 !== page) {
                setPage(Number(pagination?.current) - 1);
              }
              if (pagination?.pageSize !== pageSize) {
                setPage(0);
                setPageSize(pagination?.pageSize ?? 30);
              }
            }}
            bordered
            columns={[
              {
                title: "Утасны дугаар",
                dataIndex: "phoneNo",
                key: "phoneNo",
                render: (_, record) => (
                  <span className="text_ellipse">
                    +976 {record.user.phoneNo}
                  </span>
                ),
              },
              {
                title: "Цахим хаяг",
                dataIndex: "email",
                key: "email",
                render: (_, record) => <span>{record.user.email}</span>,
              },
              {
                title: "РД",
                dataIndex: "registerNo",
                key: "registerNo",
                render: (text) => <span>{text}</span>,
              },
              {
                title: "Төлөв",
                dataIndex: "status",
                key: "status",
                render: (_, record) => <Tag>{record.info.status}</Tag>,
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "90px",
                render: (_, record) => {
                  return (
                    <Space>
                      <Link to={"/kycs/" + record.user._id}>
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          icon={<EditOutlined />}
                        />
                      </Link>
                      <Popconfirm
                        disabled={loading}
                        title="Та устгахдаа итгэлтэй байна уу?"
                        okText="Тийм"
                        cancelText="Үгүй"
                      >
                        <Button
                          size="middle"
                          shape="circle"
                          disabled={loading}
                          danger
                          type="primary"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
