import { useEffect } from "react";
import { ImageWidget } from "../widgets/image";

export const ImagePage = () => {
  useEffect(() => {
    document.title = "Зурагнууд";
  }, []);
  return (
    <div className="page">
      <ImageWidget />
    </div>
  );
};
