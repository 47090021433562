import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Table } from "antd";

import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import sdk from "@/sdk";

export function UserDeletionRequestWidget() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);

  const queryClient = useQueryClient();
  const { mutate: deleteUser, isLoading: isMutating } = useMutation({
    mutationFn: (id: string) => sdk.deleteUser({ _id: id }),
    onSuccess: () => {
      message.success("Амжилттай устгагдлаа.", 2);
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: (err: any) => message.warn(err.response?.errors[0]?.message, 2),
  });
  const { data, isLoading } = useQuery({
    queryKey: ["user-deletion-requests", { page, limit }],
    queryFn: () => sdk.getUserDeletionRequests({ page, limit }),
    select: (data) => data.getUserDeletionRequests,
  });
  return (
    <>
      <div className="page-header">
        <h3>Хэрэглэгч устгах хүсэлтүүд</h3>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data?.requests ?? []}
            size="small"
            loading={isLoading}
            pagination={{
              pageSize: limit,
              total: Number(data?.totalPages) * limit,
              showSizeChanger: true,
              onChange: (page, pageSize) => {
                setPage(page - 1);
                setLimit(pageSize);
              },
            }}
            bordered
            columns={[
              {
                title: "Утасны дугаар",
                dataIndex: "user",
                key: "user",
                render: (user) => <span>{user.phoneNo}</span>,
              },
              {
                title: "Овог",
                dataIndex: "user",
                key: "user",
                render: (user) => <span>{user.lastName}</span>,
              },
              {
                title: "Нэр",
                dataIndex: "user",
                key: "user",
                render: (user) => <span>{user.firstName}</span>,
              },
              {
                title: "Цахим хаяг",
                dataIndex: "user",
                key: "user",
                render: (user) => <span>{user.email}</span>,
              },
              {
                title: "Хүсэлтийн огноо",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (createdAt) => (
                  <span>{moment(createdAt).format("YYYY/MM/DD HH:mm:ss")}</span>
                ),
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                width: 45,
                render: (_, record) => (
                  <Popconfirm
                    disabled={isMutating}
                    title="Та устгахдаа итгэлтэй байна уу?"
                    okText="Тийм"
                    cancelText="Үгүй"
                    onConfirm={() => {
                      if (record.user._id) {
                        deleteUser(record.user._id);
                      }
                    }}
                  >
                    <Button
                      size="middle"
                      shape="circle"
                      disabled={isMutating}
                      danger
                      type="primary"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
}
