import { useEffect } from "react";
import { SurveyWidget } from "../../widgets/survey";

export const SurveyPage = () => {
  useEffect(() => {
    document.title = "Судалгаа";
  }, []);
  return (
    <div className="page">
      <SurveyWidget />
    </div>
  );
};
