import { Button, Modal } from "antd";
import { Dispatch } from "react";
import { PrepareOrderListResult, StockType } from "../../../graphql";
import { formatInput } from "../../../utils/formatters";

export const PrepareOrderListModal = ({
  data,
  setData,
}: {
  data: PrepareOrderListResult | null;
  setData: Dispatch<PrepareOrderListResult | null>;
}) => {
  return (
    <Modal
      width={500}
      title="Захиалгын хуудсын задаргаа"
      visible={!!data}
      onCancel={() => setData(null)}
      footer={[
        <Button key="back" onClick={() => setData(null)}>
          Хаах
        </Button>,
      ]}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <p>Бонд</p>
        <p>₮{formatInput({ balance: data?.bond ?? 0 })}</p>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <p>Үл хөдлөх хөрөнгө</p>
        <p>₮{formatInput({ balance: data?.savings ?? 0 })}</p>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <p>Гадаад хувьцаа</p>
        <p>
          ₮
          {formatInput({
            balance:
              data?.stocks
                .filter(
                  (filteredStock) =>
                    filteredStock.type === StockType.International
                )
                .map((stock) => stock.totalPrice)
                .reduce((c, n) => c + n, 0) ?? 0,
          })}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <p>Дотоод хувьцаа</p>
        <p>
          ₮
          {formatInput({
            balance:
              data?.stocks
                .filter(
                  (filteredStock) => filteredStock.type === StockType.Domestic
                )
                .map((stock) => stock.totalPrice)
                .reduce((c, n) => c + n, 0) ?? 0,
          })}
        </p>
      </div>
    </Modal>
  );
};
