import { useState, useEffect, Dispatch, SetStateAction } from "react";

export function usePersistedState<T>(
  defaultValue: T,
  key: string
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    if (typeof localStorage === "undefined") return defaultValue;

    const persistedValue: T = JSON.parse(localStorage.getItem(key) ?? "null");
    return persistedValue ?? defaultValue;
  });

  useEffect(() => {
    const handleChange = (event: StorageEvent) => {
      if (event.key !== key) return;
      if (event.oldValue === event.newValue) return;
      setValue(JSON.parse(event.newValue ?? "null"));
    };

    window.addEventListener("storage", handleChange, { passive: true });
    localStorage.setItem(key, JSON.stringify(value));
    return () => window.removeEventListener("storage", handleChange);
  }, [key, value]);

  return [value, setValue];
}
