import { useEffect } from "react";
import { StockWidget } from "../../widgets/stock";

export const StockPage = () => {
  useEffect(() => {
    document.title = "Үнэт цаас";
  }, []);
  return (
    <div className="page">
      <StockWidget />
    </div>
  );
};
