import moment from "moment";
import { Button, Table } from "antd";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import sdk from "@/sdk";
import { Contract } from "@/graphql";

import { ContractModel } from "./ContractModal";

export function ContractPage() {
  const { data, isLoading } = useQuery({
    queryKey: ["contracts"],
    queryFn: () => sdk.getContracts(),
    select: (data) => data.contracts,
  });

  const [selectedContract, setSelectedContract] =
    useState<Partial<Contract> | null>(null);
  return (
    <main className="page">
      <ContractModel
        selectedContract={selectedContract}
        onClose={() => setSelectedContract(null)}
      />
      <div className="page-header">
        <h3>Гэрээ</h3>
        <Button type="primary" onClick={() => setSelectedContract({})}>
          Гэрээ нэмэх
        </Button>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table loading={isLoading} dataSource={data}>
            <Table.Column title="Нэр" dataIndex="name" />
            <Table.Column
              title="Үүсгэсэн огноо"
              dataIndex="createdAt"
              render={(value) => moment(value).format("YYYY.MM.DD HH:mm")}
            />
            <Table.Column
              title="Шинэчлэгдсэн огноо"
              dataIndex="updatedAt"
              render={(value) => moment(value).format("YYYY.MM.DD HH:mm")}
            />
          </Table>
        </div>
      </div>
    </main>
  );
}
