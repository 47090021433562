import { Button, Table } from "antd";
import moment from "moment";
import { formatBalance } from "../../../../utils/formatters";
import { useGetBalance } from "../useGetBalance";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

export const BalanceStockHistoryWidget = ({
  _id,
  index,
}: {
  _id: string;
  index: string;
}) => {
  const { data, loading } = useGetBalance({ _id });
  const Stockindex = parseInt(index);
  const history = useHistory();

  return (
    <>
      <div className="page-header">
        <h3>Үнэт цаас дэлгэрэнгүй түүх</h3>
        <div className="page-header-buttons">
          <Button
            icon={<ArrowLeftOutlined />}
            className="back-button"
            onClick={() => history.goBack()}
          >
            Буцах
          </Button>
        </div>
      </div>
      <div className="page-body">
        <Table
          bordered
          size="small"
          loading={loading}
          dataSource={
            data?.stocks?.[Stockindex]?.history?.sort(
              (a, b) => b.createdAt - a.createdAt
            ) ?? []
          }
          columns={[
            {
              title: "Огноо",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (text) => (
                <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span>
              ),
            },
            {
              title: "Тоо ширхэг",
              dataIndex: "totalAmount",
              key: "totalAmount",
              render: (text) => <span>{text.toFixed(6)}</span>,
            },

            {
              title: "Нийт үлдэгдэл",
              dataIndex: "totalPrice",
              key: "totalPrice",
              render: (text) => (
                <span>{formatBalance({ balance: text })} ₮</span>
              ),
            },

            {
              title: "Нэгж үнэ",
              dataIndex: "unitPrice",
              key: "unitPrice",
              render: (text) => (
                <span>{formatBalance({ balance: text })} ₮</span>
              ),
            },

            {
              title: "Шимтгэл",
              dataIndex: "fee",
              key: "fee",
              render: (text) => (
                <span>{formatBalance({ balance: text })} ₮</span>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};
