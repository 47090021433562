import { Link } from "react-router-dom";
import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";

import { Currency } from "../../../graphql";
import { Option } from "../../portfolio/create";
import { useEditSaving } from "../useEditSaving";
import BlueDivider from "../../../components/BlueDivider";

export const EditSavingsWidget = ({ _id }: { _id: string }) => {
  const { form, loading, editSaving } = useEditSaving({
    selectedSavingId: _id ?? null,
  });
  return (
    <>
      <div className="page-header">
        <h3>Үл хөдлөх хөрөнгө засах</h3>
        <div className="page-header-buttons">
          <Link to="/savings">
            <Button icon={<ArrowLeftOutlined />} className="back-button">
              Буцах
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-body">
        <Form
          form={form}
          onFinish={editSaving}
          style={{ marginTop: 20 }}
          layout="vertical"
        >
          <BlueDivider
            text="Мэдээлэл"
            styles={{ marginBottom: "30px", marginTop: "10px" }}
          />
          <Row gutter={24}>
            <Col span={11}>
              <Form.Item
                label="Банкны нэр"
                name="bankName"
                rules={[{ required: true, message: "Банкны нэр оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Дансны дугаар"
                name="accountNo"
                rules={[
                  { required: true, message: "Дансны дугаар оруулна уу" },
                ]}
              >
                <Input type="number" disabled={loading} />
              </Form.Item>

              <Form.Item
                style={{ width: "50%" }}
                label="Хугацаа"
                name="duration"
                rules={[{ required: true, message: "Хугацаагаа сонгоно уу" }]}
              >
                <Select disabled={loading} loading={loading}>
                  <Option value="1y">1 жил</Option>
                  <Option value="6m">6 сар</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={1} style={{ display: "flex", justifyContent: "center" }}>
              <Divider type="vertical" style={{ height: "100%" }}></Divider>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Жилийн хүү (%)"
                name="interestRate"
                rules={[{ required: true, message: "Жилийн хүү оруулна уу" }]}
              >
                <Input type="number" disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Вальют"
                name="currency"
                rules={[{ required: true, message: "Вальют оруулна уу" }]}
              >
                <Select disabled={loading} loading={loading}>
                  {Object.values(Currency)?.map((currency) => (
                    <Option key={currency} value={currency}>
                      {currency}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="form-flex-container">
          <div />
          <Button
            icon={<PlusOutlined />}
            key="submit"
            disabled={loading}
            type="primary"
            onClick={() => form.validateFields().then(editSaving)}
          >
            Үл хөдлөх хөрөнгө засах
          </Button>
        </div>
      </div>
    </>
  );
};
