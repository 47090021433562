import moment from "moment";
import invariant from "ts-invariant";
import { useMemo } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Table } from "antd";

import nodataImgUrl from "@/assets/nodata.png";

import { FullLoading } from "@/components/FullLoading";
import { formatBalance } from "@/utils/formatters";
import { useSearchParam } from "@/utils/url-state";
import { OrderType, PurchaseDataFragment } from "@/graphql";

import { HistoryModal } from "../../../balance/detail/historyModal";
import { useTabBalanceHistory } from "./useTabBalanceHistory";

export const BalanceHistoryTab = ({ _id }: { _id: string }) => {
  const { loading, data, error } = useTabBalanceHistory({ _id });
  const [purchaseId, { set, unset }] = useSearchParam("purchase");
  const activePurchase = useMemo<PurchaseDataFragment | null>(() => {
    if (!purchaseId) {
      return null;
    }
    return (
      data?.balanceHistory.find((purchase) => purchase._id === purchaseId) ??
      null
    );
  }, [purchaseId, data]);
  return (
    <>
      {error ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img width="250" src={nodataImgUrl} alt="no-data" />
          <p>Мэдээлэл олдсонгүй</p>
        </div>
      ) : (
        <>
          <HistoryModal data={activePurchase} onClose={unset} />
          <div className="page-body">
            <Row justify="center">
              {loading ? (
                <FullLoading />
              ) : (
                <>
                  <Col span={6}>
                    <span className="question_title">Нийт хөрөнгийн дүн</span>
                    <p>
                      {formatBalance({ balance: data?.netAssetValue ?? 0 })} ₮
                    </p>
                  </Col>
                  {typeof data?.totalDeposit === "number" && (
                    <Col span={6}>
                      <span className="question_title">
                        Нийт цэнэглэсэн дүн
                      </span>
                      <p>{formatBalance({ balance: data.totalDeposit })} ₮</p>
                    </Col>
                  )}
                  <Col span={6}>
                    <span className="question_title">Нийт бэлэн мөнгө</span>
                    <p>{formatBalance({ balance: data?.cashAmount ?? 0 })} ₮</p>
                  </Col>
                  <Col span={6}>
                    <span className="question_title">Битүүмжлэгдсэн дүн</span>
                    <p>{formatBalance({ balance: data?.holdAmount ?? 0 })} ₮</p>
                  </Col>
                  <Col span={24}>
                    <Divider orientation="left">Захиалгын түүх</Divider>
                  </Col>
                  <Col span={24}>
                    <Table
                      pagination={false}
                      dataSource={data?.orderHistory?.reverse() ?? []}
                      loading={loading}
                      size="small"
                      bordered
                      columns={[
                        {
                          title: "Өмнөх үлдэгдэл",
                          dataIndex: "beforeBalance",
                          key: "beforeBalance",
                          render: (text) => (
                            <span>{formatBalance({ balance: text })} ₮</span>
                          ),
                        },
                        {
                          title: "Захиалгын дүн",
                          dataIndex: "amount",
                          key: "amount",
                          render: (text, record) => (
                            <span
                              style={{
                                color:
                                  record.type === OrderType.Income
                                    ? "green"
                                    : "red",
                              }}
                            >
                              {record.type === OrderType.Income ? "+" : "-"}{" "}
                              {formatBalance({ balance: text })} ₮
                            </span>
                          ),
                        },
                        {
                          title: "Дараах үлдэгдэл",
                          dataIndex: "afterBalance",
                          key: "afterBalance",
                          render: (text) => (
                            <span>{formatBalance({ balance: text })} ₮</span>
                          ),
                        },
                        {
                          title: "Огноо",
                          dataIndex: "createdAt",
                          key: "createdAt",
                          render: (text) => (
                            <span>
                              {moment(text).format("YYYY/MM/DD HH:mm:ss")}
                            </span>
                          ),
                        },
                        {
                          title: "Төлөв",
                          dataIndex: "status",
                          key: "status",
                        },
                      ]}
                    />
                  </Col>
                  <Col span={24}>
                    <Divider orientation="left">Худалдан авалтын түүх</Divider>
                  </Col>
                  <Col span={24}>
                    <Table
                      pagination={false}
                      dataSource={data?.balanceHistory ?? []}
                      loading={loading}
                      size="small"
                      bordered
                      columns={[
                        {
                          title: "Худалдан авалтын дүн",
                          dataIndex: "title",
                          key: "title",
                        },
                        {
                          title: "Огноо",
                          dataIndex: "createdAt",
                          key: "createdAt",
                          render: (text) => (
                            <span>
                              {moment(text).format("YYYY/MM/DD HH:mm:ss")}
                            </span>
                          ),
                        },
                        {
                          title: "Хөрөнгө оруулсан дүн",
                          dataIndex: "principleAmount",
                          key: "principleAmount",
                          render: (text) => (
                            <span>{formatBalance({ balance: text })} ₮</span>
                          ),
                        },
                        {
                          title: "Шимтгэл",
                          dataIndex: "fee",
                          key: "fee",
                          render: (text) => (
                            <span>{formatBalance({ balance: text })} ₮</span>
                          ),
                        },
                        {
                          width: 50,
                          dataIndex: "button",
                          key: "button",
                          render: (_, record) => (
                            <Button
                              type="primary"
                              shape="circle"
                              onClick={() => {
                                invariant(
                                  record._id,
                                  "record._id must be defined"
                                );
                                set(record._id);
                              }}
                            >
                              <EyeOutlined />
                            </Button>
                          ),
                        },
                      ]}
                    />
                  </Col>
                </>
              )}
            </Row>
          </div>
        </>
      )}
    </>
  );
};
