import { message } from "antd";
import { useCallback, useState } from "react";
import sdk from "../../../../sdk";

export const useRecoverOtp = ({ username }: { username: string }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const recoverOtp = useCallback(() => {
    setLoading(true);
    sdk
      .recoverOtp({ username })
      .then((data) => {
        if (data.recoverOtp) {
          message.success("Амжилттай сэргээгдлээ.");
        }
        setLoading(false);
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [username]);

  return { loading, recoverOtp };
};
