import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Dispatch, useState } from "react";
import { Image } from "../graphql";
import { Images } from "./SelectImage";
import { DeleteFilled } from "@ant-design/icons";
export const ImagePicker = ({
  image,
  handler,
  setImage,
}: {
  image: Image | null;
  handler: (image: Image) => void;
  setImage: Dispatch<Image | null>;
}) => {
  const [show, setShow] = useState(false);
  const closeHandler = (image: Image) => {
    setShow(false);
    handler(image);
  };
  return (
    <div className="image-picker">
      <Images show={show} setShow={setShow} imageHandler={closeHandler} />
      {image ? (
        <div
          className="image-picker-img"
          style={{
            backgroundImage: `url(${
              import.meta.env.PROD
                ? `/images/${image.path}`
                : `https://avtamata.com/images/${image.path}`
            })`,
          }}
        >
          <DeleteFilled
            onClick={() => setImage(null)}
            style={{
              fontSize: "30px",
              color: "#C70000",
              position: "absolute",
              top: 5,
              right: 5,
              cursor: "pointer",
            }}
          />
        </div>
      ) : (
        <div className="image-picker-no-img">No image</div>
      )}
      <div className="image-picker-buttons">
        <Button
          type="primary"
          className="image-picker-btn"
          onClick={() => setShow(true)}
          icon={<UploadOutlined />}
        >
          Зураг сонгох
        </Button>
      </div>
    </div>
  );
};
