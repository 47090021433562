import { useState } from "react";
import { Button, Modal, Pagination, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { Image } from "../graphql";
import { AddImageWidget } from "../widgets/image/modal";
import { useImages } from "../widgets/image/useImages";

export const Images = ({
  show,
  setShow,
  imageHandler,
}: {
  show: boolean;
  setShow: (value: boolean) => void;
  imageHandler: (image: Image) => void;
}) => {
  const [open, setOpen] = useState(false);
  const { data, loading, setPage, page, getImages } = useImages();
  return (
    <Modal
      width={900}
      visible={!!show}
      onCancel={() => setShow(false)}
      footer={false}
    >
      <AddImageWidget show={open} setShow={setOpen} getImages={getImages} />
      {loading && <Spin />}
      <div className="selectimage">
        <div className="selectimage-upload">
          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={() => setOpen(true)}
          >
            Зураг хуулах
          </Button>
        </div>
        <div className="selectimage-images">
          {data?.images?.map((img) => {
            return (
              <div
                key={img._id}
                className="image"
                onClick={() => imageHandler(img)}
              >
                <img
                  height="200px"
                  width="100%"
                  style={{ objectFit: "contain" }}
                  src={
                    import.meta.env.PROD
                      ? `/images/${img.path}`
                      : `http://avtamata.com/images/${img.path}`
                  }
                  alt={img._id!}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Pagination
        style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}
        current={page + 1}
        defaultCurrent={0}
        total={Number(data?.totalPages! * 20)}
        defaultPageSize={20}
        onChange={(value) => setPage(value - 1)}
      />
    </Modal>
  );
};
