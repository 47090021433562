import { Button, message, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import { useEditImage } from "./useEditImage";

export const AddImageWidget = ({
  show,
  setShow,
  getImages,
}: {
  show: boolean;
  setShow: (value: boolean) => void;
  getImages: () => void;
}) => {
  const [image, setImage] = useState<File | null>(null);
  const { addImage } = useEditImage({ getImages });

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  const imageUploadHandler = (e: any) => {
    if (!image) {
      e.preventDefault();
      message.warn("Зураг оруулна уу");
      return;
    }
    e.preventDefault();
    let data = new FormData();
    data.append("file", image!);
    fetch(
      import.meta.env.PROD
        ? "/upload/image"
        : "http://localhost:8811/upload/image",
      {
        method: "POST",
        body: data,
        headers: {
          Accept: "*/*",
        },
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          message.warn("1mb-аас бага хэмжээтэй зураг хуулна уу! ");
        }
      })
      .then((image) => {
        if (image) {
          addImage(image.fileName);
        }
      })
      .finally(() => {
        setImage(null);
        setShow(false);
        ref.current.value = "";
      });
  };

  return (
    <Modal
      width={500}
      title="Хуулах зурагаа сонгоно уу"
      visible={show}
      onCancel={() => {
        setShow(false);
        ref.current.value = "";
      }}
      footer={false}
    >
      <form
        encType="multipart/form-data"
        style={{ padding: 0, border: 0 }}
        onSubmit={imageUploadHandler}
      >
        <div
          className="file"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label className="file-label">
            <input
              ref={ref}
              multiple={true}
              className="file-input"
              type="file"
              name="files"
              onChange={(e) => {
                if (e.target.files) {
                  setImage(e.target.files[0]);
                }
              }}
            />
          </label>
          <Button
            style={{ marginTop: 20 }}
            htmlType="submit"
            type="primary"
            icon={<UploadOutlined />}
          >
            Хуулах
          </Button>
        </div>
      </form>
    </Modal>
  );
};
