import { message } from "antd";
import { useCallback, useEffect, useState } from "react";

import sdk from "../../sdk";
import { GetPortfolioQuery } from "../../graphql";

export const usePortfolio = ({ _id }: { _id?: string }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetPortfolioQuery | null>(null);
  const getPortfolio = useCallback(() => {
    if (_id && _id !== "create") {
      setLoading(true);
      sdk
        .getPortfolio({ _id })
        .then((data) => setData(data))
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  }, [_id]);

  useEffect(() => {
    getPortfolio();
  }, [getPortfolio, _id]);

  return {
    data: data?.getPortfolio,
    loading,
  };
};
