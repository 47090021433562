import { Dispatch } from "react";
import { Button, Form, Input, Modal, Select } from "antd";

import { ImagePicker } from "../../../components/ImagePicker";
import { Image, StockType } from "../../../graphql";
import { Option } from "../../user/modal/create";
import { useEditStock } from "../edit/useEditStock";

export const AddStockWidget = ({
  show,
  getStocks,
  setShow,
}: {
  show: boolean;
  getStocks: () => void;
  setShow: Dispatch<boolean>;
}) => {
  const { form, loading, image, addStock, setImage } = useEditStock({});
  const closeModalHandler = () => {
    setShow(false);
    setImage(null);
  };
  return (
    <Modal
      width={700}
      title="Үнэт цаас нэмэх"
      visible={show}
      onCancel={closeModalHandler}
      footer={[
        <Button key="back" disabled={loading} onClick={closeModalHandler}>
          Болих
        </Button>,
        <Button
          key="submit"
          disabled={loading}
          type="primary"
          onClick={() => form.validateFields().then(addStock)}
        >
          Нэмэх
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={addStock}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Нэр"
          name="name"
          rules={[{ required: true, message: "Нэр оруулна уу" }]}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Symbol"
          name="symbol"
          rules={[{ required: true, message: "Symbol оруулна уу" }]}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Төрөл"
          name="type"
          rules={[{ required: true, message: "Төрөл оруулна уу" }]}
        >
          <Select disabled={loading} loading={loading}>
            {Object.values(StockType).map((stockType) => (
              <Option key={stockType} value={stockType}>
                {stockType}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <ImagePicker
          setImage={setImage}
          image={image}
          handler={(value: Image) => setImage({ path: value.path })}
        />
      </Form>
    </Modal>
  );
};
