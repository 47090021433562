import { useCallback, useEffect, useState } from "react";
import { GetPortfolioQuery } from "../../../graphql";
import sdk from "../../../sdk";

export const useGetPortfolio = ({ _id }: { _id: string }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<GetPortfolioQuery | null>(null);
  const getPortfolio = useCallback(() => {
    setLoading(true);
    sdk
      .getPortfolio({ _id })
      .then((data) => setData(data))
      .catch((err) => {
        setError(err.response?.errors[0]?.message);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [_id]);
  useEffect(() => {
    getPortfolio();
  }, [getPortfolio]);
  return {
    data: data?.getPortfolio,
    loading,
    error,
  };
};
