import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { FullLoading } from "../components/FullLoading";

export function LogoutPage() {
  const history = useHistory();
  const queryClient = useQueryClient();
  useEffect(() => {
    queryClient.cancelQueries();
    queryClient.clear();
    localStorage.clear();
    history.push("/login");
  }, [history]);
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FullLoading />
    </div>
  );
}
