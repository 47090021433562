import { useMemo, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  Button,
  Col,
  message,
  Row,
  Space,
  Statistic,
  Table,
  Tooltip,
} from "antd";
import {
  DoubleRightOutlined,
  RightOutlined,
  CloseOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import sdk from "@/sdk";
import { WithdrawalRequest, WithdrawalStatus } from "@/graphql";
import { RefundDetailModal } from "./RefundDetailModal";
import { RefundUserDetailModal } from "./RefundUserDetailModal";

export function RefundCreateWidget() {
  const [show, setShow] = useState(false);
  const [userShow, setUserShow] = useState<WithdrawalRequest | null>(null);

  const [selectedUser, setSelectedUser] = useState<WithdrawalRequest[]>([]);
  const [page, setPage] = useState(0);
  const history = useHistory();
  const { data, isLoading } = useQuery(
    ["getAllWithdrawalRequest", page],
    async () =>
      sdk.allWithdrawalRequests({
        limit: 30,
        page: 0,
        status: WithdrawalStatus.Pending,
      }),
    {
      select: (data) => data.allWithdrawalRequests,
    }
  );

  const requestIds = useMemo(() => {
    return selectedUser.map((value) => value._id);
  }, [selectedUser]);

  const { isLoading: mutationLoading, mutate } = useMutation({
    mutationKey: ["createWithdrawalOrderList"],
    mutationFn: async () =>
      sdk.createWithdrawalOrderList({ withdrawalRequestIds: requestIds }),
    onSuccess: () => {
      message.success("Амжилттай", 2);
      history.push("/refund");
    },
    onError: (err) => message.warn((err as Error).message, 2),
  });

  const UsersCount = useMemo<Number>(() => {
    const users = new Set<string>();

    data?.requests.forEach((item) => users.add(item._id));

    return users.size;
  }, [data]);

  const handleCreateWithdraw = () => {
    if (selectedUser.length === 0) {
      message.warn("Хэрэглэгч сонгоно уу", 2);
      return;
    }
    mutate();
  };
  console.log(data);
  const handlePreview = () => {
    setShow(true);
  };

  return (
    <div>
      <RefundDetailModal
        requestIds={requestIds}
        setShow={setShow}
        show={show}
      />
      <RefundUserDetailModal setUser={setUserShow} user={userShow} />
      <div className="page-header">
        <h3>Буцаалтын хуудас үүсгэх</h3>
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            loading={mutationLoading}
            onClick={handleCreateWithdraw}
          >
            Буцаалтын хуудас үүсгэх
          </Button>
          <Link to="/refund">
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Буцах
            </Button>
          </Link>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Row>
            <Col span={6}>
              <Statistic title="Хэрэглэгчдийн тоо" value={`${UsersCount}`} />
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "30px",
              justifyContent: "space-between",
              gap: 16,
            }}
          >
            <Col style={{ flex: 1 }}>
              <Table
                title={() => <Button type="text">Боломжит хэрэглэгчид</Button>}
                size="small"
                bordered
                loading={isLoading}
                dataSource={data?.requests ?? []}
                rowKey={(row) => row._id}
                pagination={{
                  pageSize: 30,
                  total: data?.totalItems,
                }}
                onChange={(pagination) =>
                  setPage(Number(pagination?.current) - 1)
                }
                columns={[
                  {
                    title: "Багцын дугаар",
                    dataIndex: "chosenPortfolio",
                    key: "chosenPortfolio",
                    render: (_, record) => <span>{3}</span>,
                  },
                  {
                    title: "Утасны дугаар эсвэл и-мейл хаяг",
                    dataIndex: "user",
                    key: "user",
                    render: (_, record) => (
                      <span>{record.user.email ?? record.user.phoneNo}</span>
                    ),
                  },
                  {
                    title: () => (
                      <Tooltip title="Бүгдийг сонгох">
                        <Button
                          type="primary"
                          disabled={
                            mutationLoading ||
                            data?.requests.length === selectedUser.length
                          }
                          icon={<DoubleRightOutlined />}
                          onClick={() =>
                            setSelectedUser(
                              data?.requests as WithdrawalRequest[]
                            )
                          }
                        />
                      </Tooltip>
                    ),
                    dataIndex: "button",
                    key: "button",
                    fixed: "right",
                    width: "45px",
                    render: (_, record) => {
                      return (
                        <Space>
                          <Button
                            type="primary"
                            disabled={
                              mutationLoading ||
                              selectedUser.some(
                                (value) => value._id === record._id
                              )
                            }
                            icon={<RightOutlined />}
                            onClick={() =>
                              setSelectedUser((current) => [
                                ...current,
                                record as WithdrawalRequest,
                              ])
                            }
                          />
                          <Button
                            disabled={mutationLoading}
                            icon={<EyeOutlined />}
                            onClick={() =>
                              setUserShow(record as WithdrawalRequest)
                            }
                          />
                        </Space>
                      );
                    },
                  },
                ]}
              />
            </Col>
            <Col style={{ flex: 1 }}>
              <Table
                dataSource={selectedUser}
                title={() => (
                  <div>
                    <Button type="text">Сонгосон хэрэглэгчид</Button>
                    <Button
                      type="dashed"
                      icon={<EyeOutlined />}
                      onClick={handlePreview}
                    >
                      Задаргаа харах
                    </Button>
                  </div>
                )}
                size="small"
                bordered
                columns={[
                  {
                    title: "Багцын дугаар",
                    dataIndex: "chosenPortfolio",
                    key: "chosenPortfolio",
                    render: (_, record) => <span></span>,
                  },
                  {
                    title: "Утасны дугаар эсвэл и-мейл хаяг",
                    dataIndex: "user",
                    key: "user",
                    render: (_, record) => (
                      <span>{record.user.email ?? record.user.phoneNo}</span>
                    ),
                  },
                  {
                    title: () => (
                      <Tooltip title="Бүгдийг арилгах">
                        <Button
                          type="primary"
                          danger
                          disabled={
                            mutationLoading || selectedUser.length === 0
                          }
                          icon={<DeleteOutlined />}
                          onClick={() => setSelectedUser([])}
                        />
                      </Tooltip>
                    ),
                    dataIndex: "button",
                    key: "button",
                    fixed: "right",
                    width: "45px",
                    render: (_, record) => {
                      return (
                        <Space>
                          <Button
                            type="primary"
                            danger
                            disabled={mutationLoading}
                            icon={<CloseOutlined />}
                            onClick={() => {
                              setSelectedUser((current) =>
                                current.filter(
                                  (value) => value._id !== record._id
                                )
                              );
                            }}
                          />
                        </Space>
                      );
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
