import { useCallback, useEffect, useState } from "react";
import { Form, message } from "antd";
import { useHistory, useParams } from "react-router-dom";

import { Faq } from "../../../graphql";
import sdk from "../../../sdk";

const useEditFaq = () => {
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [faq, setFaq] = useState<Faq>();

  useEffect(() => {
    setLoading(true);
    sdk
      .getFaq({ _id: id })
      .then((res) => setFaq(res.getFaq))
      .catch((err) => message.warn(err.response?.errors[0]?.message, 2))
      .finally(() => setLoading(false));
  }, [id]);

  const editFaq = useCallback(
    (values: Faq) => {
      setLoading(true);
      sdk
        .editFaq({
          _id: faq?._id ?? "",
          faq: { question: values.question, answer: values.answer },
        })
        .then(() => {
          message.success("Амжилттай засагдлаа", 2);
          setLoading(false);
          history.push("/faqs");
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [faq, history]
  );
  return { editFaq, form, loading, data: faq };
};

export default useEditFaq;
