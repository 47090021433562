import { ChangeEvent, Dispatch, useState } from "react";

import { InputNumber, message, Row } from "antd";
import {
  ArrowRightOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  WithdrawalOrderListStockTotals,
  WithdrawalRequestBond,
  WithdrawalRequestStock,
} from "@/graphql";
import { WithdrawStockData } from "../RefundConfirmWidget";

type WithdrawConfirmProps = {
  stocks?: WithdrawalOrderListStockTotals[];
  handleImport: (file: any) => void;
  setStockData: Dispatch<WithdrawStockData[]>;
  dollar: number;
  setDollar: Dispatch<number>;
};
export function WithdrawConfirm({
  stocks,
  dollar,
  setDollar,
  handleImport,
  setStockData,
}: WithdrawConfirmProps) {
  const [fileName, setFileName] = useState("");
  const uploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleImport(e);
      setFileName(e.target.files[0].name);
      e.target.value = "";
    }
  };

  return (
    <div style={{ padding: "24px 0 0 0" }}>
      <Row gutter={[48, 24]}>
        <div className="excel-file-container">
          <label
            style={{
              display: "flex",
              marginBottom: 20,
              alignItems: "center",
              justifyContent: "space-between",
              width: 300,
            }}
          >
            Долларын ханш
            <InputNumber
              style={{ width: 150 }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              value={dollar}
              onChange={(value) => setDollar(value ?? 0)}
            />
          </label>
          <label className="excel-file-label" htmlFor="file">
            <UploadOutlined style={{ fontSize: 30, color: "#1990ff" }} />
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <p>Худалдан авалтын файл оруулна уу.</p>
              <p style={{ color: "#b4b4b4" }}>
                Худалдан авалт хийсний дараах хувьцааны мэдээллийг файлаар
                оруулна уу.
              </p>
            </div>
          </label>
          <input
            style={{ display: "none" }}
            id="file"
            name="file"
            type="file"
            multiple={false}
            onChange={uploadHandler}
          />
          {fileName && (
            <div
              style={{
                borderBottom: "1px solid gray",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "20%",
                marginTop: 10,
              }}
            >
              <p className="excel-file-name">{fileName}</p>
              <DeleteOutlined
                className="excel-delete-button"
                onClick={() => {
                  setFileName("");
                  setStockData([]);
                  message.warn("Файл устгагдлаа", 1);
                }}
              />
            </div>
          )}
          <label style={{ display: "flex", marginTop: 20 }}>
            <p>Буцаалтын хуудсын худалдан авах хувьцааны жагсаалт:</p>
            <div style={{ marginLeft: 10 }}>
              {stocks === undefined || stocks.length === 0 ? (
                <p style={{ fontWeight: "bold" }}>Хувьцаа байхгүй байна</p>
              ) : (
                stocks.map((stock, index) => (
                  <p key={index} style={{ fontWeight: "bold" }}>
                    {stock.stock.name}
                  </p>
                ))
              )}
            </div>
          </label>
        </div>
      </Row>
    </div>
  );
}
