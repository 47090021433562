import { useEffect } from "react";
import { BondAddWidget } from "../../../widgets/bond/add";
export const BondAddPage = () => {
  useEffect(() => {
    document.title = "Бонд нэмэх";
  }, []);
  return (
    <div className="page">
      <BondAddWidget />
    </div>
  );
};
