import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { RefundDetailWidget } from "@/widgets/refund/RefundDetailWidget";

export function RefundDetail() {
  const { _id } = useParams<{ _id: string }>();

  useEffect(() => {
    document.title = "Буцаалтын дэлгэрэнгүй";
  }, []);

  return (
    <div className="page">
      <RefundDetailWidget id={_id} />
    </div>
  );
}
