import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { PortfolioDetailWidget } from "../../widgets/portfolio/detail";

export type PortfolioParamsType = {
  _id: string;
};

export const PortfolioDetailPage = () => {
  const { _id } = useParams<PortfolioParamsType>();
  useEffect(() => {
    document.title = "Багцын дэлгэрэнгүй";
  }, []);
  return (
    <div className="page">
      <PortfolioDetailWidget _id={_id} />
    </div>
  );
};
