import { WithdrawalOrderListBondTotals } from "@/graphql";
import { formatAmount } from "@/utils/formatters";

type WithdrawalBondProps = {
  bond?: WithdrawalOrderListBondTotals[];
};

export function WithdrawBond({ bond }: WithdrawalBondProps) {
  return (
    <div style={{ paddingTop: 30 }}>
      <p style={{ fontWeight: "bold" }}>Нийт буцаасан бондууд:</p>
      {bond === undefined || bond.length === 0 ? (
        <p>Буцаасан бонд байхгүй байна</p>
      ) : (
        <div style={{ width: 400 }}>
          {bond.map((value, index) => (
            <div key={index} style={{ width: 400, display: "flex" }}>
              <div style={{ width: 200, fontSize: 16, fontWeight: "bold" }}>
                {value.asset.name}
              </div>
              <div style={{ width: 200, fontSize: 16, fontWeight: "bold" }}>
                {formatAmount({ amount: value.totalAmount })}₮
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
