import { useEffect } from "react";
import { PortfolioWidget } from "../../widgets/portfolio";

export const PortfolioPage = () => {
  useEffect(() => {
    document.title = "Багцын задаргаа";
  }, []);
  return (
    <div className="page">
      <PortfolioWidget />
    </div>
  );
};
