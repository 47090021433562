import { Button, Col, InputNumber, Row } from "antd";
import { Dispatch, useState } from "react";
import {
  ArrowRightOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { OrderListStock, AddStockPurchaseInput } from "../../../graphql";

const OrderConfirmStock = ({
  stocks,
  setUsdRate,
  next,
  usdRate,
  handleImport,
  setUploadStock,
}: {
  stocks: OrderListStock[];
  setUsdRate: Dispatch<number>;
  next: () => void;
  usdRate: number;
  handleImport: (file: any) => void;
  setUploadStock: Dispatch<AddStockPurchaseInput[] | undefined>;
}) => {
  const [fileName, setFileName] = useState<string | null>(null);

  const uploadHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName("");
    if (e.target.files) {
      handleImport(e);
      setFileName(e.target.files[0].name);
      e.target.value = "";
    }
  };

  return (
    <div style={{ padding: "24px 0 0 0" }}>
      <Row gutter={[48, 24]}>
        <div className="excel-file-container">
          <label
            style={{
              display: "flex",
              marginBottom: 20,
              alignItems: "center",
              justifyContent: "space-between",
              width: 300,
            }}
          >
            Долларын ханш
            <InputNumber
              style={{ width: 150 }}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              value={usdRate}
              onChange={(value) => setUsdRate(value ?? 0)}
            />
          </label>
          <label className="excel-file-label" htmlFor="file">
            <UploadOutlined style={{ fontSize: 30, color: "#1990ff" }} />
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <p>Худалдан авалтын файл оруулна уу.</p>
              <p style={{ color: "#b4b4b4" }}>
                Худалдан авалт хийсний дараах хувьцааны мэдээллийг файлаар
                оруулна уу.
              </p>
            </div>
          </label>
          <input
            style={{ display: "none" }}
            id="file"
            name="file"
            type="file"
            multiple={false}
            onChange={uploadHandler}
          />
          {fileName && (
            <div
              style={{
                borderBottom: "1px solid gray",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "20%",
                marginTop: 10,
              }}
            >
              <p className="excel-file-name">{fileName}</p>
              <DeleteOutlined
                className="excel-delete-button"
                onClick={() => {
                  setFileName(null);
                  setUploadStock(undefined);
                }}
              />
            </div>
          )}
          <label style={{ display: "flex", marginTop: 20 }}>
            <p>Захиалгын хуудсын худалдан авах хувьцааны жагсаалт:</p>
            <div style={{ marginLeft: 10 }}>
              {stocks.map((stock) => (
                <p style={{ fontWeight: "bold" }}>{stock.stock.name}</p>
              ))}
            </div>
          </label>
        </div>
        <Col span={24} style={{ textAlign: "right" }}>
          <Button type="primary" onClick={next}>
            Үргэлжлүүлэх
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default OrderConfirmStock;
