import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BalanceStockHistoryWidget } from "../../../widgets/balance/detail/detailHistory/stock";

export interface BalanceHistoryParams {
  _id: string;
  index: string;
}

export const BalanceDetailsStockHistoryPage = () => {
  const { _id, index } = useParams<BalanceHistoryParams>();
  useEffect(() => {
    document.title = "Үнэт цаас дэлгэрэнгүй түүх";
  }, []);
  return (
    <div className="page">
      <BalanceStockHistoryWidget _id={_id} index={index} />
    </div>
  );
};
