import { Button, Spin, Steps } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useCallback, useMemo, useState } from "react";
import OrderConfirmStock from "./stocks";
import useConfirmOrder from "./useConfirm";
import OrderConfirmBonds from "./bonds";
import OrderConfirmSavings from "./savings";
import OrderConfirmEnd from "./confirm";

const tabsMenu = [
  {
    title: "Үнэт цаас",
    index: 0,
  },
  {
    title: "Бонд",
    index: 1,
  },
  {
    title: "Үл хөдлөх хөрөнгө",
    index: 2,
  },
  {
    title: "Баталгаажуулах",
    index: 3,
  },
];

const { Step } = Steps;

const ConfirmOrderListWidget = ({ _id }: { _id: string }) => {
  const {
    data,
    bonds,
    savings,
    stocks,
    loading,
    savingForm,
    bondForm,
    usdRate,
    uploadStock,
    setUsdRate,
    setSavingForm,
    setBondForm,
    submit,
    handleImport,
    setUploadStock,
  } = useConfirmOrder({
    _id,
  });

  const [currentStep, setCurrentStep] = useState(0);

  const next = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const back = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const steps = [
    <OrderConfirmStock
      stocks={stocks ?? []}
      setUsdRate={setUsdRate!}
      usdRate={usdRate ?? 0}
      next={next}
      handleImport={handleImport}
      setUploadStock={setUploadStock}
    />,
    <OrderConfirmBonds
      data={data}
      bondsData={bonds}
      bondForm={bondForm!}
      next={next}
      back={back}
      setBondForm={setBondForm}
    />,
    <OrderConfirmSavings
      next={next}
      back={back}
      data={data}
      savingForm={savingForm!}
      savingsData={savings}
      setSavingForm={setSavingForm}
    />,
    <OrderConfirmEnd
      usdRate={usdRate}
      uploadStock={uploadStock}
      bondForm={bondForm ?? []}
      savingForm={savingForm ?? []}
      submit={submit}
      back={back}
    />,
  ];

  return (
    <>
      <div className="page-header">
        <h3>Захиалга биелүүлэх</h3>
        <Link to="/orderlists">
          <Button type="dashed" icon={<ArrowLeftOutlined />}>
            Буцах
          </Button>
        </Link>
      </div>
      {loading && (
        <div style={{ textAlign: "center", padding: "24px" }}>
          <Spin />
        </div>
      )}
      <Steps style={{ marginTop: 20 }} current={currentStep}>
        {tabsMenu.map((menu) => (
          <Step key={menu.index} title={menu.title} />
        ))}
      </Steps>

      {steps[currentStep]}
    </>
  );
};
export default ConfirmOrderListWidget;
