import { useEffect } from "react";
import { BondWidget } from "../../widgets/bond";

export const BondPage = () => {
  useEffect(() => {
    document.title = "Бонд";
  }, []);
  return (
    <div className="page">
      <BondWidget />
    </div>
  );
};
