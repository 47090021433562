import { Form, message } from "antd";
import { useCallback, useState } from "react";
import { AddActivityInput } from "../../../graphql";
import sdk from "../../../sdk";

const useAddActivity = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const submit = useCallback(
    (values: AddActivityInput) => {
      setLoading(true);
      sdk
        .addActivity({
          activity: {
            ...values,
            createdAt: Date.now(),
          },
        })
        .then((data) => {
          if (data.addActivity) {
            message.success("Амжилттай", 1);
          }
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [form]
  );

  return {
    form,
    loading,
    submit,
  };
};

export default useAddActivity;
