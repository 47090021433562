import { message } from "antd";
import { useCallback, useState } from "react";
import sdk from "../../sdk";

export const useOrderListDetail = (_id: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string | null>(null);

  const generateOrderListDetail = useCallback(() => {
    setLoading(true);
    if (_id) {
      sdk
        .generateOrderListDetail({ _id })
        .then((res) => {
          setData(res.generateOrderListDetail);
          setLoading(false);
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  }, [_id]);

  return {
    data,
    loading,
    generateOrderListDetail,
  };
};
