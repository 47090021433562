import { Button, Col, Divider, Row } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { FullLoading } from "../../../components/FullLoading";
import Question from "../../../components/Question";

import { useSurvey } from "./useSurvey";

export const SurveyDetailWidget = ({ _id }: { _id: string }) => {
  const { loading, data, error } = useSurvey({ _id });
  return (
    <>
      <div className="page-header">
        <h3>{data?.name}</h3>
        <Link to="/surveys">
          <Button type="dashed" icon={<ArrowLeftOutlined />}>
            Буцах
          </Button>
        </Link>
      </div>
      <div className="page-body">
        <div className="detail-widget-container">
          {loading ? (
            <FullLoading />
          ) : (
            <Row gutter={[12, 12]}>
              <Col span={6}>
                <span className="question_title">Нэр: </span>
                {data?.name}
              </Col>
              <Col span={9}>
                <span className="question_title">Тайлбар: </span>
                {data?.introText}
              </Col>
              <Col span={9}>
                <span className="question_title">Сүүлийн тайлбар: </span>
                {data?.completeText}
              </Col>
              <Col span={24}>
                <Divider orientation="left" plain>
                  Асуултууд
                </Divider>
              </Col>
              {data?.questions?.map((item, index) => (
                <Col span={12}>
                  <Question
                    title={`${index + 1}. ${item.question}`}
                    answers={item.answers}
                  />
                </Col>
              ))}
            </Row>
          )}
        </div>
      </div>
      {error}
    </>
  );
};
