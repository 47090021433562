import { Form, message } from "antd";
import { useCallback, useState } from "react";
import sdk from "../../../sdk";

export const useEditFAQ = ({
  close,
  getFAQs,
}: {
  close: () => void;
  getFAQs: () => void;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const addFaq = useCallback(
    (values) => {
      setLoading(true);
      sdk
        .addFaq({ faq: { question: values.question, answer: values.answer } })
        .then(() => {
          close();
          message.success("Амжилттай нэмэгдлээ", 2);
          form.resetFields();
          setLoading(false);
          getFAQs();
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [form, getFAQs, close]
  );

  return {
    form,
    loading,
    addFaq,
  };
};
