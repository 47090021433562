import moment from "moment";
import {
  Button,
  Carousel,
  Col,
  Divider,
  Form,
  Image,
  Input,
  Row,
  Select,
} from "antd";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { CarouselRef } from "antd/lib/carousel";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

import nodataImgUrl from "@/assets/nodata.png";

import { FullLoading } from "../../../components/FullLoading";
import { Status } from "../../../graphql";
import { useGetKYCByUser } from "../../user/modal/useGetKYCByUser";
import { useEditKYC } from "../../user/modal/useEditKYC";
import BlueDivider from "../../../components/BlueDivider";
import { KYCRejectOptions } from "../../../utils/kycRejectOptions";

const Option = Select;

const KycEditWidget = ({ _id }: { _id: string }) => {
  const history = useHistory();
  const carouselRef = useRef() as React.RefObject<CarouselRef>;

  const { loading, data, error, form, status, setStatus } = useGetKYCByUser(
    _id ?? null
  );
  const { loading: editing, editKYC } = useEditKYC({
    selectedKYCId: data?._id ?? null,
  });

  return (
    <>
      <div className="page-header">
        <h3>Хэрэглэгчийн баталгаажуулалт</h3>
        <div className="page-header-buttons">
          <Button
            icon={<ArrowLeftOutlined />}
            className="back-button"
            onClick={() => history.goBack()}
          >
            Буцах
          </Button>
        </div>
      </div>

      {loading && <FullLoading />}
      {error ? (
        <>
          <img width="250" src={nodataImgUrl} alt="no-data" />
          <p>Мэдээлэл олдсонгүй</p>
        </>
      ) : (
        <div className="page-body">
          <Row gutter={24}>
            <Col span={11}>
              <BlueDivider
                text="Хувийн мэдээлэл"
                styles={{ marginBottom: "32px" }}
              />
              <div className="form-flex-container-bordered">
                <span>Овог :</span>

                <span>{data?.user.lastName}</span>
              </div>
              <div className="form-flex-container-bordered">
                <span>Нэр :</span>
                <span>{data?.user.firstName}</span>
              </div>
              <div className="form-flex-container-bordered">
                <span>Регистрийн дугаар :</span>

                <span>{data?.registerNo}</span>
              </div>
              <div className="form-flex-container-bordered">
                <span>Төрсөн огноо :</span>
                <span>{moment(data?.birthDate).format("YYYY-MM-DD")}</span>
              </div>
              <div className="form-flex-container-bordered">
                <span>Гэрийн хаяг :</span>
                <span>{data?.address}</span>
              </div>
              <div className="form-flex-container-bordered">
                <span>Утасны дугаар :</span>
                <span>+976 {data?.user.phoneNo}</span>
              </div>
              <div style={{ marginTop: 20 }}>
                <Form
                  form={form}
                  onFinish={editKYC}
                  layout="vertical"
                  autoComplete="off"
                >
                  <Form.Item label="Төлөв өөрчлөх:" name="status">
                    <Select
                      style={{ minWidth: 200, width: "100%" }}
                      disabled={editing}
                      onChange={(value) => setStatus(value)}
                    >
                      {Object.values(Status).map((item) => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Тайлбар" name="description">
                    {status === Status.Rejected ? (
                      <Select>
                        {KYCRejectOptions.map(({ key, description }) => (
                          <Option key={key} value={description}>
                            {description}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input />
                    )}
                  </Form.Item>
                </Form>

                <div />
              </div>
              <Button
                style={{
                  display: data ? "block" : "none",
                  alignSelf: "flex-end",
                }}
                key="submit"
                type="primary"
                disabled={loading}
                onClick={() => form.validateFields().then(editKYC)}
              >
                Хадгалах
              </Button>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }}></Divider>
            </Col>
            <Col span={11}>
              <BlueDivider text="Зурагнууд" styles={{ marginBottom: "32px" }} />
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  left: 0,
                  right: 0,
                }}
              >
                <Carousel dots={false} ref={carouselRef}>
                  {data?.imageUrls?.map((imageUrl) => (
                    <div>
                      <Image
                        height="500px"
                        width="100%"
                        style={{ objectFit: "contain" }}
                        src={imageUrl}
                      />
                    </div>
                  ))}
                </Carousel>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    type="dashed"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => carouselRef?.current?.prev()}
                  />
                  <Button
                    type="dashed"
                    icon={<ArrowRightOutlined />}
                    onClick={() => carouselRef?.current?.next()}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default KycEditWidget;
