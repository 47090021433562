import { Tabs } from "antd";
import { useEffect } from "react";

import { RefundWidget } from "@/widgets/refund/RefundWidget";
import { WithdrawalStatus } from "@/graphql";

export const RefundPage = () => {
  useEffect(() => {
    document.title = "Буцаалт";
  }, []);

  return (
    <div className="page">
      <Tabs
        items={[
          {
            key: WithdrawalStatus.Processing,
            label: "Хүлээгдэж буй",
            children: <RefundWidget status={WithdrawalStatus.Processing} />,
          },
          {
            key: WithdrawalStatus.TransferPending,
            label: "Шилжүүлэг хийж буй",
            children: (
              <RefundWidget status={WithdrawalStatus.TransferPending} />
            ),
          },
          {
            key: WithdrawalStatus.Done,
            label: "Баталгаажсан",
            children: <RefundWidget status={WithdrawalStatus.Done} />,
          },
          {
            key: WithdrawalStatus.Canceled,
            label: "Цуцлагдсан",
            children: <RefundWidget status={WithdrawalStatus.Canceled} />,
          },
        ]}
      />
    </div>
  );
};
