import { Form, Input, Modal, message } from "antd";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";

import sdk, { GraphQLError } from "@/sdk";
import { useHistory } from "react-router-dom";

export type PendingDividendState = {
  stockId: string;
  recordDate: string;
  dividend: number;
};
export function DistributeDividendsModal({
  pendingDividend,
  onClose,
}: {
  pendingDividend: PendingDividendState | null;
  onClose: () => void;
}) {
  const [expectedTotalDividend, setExpectedTotalDividend] = useState("");

  const history = useHistory();
  const { mutate, isLoading } = useMutation({
    mutationFn: async (
      data: PendingDividendState & { expectedTotalDividend: number }
    ) => {
      const {
        distributeDividends: { reportUrl },
      } = await sdk.distributeDividends(data);
      window.open(reportUrl);
    },
    onError: (err) => {
      message.warn((err as GraphQLError).response?.errors[0]?.message, 2);
    },
    onSuccess: () => {
      message.success("Ноогдол ашиг амжилттай тараагдлаа", 2);
      history.replace("/stocks/dividends");
    },
    onSettled: onClose,
  });
  return (
    <Modal
      title="Ноогдол ашиг тараах"
      open={!!pendingDividend}
      confirmLoading={isLoading}
      onOk={() => {
        if (!Number.isFinite(parseFloat(expectedTotalDividend))) {
          return;
        }
        return mutate({
          ...pendingDividend!,
          expectedTotalDividend: parseFloat(expectedTotalDividend),
        });
      }}
      onCancel={onClose}
    >
      <Form.Item label="Нийт дүн">
        <Input
          type="number"
          value={expectedTotalDividend}
          onChange={(e) => setExpectedTotalDividend(e.target.value)}
        />
      </Form.Item>
    </Modal>
  );
}
