import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useEditUser } from "../modal/useEditUser";
import { Role, UserType } from "../../../graphql";
import { Link } from "react-router-dom";
import BlueDivider from "../../../components/BlueDivider";

const UserAddWidget = () => {
  const { form, loading, addUser } = useEditUser({});
  const Option = Select;
  return (
    <>
      <div className="page-header">
        <h3>Хэрэглэгч нэмэх</h3>
        <div className="page-header-buttons">
          <Link to="/users">
            <Button icon={<ArrowLeftOutlined />} className="back-button">
              Буцах
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-body">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={addUser}
        >
          <Row>
            <Col span={24}>
              <BlueDivider
                text={"Хувийн мэдээлэл"}
                styles={{ marginBottom: "25px" }}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12}>
              <Form.Item label="Овог" name="lastName">
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Нэр" name="firstName">
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Цахим шуудан"
                name="email"
                rules={[{ required: true, message: "Цахим шуудан оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Утасны дугаар"
                name="phoneNo"
                rules={[
                  { required: true, message: "Утасны дугаар оруулна уу" },
                ]}
              >
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="role"
                label="Хэрэглэгчийн эрх"
                rules={[
                  { required: true, message: "Хэрэглэгчийн эрхээ оруулна уу" },
                ]}
              >
                <Select disabled={loading} style={{ width: "100%" }}>
                  {Object.values(Role).map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Төрсөн огноо" name="birthDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="type"
                label="Хэрэглэгчийн төрөл"
                rules={[
                  {
                    required: true,
                    message: "Хэрэглэгчийн төрөлөө оруулна уу",
                  },
                ]}
              >
                <Select disabled={loading} style={{ width: "100%" }}>
                  {Object.values(UserType).map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <BlueDivider
                text={"Дансны мэдээлэл"}
                styles={{ marginBottom: "32px" }}
              />

              <Form.Item label="Банкны нэр" name="bankName">
                <Input disabled={loading} />
              </Form.Item>

              {/* <Form.Item
                label="БД Код"
                name="bdCode"
                rules={[{ required: true, message: "БД Код оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item>

              <Form.Item
                label="Дансны нэр"
                name="accountName"
                rules={[{ required: true, message: "Дансны нэрээ оруулна уу" }]}
              >
                <Input disabled={loading} />
              </Form.Item> */}

              <Form.Item label="Дансны дугаар" name="accountNo">
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <BlueDivider text={"Нууц үг"} styles={{ marginBottom: "32px" }} />

              <Form.Item
                label="Нууц үг"
                name="password"
                rules={[{ required: true, message: "Нууц үгээ оруулна уу" }]}
              >
                <Input.Password disabled={loading} />
              </Form.Item>

              <Form.Item
                label="Нууц үг давтах"
                name="verifyPassword"
                rules={[
                  { required: true, message: "Давтах нууц үгээ оруулна уу" },
                ]}
              >
                <Input.Password disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="primary"
                  key="submit"
                  icon={<UserOutlined />}
                  onClick={() => form.validateFields().then(addUser)}
                >
                  Хэрэглэгч нэмэх
                </Button>
              </Col>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default UserAddWidget;
