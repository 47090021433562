import { useState } from "react";
import { Button, Popconfirm, Space, Table, Tag } from "antd";
import { DeleteOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";

import { useStocks } from "./useStocks";
import { Stock } from "../../graphql";
import { AddStockWidget } from "./modal/create";
import { EditStockWidget } from "./modal";
import { Link } from "react-router-dom";

export const StockWidget = () => {
  const [show, setShow] = useState(false);
  const [stock, setStock] = useState<Stock | null>(null);
  const { loading, data, setPage, getStocks, deleteStock } = useStocks();
  return (
    <>
      <AddStockWidget show={show} setShow={setShow} getStocks={getStocks} />
      <EditStockWidget
        stock={stock}
        setStock={setStock}
        getStocks={getStocks}
      />
      <div className="page-header">
        <h3>Үнэт цаас жагсаалт</h3>
        <Space>
          <Link to={"/stocks/add"}>
            <Button type="primary" icon={<PlusOutlined />}>
              Үнэт цаас нэмэх
            </Button>
          </Link>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data?.stocks ?? []}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 20,
              total: Number(data?.totalPages) * 20,
            }}
            onChange={(pagination) => setPage(Number(pagination?.current) - 1)}
            bordered
            columns={[
              {
                title: "Нэр",
                dataIndex: "name",
                key: "name",
                render: (text) => <span>{text}</span>,
              },
              {
                title: "Symbol",
                dataIndex: "symbol",
                key: "symbol",
                render: (text) => (
                  <span>
                    <Tag>{text}</Tag>
                  </span>
                ),
              },
              {
                title: "Төрөл",
                dataIndex: "type",
                key: "type",
                render: (text) => (
                  <span>
                    {text === "INTERNATIONAL" ? (
                      <Tag>Гадаад хувьцаа</Tag>
                    ) : (
                      <Tag>Дотоод хувьцаа</Tag>
                    )}
                  </span>
                ),
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "90px",
                render: (_, record) => {
                  return (
                    <Space>
                      <Link to={"/stocks/edit/" + record._id}>
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          icon={<EditOutlined />}
                        />
                      </Link>
                      <Popconfirm
                        disabled={loading}
                        title="Та устгахдаа итгэлтэй байна уу?"
                        okText="Тийм"
                        cancelText="Үгүй"
                        onConfirm={() => {
                          if (record._id) {
                            deleteStock(record._id);
                          }
                        }}
                      >
                        <Button
                          size="middle"
                          shape="circle"
                          disabled={loading}
                          danger
                          type="primary"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
