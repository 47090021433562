import { Button, Col, Divider, Row, Space, Table } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

import nodataImgUrl from "@/assets/nodata.png";

import { FullLoading } from "../../../components/FullLoading";
import { useGetPortfolio } from "./useGetPortfolio";

const NoData = () => (
  <>
    <img width="250" src={nodataImgUrl} alt="no-data" />
    <p>Мэдээлэл олдсонгүй</p>
  </>
);

export const PortfolioDetailWidget = ({ _id }: { _id: string }) => {
  const { data, loading, error } = useGetPortfolio({ _id });
  return (
    <>
      <div className="page-header">
        <h3>
          {data?.localizedName?.mn} - {data?.index}
        </h3>
        <Space>
          <Link to="/portfolios">
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Буцах
            </Button>
          </Link>
        </Space>
      </div>
      <div className="detail-widget-container">
        {loading && <FullLoading />}
        {error && <NoData />}
        <div className="detail-widget-container-content">
          {data && (
            <Row>
              <Col span={6}>
                <p className="gray-text">Багцын нэр :</p>
                <p>{data?.localizedName?.mn}</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Багцын тайлбар :</p>
                <p>{data?.localizedDescription?.mn}</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Багцын дугаар :</p>
                <p>{data?.index}</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Эрсдлийн үнэлгээ :</p>
                <p>{data?.riskLevel}-р түвшин</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Хугацаа :</p>
                <p>{data?.duration}</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Бондын эзлэх хувь :</p>
                <p>{data?.bond.value}%</p>
              </Col>
              <Col span={6}>
                <p className="gray-text">Үл хөдлөх хөрөнгийн эзлэх хувь :</p>
                <p>{data?.saving.value}%</p>
              </Col>
              <Col span={24}>
                <Divider orientation="left" plain>
                  Үнэт цаасны жагсаалт
                </Divider>
              </Col>
              {data?.stocks.length !== 0 ? (
                <Col span={24}>
                  <Table
                    dataSource={data?.stocks}
                    loading={loading}
                    size="small"
                    bordered
                    columns={[
                      {
                        title: "Эзлэх хувь",
                        dataIndex: "stock",
                        key: "stock",
                        render: (stock, record) => <span>{record.value}%</span>,
                      },
                      {
                        title: "Үнэт цаас нэр",
                        dataIndex: "stock",
                        key: "stock",
                        render: (stock) => <span>{stock.name}</span>,
                      },
                      {
                        title: "Symbol",
                        dataIndex: "stock",
                        key: "stock",
                        render: (stock) => <span>{stock.symbol}</span>,
                      },
                      {
                        title: "Төрөл",
                        dataIndex: "stock",
                        key: "stock",
                        render: (stock) => <span>{stock.type}</span>,
                      },
                    ]}
                  />
                </Col>
              ) : (
                <NoData />
              )}
            </Row>
          )}
        </div>
      </div>
    </>
  );
};
