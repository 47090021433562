import { Menu } from "antd";
import { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";

import { path } from "@/utils/format";
import { categories } from "@/utils/constants";
import { getUserData } from "@/utils/auth";

import logoFullImgUrl from "@/assets/logo-full.png";

const { SubMenu } = Menu;

export const Sidebar = () => {
  const history = useHistory();
  const selectedMenu = () => {
    return categories
      .filter(
        (category) => category.path === history.location.pathname.toString()
      )[0]
      ?.key.toString();
  };
  return (
    <Menu
      style={{ width: 300, height: "100vh", position: "sticky", top: 0 }}
      selectedKeys={[selectedMenu()]}
      mode="inline"
    >
      <div
        style={{
          padding: "12px 24px",
        }}
      >
        <img
          style={{ cursor: "pointer" }}
          width="100%"
          src={logoFullImgUrl}
          alt="logo"
          onClick={() => history.push("/")}
        />
      </div>
      {getUserData()?.role &&
        categories
          .filter((category) => category.role?.includes(getUserData()!.role))
          .map((category) => {
            return (
              <Fragment key={category.key}>
                {category.subCategories ? (
                  <SubMenu
                    icon={category.icon}
                    key={category.key}
                    title={category.name}
                  >
                    {category.subCategories?.map((sub) => (
                      <Menu.Item key={sub.key}>
                        <Link to={sub.path}>{sub.name}</Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={category.key} icon={category.icon}>
                    <Link to={category.path}>{category.name}</Link>
                  </Menu.Item>
                )}
              </Fragment>
            );
          })}
    </Menu>
  );
};
