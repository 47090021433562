import { useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { message } from "antd";
import { ClientError } from "graphql-request";

import sdk from "@/sdk";

export const useKYCs = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["getKYCs", { page, pageSize }],
    queryFn: () => sdk.getKYCs({ page, limit: pageSize }),
    select: (data) => data.getKYCs,
  });
  const { isLoading: isDeleting, mutateAsync } = useMutation({
    mutationFn: (id: string) => sdk.deleteKYC({ _id: id }),
    onSuccess: () => {
      message.success("Амжилттай устгагдлаа");
    },
    onError: (err: ClientError) => {
      message.warn(err.response?.errors?.[0]?.message ?? err.message, 2);
    },
  });
  return {
    page,
    pageSize,
    setPage,
    setPageSize,
    data,
    loading: isLoading || isDeleting,
    getKYCs: refetch,
    deleteKYC: mutateAsync,
  };
};
