import { useQuery } from "@tanstack/react-query";

import sdk from "@/sdk";

export function useGetBalance({ _id }: { _id: string }) {
  const { isLoading, error, data } = useQuery(["balance", _id], {
    queryFn: () => sdk.getBalance({ _id }),
    select: (data) => data.getBalance,
  });

  const err = error as any;
  return {
    data,
    loading: isLoading,
    error: err?.response?.errors?.[0]?.message ?? err?.message,
  };
}
