import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { GetConfigsQuery } from "../../graphql";
import sdk from "../../sdk";

export const useConfigs = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetConfigsQuery>();

  const getConfigs = useCallback(() => {
    setLoading(true);
    sdk
      .getConfigs()
      .then((data) => setData(data))
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getConfigs();
  }, [getConfigs]);
  return {
    data: data?.getConfigs,
    loading,
    getConfigs,
  };
};
