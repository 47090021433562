import { Route, useHistory } from "react-router-dom";
import { useEffect, useMemo, useRef } from "react";

import { message } from "antd";
import { ClientError } from "graphql-request";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import SearchProvider from "./context/UserSearchProvider";

import { AdminApp } from "./apps/Admin";
import { LogoutPage } from "./pages/Logout";

export function App() {
  const history = useHistory();
  const historyRef = useRef(history);
  useEffect(() => {
    historyRef.current = history;
  }, [history]);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (err, query) => {
            if (query.meta?.errorMessage) {
              message.warn(query.meta.errorMessage);
              return;
            }

            const gqlError =
              err instanceof ClientError ? err.response.errors?.[0] : null;
            if (gqlError?.extensions.code === "UNAUTHORIZED") {
              historyRef.current.replace("/logout");
            } else {
              message.warn(
                gqlError?.message ?? "Алдаа гарлаа. Та дахин оролдоно уу.",
                2
              );
            }
          },
        }),
      }),
    []
  );
  return (
    <QueryClientProvider client={queryClient}>
      <SearchProvider>
        <Route component={AdminApp} path="/" />
        <Route component={LogoutPage} path="/logout" />
      </SearchProvider>
    </QueryClientProvider>
  );
}
