import { Button, Form, Input } from "antd";
import { LoginOutlined, KeyOutlined, SendOutlined } from "@ant-design/icons";

import { emailRegex } from "@/utils/validation";

import { useLogin } from "./useLogin";

export function LoginWidget() {
  const [form] = Form.useForm();
  const {
    loading,
    otpChallenge,
    webauthnChallenge,
    submit,
    requestOtp,
    submitWebauthn,
    getLoginChallenge,
  } = useLogin();
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => {
        if (webauthnChallenge || otpChallenge) {
          submit(values);
          return;
        }
        getLoginChallenge(values.username);
      }}
    >
      <Form.Item
        label="И-мэйл хаяг"
        name="username"
        style={{ marginBottom: "16px" }}
        rules={[
          { required: true, message: "И-мэйл хаяг оруулна уу" },
          {
            pattern: emailRegex,
            message: "Зөв и-мэйл хаяг оруулна уу",
          },
        ]}
      >
        <Input
          autoComplete="email"
          placeholder="example@example.com"
          disabled={!!loading}
        />
      </Form.Item>
      {otpChallenge && (
        <Form.Item
          label="Нууц үг"
          name="password"
          rules={[{ required: true, message: "Нууц үгээ оруулна уу!" }]}
        >
          <Input.Password
            autoComplete="one-time-code"
            placeholder="******"
            disabled={!!loading}
          />
        </Form.Item>
      )}
      {/*
       * Possible states:
       * 1. Idle, wait for user to input username
       * 2a. OTP challenge, wait for user to input password
       * 2b. Webauthn challenge, wait for user to authenticate or to request OTP -> 2a, 3
       * 3. Loading, waiting for server response
       */}
      {webauthnChallenge && (
        <Form.Item>
          <Button
            loading={loading === "webauthn"}
            disabled={!!loading}
            icon={<KeyOutlined />}
            style={{ width: "100%" }}
            htmlType="button"
            type="ghost"
            onClick={() => submitWebauthn()}
          >
            {loading === "webauthn" ? "Нэвтэрч байна…" : "Дахин оролдох"}
          </Button>
        </Form.Item>
      )}
      {(otpChallenge || webauthnChallenge) && (
        <Form.Item>
          <Button
            loading={loading === "challenge"}
            disabled={!!loading}
            icon={<SendOutlined />}
            style={{ width: "100%" }}
            htmlType="button"
            type="ghost"
            onClick={() => requestOtp(form.getFieldValue("username"))}
          >
            {otpChallenge ? "Дахин илгээх" : "Нэг удаагийн кодоор нэвтрэх"}
          </Button>
        </Form.Item>
      )}
      {!webauthnChallenge && (
        <Form.Item>
          <Button
            loading={loading === "challenge" || loading === "otp"}
            disabled={!!loading}
            icon={<LoginOutlined />}
            style={{ width: "100%" }}
            htmlType="submit"
            type="primary"
          >
            {otpChallenge ? "Нэвтрэх" : "Үргэлжлүүлэх"}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}
