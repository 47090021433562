import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, Popconfirm, Select, Space, Switch } from "antd";
import {
  DeleteOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { Currency, Duration, Stock } from "../../graphql";
import { useStocks } from "../stock/useStocks";
import { useEditPortfolio } from "./useEditPortfolio";
import { usePortfolio } from "./usePortfolio";
import { SelectStock } from "../../components/SelectStock";
import BlueDivider from "../../components/BlueDivider";

export const { Option } = Select;

export interface NullablePortfolioStock {
  stock: Omit<Stock, "unitPrice"> | null;
  value: number | null;
}

export interface NullablePortfolioSaving {
  currencyCode: Currency | null;
  value: number | null;
}

export const AddPortfolioWidget = ({ _id }: { _id: string }) => {
  const [active, setActive] = useState(false);

  const { form, stocks, loading, setStocks, addPortfolio, editPortfolio } =
    useEditPortfolio(
      _id === "create"
        ? { selectedPortfolioId: undefined, active }
        : { selectedPortfolioId: _id, active }
    );
  const [selectedStock, setSelectedStock] =
    useState<NullablePortfolioStock | null>(null);
  const [selectedStockIndex, setSelectedStockIndex] = useState<number>(0);
  const { data: stocksData, loading: fetchingStocks } = useStocks();
  const { data: portfolio, loading: fetchingPortfolio } = usePortfolio({ _id });

  useEffect(() => {
    if (_id !== "create" && portfolio) {
      form.setFields([
        {
          name: "name_mn",
          value: portfolio.localizedName?.mn ?? portfolio.name,
        },
        {
          name: "name_en",
          value: portfolio.localizedName?.en ?? portfolio.name,
        },
        {
          name: "description_mn",
          value: portfolio.localizedDescription?.mn ?? portfolio.description,
        },
        {
          name: "description_en",
          value: portfolio.localizedDescription?.en ?? portfolio.description,
        },
        {
          name: "index",
          value: portfolio.index,
        },
        {
          name: "riskLevel",
          value: portfolio.riskLevel,
        },
        {
          name: "duration",
          value: portfolio.duration,
        },
        {
          name: "bond",
          value: portfolio.bond.value,
        },
        {
          name: "saving",
          value: portfolio.saving.value,
        },
      ]);
      setActive(portfolio.active ?? false);
      setStocks(portfolio.stocks);
    }
  }, [_id, portfolio, form, setStocks]);

  const addStock = () => {
    const stock: NullablePortfolioStock = { stock: null, value: null };
    const newStock = [...stocks, stock];
    setStocks(newStock);
  };

  const deleteStock = (index: number) => {
    if (stocks.length > 1) {
      stocks.splice(index, 1);
      setStocks([...stocks]);
    } else {
      updateStock({ data: null, index });
    }
  };
  const updateStock = ({
    data,
    index,
  }: {
    data: NullablePortfolioStock | null;
    index: number;
  }) => {
    const newStocks = [...stocks];
    newStocks[index] = data;
    setStocks(newStocks);
  };

  return (
    <>
      <div className="page-header">
        <h3>{_id === "create" ? "Багц нэмэх" : "Багц засах"}</h3>
        <Space>
          <Link to="/portfolios">
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Буцах
            </Button>
          </Link>
        </Space>
      </div>
      <SelectStock
        stock={selectedStock}
        setStock={setSelectedStock}
        stocks={stocksData?.stocks ?? null}
        loading={fetchingStocks}
        stockIndex={selectedStockIndex}
        onSubmit={(data) => updateStock(data)}
      />
      <Form
        style={{ marginTop: 20 }}
        form={form}
        onFinish={_id === "create" ? addPortfolio : editPortfolio}
        layout="vertical"
      >
        <BlueDivider
          text="Мэдээлэл"
          styles={{ marginBottom: "30px", marginTop: "10px" }}
        />
        <Form.Item
          label="Нэр (Монгол)"
          name="name_mn"
          rules={[{ required: true, message: "Нэр оруулна уу " }]}
        >
          <Input disabled={loading || fetchingPortfolio} />
        </Form.Item>
        <Form.Item
          label="Нэр (Англи)"
          name="name_en"
          rules={[{ required: true, message: "Нэр оруулна уу" }]}
        >
          <Input disabled={loading || fetchingPortfolio} />
        </Form.Item>
        <Form.Item
          label="Тайлбар (Монгол)"
          name="description_mn"
          rules={[{ required: true, message: "Тайлбар оруулна уу" }]}
        >
          <Input.TextArea disabled={loading || fetchingPortfolio} />
        </Form.Item>
        <Form.Item
          label="Тайлбар (Англи)"
          name="description_en"
          rules={[{ required: true, message: "Тайлбар оруулна уу" }]}
        >
          <Input.TextArea disabled={loading || fetchingPortfolio} />
        </Form.Item>
        <Form.Item
          label="Дугаарлалт"
          name="index"
          rules={[{ required: true, message: "Дугаарлалт оруулна уу" }]}
        >
          <Input type="number" disabled={loading || fetchingPortfolio} />
        </Form.Item>
        <Form.Item
          label="Эрсдлийн түвшин"
          name="riskLevel"
          rules={[{ required: true, message: "Эрсдлийн түвшин оруулна уу" }]}
        >
          <Input type="number" disabled={loading || fetchingPortfolio} />
        </Form.Item>
        <Form.Item
          label="Хугацаа"
          name="duration"
          rules={[{ required: true, message: "Хугацаа оруулна уу" }]}
        >
          <Select disabled={loading || fetchingPortfolio}>
            {Object.values(Duration).map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          style={{ marginRight: 30 }}
          label="Бондын эзлэх хувь"
          name="bond"
          rules={[{ required: true, message: "Бондын эзлэх хувь оруулна уу" }]}
        >
          <Input type="number" disabled={loading || fetchingPortfolio} />
        </Form.Item>
        <Form.Item
          style={{ marginRight: 30 }}
          label="Үл хөдлөх хөрөнгийн эзлэх хувь"
          name="saving"
          rules={[
            {
              required: true,
              message: "Үл хөдлөх хөрөнгийн эзлэх хувь оруулна уу",
            },
          ]}
        >
          <Input type="number" disabled={loading || fetchingPortfolio} />
        </Form.Item>
        <Form.Item style={{ marginRight: 30 }} label="Идэвхитэй эсэх">
          <Switch checked={active} onChange={() => setActive(!active)} />
        </Form.Item>
        <div className="form-flex-container">
          <Form.Item style={{ width: "45%" }} label="Үнэт цаасууд" required>
            {stocks.map((stock, index) => (
              <div style={{ width: "100%" }}>
                <Button
                  style={{
                    margin: "5px 5px 5px 0",
                    width: "90%",
                    textAlign: "left",
                  }}
                  onClick={() => {
                    setSelectedStockIndex(index);
                    setSelectedStock(stock);
                  }}
                >
                  {stock?.stock
                    ? stock?.stock?.name + " - " + stock?.value + "%"
                    : "Хоосон"}
                </Button>
                <Popconfirm
                  disabled={loading}
                  title="Та устгахдаа итгэлтэй байна уу?"
                  okText="Тийм"
                  cancelText="Үгүй"
                  onConfirm={() => {
                    deleteStock(index);
                  }}
                >
                  <Button
                    size="middle"
                    disabled={loading}
                    type="dashed"
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                style={{ marginTop: 10 }}
                onClick={addStock}
                icon={<PlusOutlined />}
              >
                Үнэт цаас нэмэх
              </Button>
            </Form.Item>
          </Form.Item>
        </div>
        <div className="form-flex-container">
          <div />
          <Button htmlType="submit" type="primary">
            {_id === "create" ? "Багц нэмэх" : "Багц засах"}
          </Button>
        </div>
      </Form>
    </>
  );
};
