import invariant from "ts-invariant";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Radio, Modal, Space, InputNumber, message } from "antd";

import sdk from "@/sdk";
import { ReturnOrderMutationVariables } from "@/graphql";

export function ReturnOrderModal({
  orderId,
  close,
}: {
  orderId: string | null;
  close: () => void;
}) {
  const onClose = () => {
    close();
    setAmount("");
    setCustomAmount(false);
  };
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (variables: ReturnOrderMutationVariables) => {
      return sdk.returnOrder(variables);
    },
    onSettled: onClose,
    onSuccess: () => message.success("Амжилттай буцаагдлаа.", 2),
    onError: (err) =>
      message.warn((err as any).response?.errors[0]?.message, 2),
  });
  const [customAmount, setCustomAmount] = useState(false);
  const [amount, setAmount] = useState("");
  return (
    <Modal
      open={!!orderId}
      title="Цэнэглэлт буцаах"
      cancelText="Болих"
      okText="Буцаах"
      onCancel={onClose}
      onOk={() => {
        invariant(orderId, "Order id is required");
        return mutateAsync({
          id: orderId,
          amount: customAmount ? parseFloat(amount) : null,
        });
      }}
    >
      <Radio.Group
        onChange={(e) => setCustomAmount(e.target.value)}
        value={customAmount}
        disabled={isLoading}
      >
        <Space direction="vertical">
          <Radio value={false}>Бүтэн дүнгээр</Radio>
          <Radio value={true}>Өөр дүн оруулах</Radio>
        </Space>
      </Radio.Group>
      <InputNumber
        disabled={!customAmount || isLoading}
        value={amount}
        placeholder="Буцаах дүнгээ оруулна уу"
        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        onChange={(value) =>
          setAmount(`${value ?? ""}`.replace(/\B(?=(\d{3})+(?!\d))/g, ""))
        }
        style={{ width: "100%", marginTop: "1rem" }}
      />
    </Modal>
  );
}
