import { useHistory } from "react-router-dom";
import { Button, Tabs } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { GroupDetailsParams } from "../../../pages/user/group-details";
import { TabPane } from "../../../pages/Order";

import { useSearchParam } from "@/utils/url-state";

import { KycTab } from "./tabs";
import { UserTab } from "./tabs/user";
import { PortfolioTab } from "./tabs/portfolio";
import { BalanceHistoryTab } from "./tabs/balanceHistory";

export const GroupDetailsWidget = ({
  _id,
}: {
  _id: GroupDetailsParams["_id"];
}) => {
  const history = useHistory();
  const [tab, { set }] = useSearchParam("tab");
  return (
    <>
      <div className="page-header">
        <h3>Дэлгэрэнгүй мэдээлэл</h3>
        <div className="page-header-buttons">
          <Button
            icon={<ArrowLeftOutlined />}
            className="back-button"
            onClick={() => history.goBack()}
          >
            Буцах
          </Button>
        </div>
      </div>
      <div className="page-body">
        <Tabs defaultActiveKey={tab ?? "kyc"} onChange={set}>
          <TabPane tab="KYC" key="kyc">
            <KycTab _id={_id} />
          </TabPane>
          <TabPane tab="Хэрэглэгчийн мэдээлэл" key="info">
            <UserTab _id={_id} />
          </TabPane>
          <TabPane tab="Багцын мэдээлэл" key="portfolio">
            <PortfolioTab _id={_id} />
          </TabPane>
          <TabPane tab="Захиалгын биелэлт" key="orders">
            <BalanceHistoryTab _id={_id} />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};
