import { Modal, Table } from "antd";
import moment from "moment";
import { Dispatch } from "react";
import { formatBalance } from "../../utils/formatters";

export const SavingBondHistoryModal = ({
  data,
  setData,
}: {
  data: any | undefined;
  setData: Dispatch<any | undefined>;
}) => {
  const resultCalc = ({ after, before }: { after: number; before: number }) => {
    const balance: number = after - before;
    const symbol = balance >= 0 ? "+" : "";
    return (
      <span
        style={{
          color: symbol === "+" ? "green" : "red",
        }}
      >
        {symbol}
        {formatBalance({ balance })}
      </span>
    );
  };
  return (
    <Modal
      width={1600}
      title="Өөрчлөлтийн түүх"
      visible={!!data}
      onCancel={() => setData(undefined)}
      footer={false}
    >
      <Table
        size="small"
        bordered
        scroll={{ x: 2000 }}
        dataSource={
          data?.sort((a: any, b: any) => b.createdAt - a.createdAt) ?? []
        }
        columns={[
          {
            title: "Огноо",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => (
              <span>{moment(text).format("YYYY/MM/DD HH:mm:ss")}</span>
            ),
          },
          {
            title: "Өмнөх нийт үлдэгдэл",
            dataIndex: "beforePrincipleAmount",
            key: "beforePrincipleAmount",
            render: (text) => <span>{formatBalance({ balance: text })} ₮</span>,
          },
          {
            title: "Нийт үлдэгдэл",
            render: (_, record) =>
              resultCalc({
                after: record.afterPrincipleAmount,
                before: record.beforePrincipleAmount,
              }),
          },
          {
            title: "Дараах нийт үлдэгдэл",
            dataIndex: "afterPrincipleAmount",
            key: "afterPrincipleAmount",

            render: (text) => <span>{formatBalance({ balance: text })} ₮</span>,
          },

          {
            title: "Өмнөх хуримтлагдсан хүү",
            dataIndex: "beforeAccumulatedAmount",
            key: "beforeAccumulatedAmount",
            render: (text) => <span>{formatBalance({ balance: text })}</span>,
          },
          {
            title: "Хуримтлагдсан хүү",
            render: (_, record) =>
              resultCalc({
                after: record.afterAccumulatedAmount,
                before: record.beforeAccumulatedAmount,
              }),
          },
          {
            title: "Дараах хуримтлагдсан хүү",
            dataIndex: "afterAccumulatedAmount",
            key: "afterAccumulatedAmount",
            render: (text) => <span>{formatBalance({ balance: text })}</span>,
          },

          {
            title: "Өмнөх шимтгэл",
            dataIndex: "beforeFee",
            key: "beforeFee",
            render: (text) => <span>{formatBalance({ balance: text })} ₮</span>,
          },
          {
            title: "Шимтгэл",
            render: (_, record) =>
              resultCalc({
                after: record.afterFee,
                before: record.beforeFee,
              }),
          },
          {
            title: "Дараах шимтгэл",
            dataIndex: "afterFee",
            key: "afterFee",
            render: (text) => <span>{formatBalance({ balance: text })} ₮</span>,
          },
          {
            title: "Төрөл",
            dataIndex: "type",
            key: "type",
          },
        ]}
      />
    </Modal>
  );
};
