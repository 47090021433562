import { Button, Popconfirm, Space, Table, Tag } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useSurveys } from "./useSurveys";

export const SurveyWidget = () => {
  const { data, loading, deleteSurvey } = useSurveys();
  return (
    <>
      <div className="page-header">
        <h3>Судалгааны жагсаалт</h3>
        <Space>
          <Link to={`/surveys/create`}>
            <Button type="primary" icon={<PlusOutlined />}>
              Судалгаа нэмэх
            </Button>
          </Link>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data ?? []}
            size="small"
            loading={loading}
            pagination={false}
            bordered
            columns={[
              {
                title: "Судалгааны нэр",
                dataIndex: "name",
                key: "name",
                render: (text) => <span>{text}</span>,
              },
              {
                title: "Идэвхитэй эсэх",
                dataIndex: "active",
                key: "active",
                render: (text) => (
                  <span>
                    <Tag>{text ? "Идэвхитэй" : "Идэвхигүй"}</Tag>
                  </span>
                ),
              },
              {
                title: "Асуултын тоо",
                dataIndex: "questions",
                key: "questions",
                render: (_, record) => (
                  <span>{record.questions?.length ?? 0}</span>
                ),
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "130px",
                render: (_, record) => (
                  <Space>
                    <Link to={`surveydetail/${record._id}`}>
                      <Button
                        type="primary"
                        shape="circle"
                        disabled={loading}
                        icon={<EyeOutlined />}
                      />
                    </Link>
                    <Link to={`surveys/${record._id}`}>
                      <Button
                        type="primary"
                        shape="circle"
                        disabled={loading}
                        icon={<EditOutlined />}
                      />
                    </Link>
                    <Popconfirm
                      disabled={loading}
                      title="Та устгахдаа итгэлтэй байна уу?"
                      okText="Тийм"
                      cancelText="Үгүй"
                      onConfirm={() => {
                        if (record._id) {
                          deleteSurvey(record._id);
                        }
                      }}
                    >
                      <Button
                        size="middle"
                        shape="circle"
                        disabled={loading}
                        danger
                        type="primary"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                  </Space>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
