import { Link } from "react-router-dom";
import { Space, Button, Table, Popconfirm, Tag } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { useSavings } from "./useSavings";
import { formatAccountNo } from "../../utils/formatters";

export const SavingWidget = () => {
  const { loading, data, setPage, deleteSaving } = useSavings();
  return (
    <>
      <div className="page-header">
        <h3>Үл хөдлөх хөрөнгийн жагсаалт</h3>
        <Space>
          <Link to={"/savings/add"}>
            <Button type="primary" icon={<PlusOutlined />}>
              Үл хөдлөх хөрөнгө нэмэх
            </Button>
          </Link>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data?.savings ?? []}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 20,
              total: Number(data?.totalPages) * 20,
            }}
            onChange={(pagination) => setPage(Number(pagination?.current) - 1)}
            bordered
            columns={[
              {
                title: "Банкны нэр",
                dataIndex: "bankName",
                key: "bankName",
                render: (text) => <span>{text}</span>,
              },
              {
                title: "Дансны дугаар",
                dataIndex: "accountNo",
                key: "accountNo",
                render: (text) => (
                  <span>{formatAccountNo({ accountNo: text })}</span>
                ),
              },
              {
                title: "Вальют",
                dataIndex: "currency",
                key: "currency",
                render: (text) => <Tag>{text}</Tag>,
              },
              {
                title: "Хүү (%)",
                dataIndex: "interestRate",
                key: "interestRate",
                render: (text) => <span>{text}%</span>,
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "90px",
                render: (_, record) => {
                  return (
                    <Space>
                      <Link to={"/savings/edit/" + record._id}>
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          icon={<EditOutlined />}
                        />
                      </Link>
                      <Popconfirm
                        disabled={loading}
                        title="Та устгахдаа итгэлтэй байна уу?"
                        okText="Тийм"
                        cancelText="Үгүй"
                        onConfirm={() => {
                          if (record._id) {
                            deleteSaving(record._id);
                          }
                        }}
                      >
                        <Button
                          size="middle"
                          shape="circle"
                          disabled={loading}
                          danger
                          type="primary"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
