import { useEffect } from "react";
import EditUserWidget from "../../../widgets/user/edit";
import { useParams } from "react-router-dom";
export type UserParamsType = {
  _id: string;
};

export const EditUserPage = () => {
  const { _id } = useParams<UserParamsType>();
  useEffect(() => {
    document.title = "Хэрэглэгч Засах";
  }, []);
  return (
    <div className="page">
      <EditUserWidget _id={_id} />
    </div>
  );
};
