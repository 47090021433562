import moment from "moment";
import { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Space, Table } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";

import sdk from "@/sdk";
import {
  ReportSummary,
  ExportFeeReportForDateRangeMutationVariables,
} from "@/graphql";

import { DateRangeModal } from "../../widgets/DateRangeModal";

export function FeeReportsPage() {
  const { data, isLoading } = useQuery({
    queryKey: ["reports", "fee"],
    queryFn: () => sdk.feeReports(),
    select: (data) => data.feeReports,
  });
  const { mutate, isLoading: isDownloadingReport } = useMutation({
    mutationFn: async (batchId: string) => {
      const {
        downloadFeeReport: { reportUrl },
      } = await sdk.downloadFeeReport({ batchId });
      window.open(reportUrl);
    },
  });

  const [showCreateReportModal, setShowCreateReportModal] = useState(false);
  const { mutate: createReport, isLoading: isCreatingReport } = useMutation({
    mutationFn: async ({
      startDate,
      endDate,
    }: ExportFeeReportForDateRangeMutationVariables) => {
      const {
        exportFeeReportForDateRange: { reportUrl },
      } = await sdk.exportFeeReportForDateRange({
        startDate,
        endDate,
      });
      window.open(reportUrl);
    },
    onSettled: () => setShowCreateReportModal(false),
  });
  return (
    <main className="page">
      <DateRangeModal
        title="Тайлан үүсгэх"
        show={showCreateReportModal}
        onClose={setShowCreateReportModal.bind(null, false)}
        loading={isCreatingReport}
        onSubmit={(startDate, endDate) =>
          createReport({
            startDate: startDate.format("YYYYMMDD"),
            endDate: endDate.format("YYYYMMDD"),
          })
        }
      />
      <div className="page-header">
        <h3>Шимтгэлийн тайлан</h3>
        <Button type="primary" onClick={() => setShowCreateReportModal(true)}>
          Тайлан үүсгэх
        </Button>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table loading={isLoading} dataSource={data}>
            <Table.Column
              title="Огноо"
              dataIndex="batchId"
              render={(value: string) =>
                moment(value, "YYYYMMDD").format("YYYY.MM.DD")
              }
            />
            <Table.Column
              title="Үйлдэл"
              render={(_, { batchId }: ReportSummary) => (
                <Space>
                  <Button
                    type="primary"
                    shape="circle"
                    disabled={isDownloadingReport}
                    icon={<DownloadOutlined />}
                    onClick={() => {
                      if (batchId) {
                        mutate(batchId);
                      }
                    }}
                  />
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </main>
  );
}
