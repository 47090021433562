import { Button, Form, Input, Col, Row, Divider, Select } from "antd";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { debounce } from "ts-debounce";

import BlueDivider from "../../../components/BlueDivider";
import useAddActivity from "./useAddActivity";
import { useUsers } from "../../user/useUsers";
import { ActivityType } from "../../../graphql";
import { Option } from "../../user/modal/create";
import { useContext } from "react";
import { SearchCtx } from "@/context/UserSearchProvider";

export const { TextArea } = Input;

export const AddActivityWidget = () => {
  const { form, loading, submit } = useAddActivity();
  const { data, loading: fetchingUsers } = useUsers();

  const { setSearch } = useContext(SearchCtx);
  const handleChange = debounce((value: string) => {
    setSearch(value);
  }, 500);

  return (
    <>
      <div className="page-header">
        <h3>Мэдэгдэл нэмэх</h3>
        <div className="page-header-buttons">
          <Link to="/activity">
            <Button icon={<ArrowLeftOutlined />} className="back-button">
              Буцах
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-body">
        <Form
          form={form}
          onFinish={submit}
          layout="vertical"
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={11}>
              <BlueDivider
                text="Хэрэглэгчид"
                styles={{ marginBottom: "30px", marginTop: "10px" }}
              />
              <Form.Item
                label="Хэрэглэгчид"
                name="users"
                rules={[{ required: true, message: "Хэрэглэгчид оруулна уу" }]}
              >
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  mode="multiple"
                  loading={fetchingUsers}
                  onSearch={(value) => handleChange(value)}
                  filterOption={(input, option) =>
                    option!.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option!.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {data?.users?.map((user) => (
                    <Option key={user._id} value={user._id}>
                      {user.email} - {user.phoneNo}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Ангилал"
                name="type"
                rules={[
                  { required: true, message: "Киноны ангилал оруулна уу" },
                ]}
              >
                <Select style={{ width: "100%" }} disabled={loading}>
                  <Option key={ActivityType.Basic} value={ActivityType.Basic}>
                    Энгийн
                  </Option>
                  <Option key={ActivityType.Order} value={ActivityType.Order}>
                    Цэнэглэлт
                  </Option>
                  <Option
                    key={ActivityType.Purchase}
                    value={ActivityType.Purchase}
                  >
                    Худалдан авалт
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={1}>
              <Divider style={{ height: "100%" }} type="vertical" />
            </Col>
            <Col span={11}>
              <BlueDivider
                text="Мэдээлэл"
                styles={{ marginBottom: "30px", marginTop: "10px" }}
              />
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Title оруулна уу" }]}
              >
                <Input.TextArea disabled={loading} />
              </Form.Item>
              <Form.Item
                label="Body"
                name="body"
                rules={[{ required: true, message: "Body оруулна уу" }]}
              >
                <Input.TextArea disabled={loading} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="form-flex-container">
          <div />
          <Button
            type="primary"
            key="submit"
            style={{ marginTop: "30px" }}
            icon={<PlusOutlined />}
            onClick={() => form.validateFields().then(() => form.submit())}
          >
            Нэмэх
          </Button>
        </div>
      </div>
    </>
  );
};
