import { message } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";

import sdk from "@/sdk";
import { Balance, PrepareOrderListResult } from "@/graphql";

export const useCreateOrderList = () => {
  const [loading, setLoading] = useState(false);
  const [usdRate, setUsdRate] = useState(0);
  const [balances, setBalances] = useState<Balance[]>([]);
  const [excelData, setExcelData] = useState<PrepareOrderListResult | null>(
    null
  );

  const [page, setPage] = useState(0);
  const { data, error, refetch, isLoading } = useQuery({
    queryKey: ["getAvailableBalances", page],
    queryFn: () =>
      sdk.getAvailableBalances({
        page,
        limit: 40,
        options: { pagination: false },
        advanced: { isProcessing: false },
      }),
  });
  useEffect(() => {
    if (error) {
      message.warn((error as any).response?.errors[0]?.message, 2);
    }
  }, [error]);

  const prepareOrderList = useCallback(() => {
    if (!balances.length) {
      message.warn("Хэрэглэгч сонгоно уу.", 2);
      return;
    }
    setLoading(true);
    sdk
      .prepareOrderList({
        balances: balances
          .filter((filteredBalance) => filteredBalance._id !== null)
          .map((balance) => String(balance._id)),
      })
      .then((data) => {
        setExcelData(data.prepareOrderList);
        setLoading(false);
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [balances]);

  const createOrderList = useCallback(() => {
    if (!balances.length) {
      message.warn("Хэрэглэгч сонгоно уу.", 2);
      return;
    }
    if (!usdRate || usdRate < 0) {
      message.warn("Долларын ханш оруулна уу.", 2);
      return;
    }
    setLoading(true);
    sdk
      .createOrderList({
        balances: balances
          .filter((filteredBalance) => filteredBalance._id !== null)
          .map((balance) => String(balance._id)),
        usdRate,
      })
      .then((data) => {
        if (data.createOrderList) {
          message.success("Амжилттай үүслээ.");
          setBalances([]);
          setUsdRate(0);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [balances, usdRate]);

  return {
    data: data?.getAvailableBalances,
    loading: loading || isLoading,
    usdRate,
    balances,
    excelData,
    setPage,
    setUsdRate,
    setBalances,
    setExcelData,
    getAvailableBalances: refetch,
    prepareOrderList,
    createOrderList,
  };
};
