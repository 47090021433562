import { useEffect } from "react";
import { CreateOrderListWidget } from "../../widgets/orderlist/create";

export const CreateOrderListPage = () => {
  useEffect(() => {
    document.title = "Захиалгын хуудас бэлдэх";
  }, []);
  return (
    <div className="page">
      <CreateOrderListWidget />
    </div>
  );
};
