import { useFAQs } from "./useFAQs";
import { Button, Popconfirm, Space, Table } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { AddFAQWidget } from "./modal";
import { useHistory } from "react-router-dom";

export const FAQWidget = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const { data, loading, getFAQs, deleteFaq } = useFAQs();

  const createMarkUp = (data: string) => {
    return {
      __html: data,
    };
  };

  return (
    <>
      <AddFAQWidget show={show} setShow={setShow} getFAQs={getFAQs} />
      <div className="page-header">
        <h3>FAQ жагсаалт</h3>
        <Space>
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => setShow(true)}
          >
            FAQ нэмэх
          </Button>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data ?? []}
            size="small"
            loading={loading}
            pagination={false}
            bordered
            columns={[
              {
                title: "Асуулт",
                dataIndex: "question",
                key: "question",
                render: (text) => <span className="text_ellipse">{text}</span>,
              },
              {
                title: "Хариулт",
                dataIndex: "answer",
                key: "answer",
                render: (text) => (
                  <span
                    dangerouslySetInnerHTML={createMarkUp(text)}
                    className="text_ellipse"
                  />
                ),
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "90px",
                render: (_, record) => {
                  return (
                    <Space>
                      <Button
                        type="primary"
                        shape="circle"
                        disabled={loading}
                        icon={<EditOutlined />}
                        onClick={() => history.push(`faqs/${record._id}`)}
                      />
                      <Popconfirm
                        disabled={loading}
                        title="Та устгахдаа итгэлтэй байна уу?"
                        okText="Тийм"
                        cancelText="Үгүй"
                        onConfirm={() => {
                          if (record._id) {
                            deleteFaq(record._id);
                          }
                        }}
                      >
                        <Button
                          size="middle"
                          shape="circle"
                          disabled={loading}
                          danger
                          type="primary"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
