import { WithdrawalOrderListEstateTotals } from "@/graphql";
import { formatAmount } from "@/utils/formatters";

type WithdrawSavingsProps = {
  savings?: WithdrawalOrderListEstateTotals[];
};

export function WithdrawSavings({ savings }: WithdrawSavingsProps) {
  return (
    <div style={{ paddingTop: 30 }}>
      <p style={{ fontWeight: "bold" }}>Нийт буцаасан үл хөдлөх хөрөнгө:</p>
      {savings === undefined || savings.length === 0 ? (
        <p>Буцаасан үл хөдлөх хөрөнгө байхгүй байна</p>
      ) : (
        <div style={{ width: 400 }}>
          {savings.map((value, index) => (
            <div key={index} style={{ width: 400, display: "flex" }}>
              <div style={{ width: 200, fontSize: 16, fontWeight: "bold" }}>
                {value.asset.bankName}
              </div>
              <div style={{ width: 200, fontSize: 16, fontWeight: "bold" }}>
                {formatAmount({ amount: value.totalAmount })}₮
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
