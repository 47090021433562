import { message } from "antd";
import { useCallback, useEffect, useState } from "react";

import sdk from "../../sdk";
import { GetPortfoliosQuery } from "../../graphql";

export const usePortfolios = () => {
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetPortfoliosQuery>();

  const getPortfolios = useCallback(() => {
    setLoading(true);
    sdk
      .getPortfolios({ page, limit: 30 })
      .then((data) => setData(data))
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [page]);

  const deletePortfolio = (id: string) => {
    sdk
      .deletePortfolio({ _id: id })
      .then(() => {
        message.success("Амжилттай устгагдлаа");
        getPortfolios();
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPortfolios();
  }, [getPortfolios, page]);

  return {
    data: data?.getPortfolios,
    getPortfolios,
    setPage,
    deletePortfolio,
    loading,
  };
};
