import React from "react";
import { UserParamsType } from "../../../pages/user/edit";
import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useEditUser } from "../modal/useEditUser";
import { Role, UserType } from "../../../graphql";
import { Link } from "react-router-dom";
import BlueDivider from "../../../components/BlueDivider";
const Option = Select;

const EditUserWidget = ({ _id }: { _id: UserParamsType["_id"] }) => {
  const { form, loading, editUser } = useEditUser({
    selectedUserId: _id,
  });
  return (
    <>
      <div className="page-header">
        <h3>Хэрэглэгч засах</h3>
        <div className="page-header-buttons">
          <Link to="/users">
            <Button icon={<ArrowLeftOutlined />} className="back-button">
              Буцах
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-body">
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={editUser}
        >
          <Row>
            <Col span={24}>
              <BlueDivider
                text={"Хувийн мэдээлэл"}
                styles={{ marginBottom: "25px" }}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="center">
            <Col span={12}>
              <Form.Item label="Овог" name="lastName">
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Нэр" name="firstName">
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Цахим шуудан" name="email">
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Утасны дугаар" name="phoneNo">
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="role" label="Хэрэглэгчийн эрх">
                <Select disabled={loading} style={{ width: "100%" }}>
                  {Object.values(Role).map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Төрсөн огноо" name="birthDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="type" label="Хэрэглэгчийн төрөл">
                <Select disabled={loading} style={{ width: "100%" }}>
                  {Object.values(UserType).map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col span={12}>
              <BlueDivider
                text={"Дансны мэдээлэл"}
                styles={{ marginBottom: "32px" }}
              />

              <Form.Item label="Банкны нэр" name="bankName">
                <Input disabled={loading} />
              </Form.Item>

              <Form.Item label="Дансны нэр" name="accountName">
                <Input disabled={loading} />
              </Form.Item>

              <Form.Item label="Дансны дугаар" name="accountNo">
                <Input disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <BlueDivider text={"Нууц үг"} styles={{ marginBottom: "32px" }} />

              <Form.Item label="Нууц үг" name="password">
                <Input.Password disabled={loading} />
              </Form.Item>

              <Form.Item label="Нууц үг давтах" name="verifyPassword">
                <Input.Password disabled={loading} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "right" }}
              >
                <Button
                  type="primary"
                  key="submit"
                  icon={<UserOutlined />}
                  onClick={() => form.validateFields().then(editUser)}
                >
                  Хэрэглэгч засах
                </Button>
              </Col>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default EditUserWidget;
