import { message } from "antd";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { GetFaqsQuery } from "../../graphql";
import sdk from "../../sdk";

export const useFAQs = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetFaqsQuery>();

  const getFAQs = useCallback(() => {
    setLoading(true);
    sdk
      .getFaqs()
      .then((data) => setData(data))
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, []);
  const deleteFaq = (id: string) => {
    sdk
      .deleteFaq({ _id: id })
      .then(() => {
        message.success("Амжилттай устгагдлаа");
        getFAQs();
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getFAQs();
  }, [getFAQs]);
  return {
    data: data?.getFaqs,
    loading,
    getFAQs,
    deleteFaq,
  };
};
