import { GraphQLClient } from "graphql-request";

import { getSdk } from "./graphql";

export interface GraphQLError extends Error {
  response: {
    errors: {
      message: string;
    }[];
  };
}

const defaultHeaders = {
  "X-Client-Name": "dashboard",
  "X-Client-Version": "2.0.0",
};
export const client = new GraphQLClient("/graphql", {
  credentials: "include",
  headers:
    import.meta.env.DEV && localStorage.getItem("auth")
      ? {
          ...defaultHeaders,
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        }
      : defaultHeaders,
});

const sdk = getSdk(client);

export default sdk;
