import { useEffect } from "react";
import { UserWidget } from "../../widgets/user";

export const UserPage = () => {
  useEffect(() => {
    document.title = "Харилцагчид";
  }, []);
  return (
    <div className="page">
      <UserWidget />
    </div>
  );
};
