import { Dispatch } from "react";
import { useQuery } from "@tanstack/react-query";

import sdk from "@/sdk";
import { Col, Modal, Row } from "antd";
import { formatAmount } from "@/utils/formatters";

type RefundDetailModalProps = {
  show: boolean;
  setShow: Dispatch<boolean>;
  requestIds: string | string[];
};

export function RefundDetailModal({
  setShow,
  show,
  requestIds,
}: RefundDetailModalProps) {
  const { data, isLoading } = useQuery(
    ["previewWithdrawalList", requestIds],
    async () =>
      sdk.previewWithdrawalOrderList({
        withdrawalRequestIds: requestIds,
      }),
    {
      enabled: show,
    }
  );

  return (
    <Modal
      open={show}
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      title="Буцаалтын задаргаа"
    >
      <Col>
        <Row style={{ fontWeight: "bold" }}>Бонд</Row>
        {data?.previewWithdrawalOrderList.bondTotals.map((value, index) => (
          <Row
            key={index}
            style={{
              paddingLeft: 15,
              paddingTop: 5,
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col>{value.asset.name}</Col>
            <Col>{formatAmount({ amount: value.totalAmount })}₮</Col>
          </Row>
        ))}
      </Col>
      <Col style={{ marginTop: 10 }}>
        <Row style={{ fontWeight: "bold" }}>Хувьцаа</Row>
        {data?.previewWithdrawalOrderList.stockTotals.map((value, index) => (
          <Row
            key={index}
            style={{
              paddingLeft: 15,
              paddingTop: 5,
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col>{value.stock.name}</Col>
            <Col>{value.totalQuantity.toFixed(4)}</Col>
          </Row>
        ))}
      </Col>
      <Col style={{ marginTop: 10 }}>
        <Row style={{ fontWeight: "bold" }}>Үл хөдлөх</Row>
        {data?.previewWithdrawalOrderList.estateTotals.map((value, index) => (
          <Row
            key={index}
            style={{
              paddingLeft: 15,
              paddingTop: 5,
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col>{value.asset.bankName}</Col>
            <Col>{formatAmount({ amount: value.totalAmount })}₮</Col>
          </Row>
        ))}
      </Col>
    </Modal>
  );
}
