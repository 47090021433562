import { Form, message } from "antd";
import { useCallback, useState } from "react";
import { OrderType } from "../../../graphql";
import sdk from "../../../sdk";

export const useEditOrder = ({
  selectedOrderId,
  getOrders,
}: {
  selectedOrderId?: string | null;
  getOrders: () => void;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<boolean>(false);

  const addOrder = useCallback(
    (values) => {
      setResult(false);
      setLoading(true);
      sdk
        .addOrder({
          order: Object.assign(values, {
            amount: Number(values.amount),
            type: OrderType.Income,
          }),
        })
        .then(() => {
          getOrders();
          message.success("Амжилттай нэмэгдлээ", 2);
          setLoading(false);
          setResult(true);
          form.resetFields();
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
          setResult(false);
        })
        .finally(() => setLoading(false));
    },
    [form, getOrders]
  );

  // const editOrder = useCallback(
  //   (values) => {
  //     setLoading(true);
  //     if (selectedOrderId) {
  //       sdk
  //         .editOrder({
  //           _id: selectedOrderId,
  //           status: values.status,
  //         })
  //         .then(() => {
  //           getOrders();
  //           message.success("Амжилттай засагдлаа", 2);
  //           setLoading(false);
  //           setResult(true);
  //         })
  //         .catch((err) => {
  //           message.warn(err.response?.errors[0]?.message, 2);
  //           setLoading(false);
  //           setResult(false);
  //         })
  //         .finally(() => setLoading(false));
  //     }
  //   },
  //   [getOrders, selectedOrderId]
  // );

  return {
    form,
    result,
    loading,
    addOrder,
    // editOrder,
  };
};
