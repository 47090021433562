import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useSearchParam(
  key: string
): [string | null, { set: (value: string) => void; unset: () => void }] {
  const history = useHistory();
  const location = useLocation();

  const value = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get(key);
  }, [key, location.search]);

  const set = useCallback(
    (value) => {
      const params = new URLSearchParams(location.search);
      params.set(key, value);
      history.push({ search: params.toString() });
    },
    [key, location]
  );
  const unset = useCallback(() => {
    const params = new URLSearchParams(location.search);
    params.delete(key);
    history.push({ search: params.toString() });
  }, [key, location]);
  return [value, { set, unset }];
}
