import { Col, Row, Tag } from "antd";
import moment from "moment";
import BlueDivider from "../../../../components/BlueDivider";
import { useEditUser } from "../../modal/useEditUser";

export const UserTab = ({ _id }: { _id: string }) => {
  const { userData } = useEditUser({
    selectedUserId: _id,
  });
  return (
    <>
      <Row>
        <Col span={24}>
          <BlueDivider
            text={"Хувийн мэдээлэл"}
            styles={{ marginBottom: "25px", marginTop: "25px" }}
          />
        </Col>
        <Row gutter={24}>
          <Col span={12}>
            <div className="userdetailbox">
              <div>Овог:</div>
              <div>
                {userData?.lastName !== null &&
                userData?.lastName !== undefined &&
                userData.lastName !== "" ? (
                  userData?.lastName
                ) : (
                  <Tag>Мэдээлэл оруулаагүй.</Tag>
                )}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="userdetailbox">
              <div>Нэр:</div>
              <div>
                {userData?.firstName !== null &&
                userData?.firstName !== undefined &&
                userData.firstName !== "" ? (
                  userData?.firstName
                ) : (
                  <Tag>Мэдээлэл оруулаагүй</Tag>
                )}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="userdetailbox">
              <div>Цахим шуудан:</div>
              <div>
                {userData?.email !== null &&
                userData?.email !== undefined &&
                userData.email !== "" ? (
                  userData?.email
                ) : (
                  <Tag>Мэдээлэл оруулаагүй</Tag>
                )}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="userdetailbox">
              <div>Утасны дугаар:</div>
              <div>
                {userData?.phoneNo !== null &&
                userData?.phoneNo !== undefined &&
                userData.phoneNo !== "" ? (
                  "(+976) " + userData?.phoneNo
                ) : (
                  <Tag>Мэдээлэл оруулаагүй</Tag>
                )}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="userdetailbox">
              <div>Хэрэглэгчийн эрх:</div>
              <div>
                {userData?.role !== null && userData?.role !== undefined ? (
                  <Tag>{userData?.role}</Tag>
                ) : (
                  <Tag>Мэдээлэл оруулаагүй.</Tag>
                )}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="userdetailbox">
              <div>Төрсөн огноо:</div>
              <div>
                {userData?.birthDate ? (
                  moment(Number(userData?.birthDate)).format("YYYY/MM/DD")
                ) : (
                  <Tag>Мэдээлэл оруулаагүй.</Tag>
                )}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="userdetailbox">
              <div>Хэрэглэгчийн төрөл:</div>
              <div>
                {userData?.type !== null && userData?.type !== undefined ? (
                  <Tag>{userData?.type}</Tag>
                ) : (
                  <Tag>Мэдээлэл оруулаагүй.</Tag>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <BlueDivider
            text={"Дансны мэдээлэл"}
            styles={{ marginBottom: "32px" }}
          />
        </Col>
        <Col span={12}>
          <div className="userdetailbox">
            <div>Банкны нэр:</div>
            <div>
              {userData?.bankInfo?.bankName !== null &&
              userData?.bankInfo?.bankName !== undefined &&
              userData.bankInfo?.bankName !== "" ? (
                userData?.bankInfo?.bankName
              ) : (
                <Tag>Мэдээлэл оруулаагүй.</Tag>
              )}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="userdetailbox">
            <div>Дансны нэр:</div>
            <div>
              {userData?.bankInfo?.accountName !== null &&
              userData?.bankInfo?.accountName !== undefined &&
              userData.bankInfo?.accountName !== "" ? (
                userData?.bankInfo?.accountName
              ) : (
                <Tag>Мэдээлэл оруулаагүй.</Tag>
              )}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="userdetailbox">
            <div>Дансны дугаар:</div>
            <div>
              {userData?.bankInfo?.accountNo !== null &&
              userData?.bankInfo?.accountNo !== undefined &&
              userData.bankInfo?.accountNo !== "" ? (
                userData?.bankInfo?.accountNo
              ) : (
                <Tag>Мэдээлэл оруулаагүй.</Tag>
              )}
            </div>
          </div>
        </Col>
        <Col span={12}>
          <div className="userdetailbox">
            <div>ҮЦК дансны дугаар:</div>
            <div>{userData?.dbxAccountNumber ?? <Tag>Байхгүй байна</Tag>}</div>
          </div>
        </Col>
      </Row>
    </>
  );
};
