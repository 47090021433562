import { Card } from "antd";
import { Answer } from "../graphql";

const Question = ({
  title,
  answers,
}: {
  title: string;
  answers?: Answer[];
}) => {
  return (
    <Card size="small" title={title} style={{ height: "100%" }}>
      <span className="question_title">Хариултууд</span>
      {answers?.map((item, index) => (
        <p style={{ margin: 0 }}>
          {index + 1}. {item.value}
        </p>
      ))}
    </Card>
  );
};

export default Question;
