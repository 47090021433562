import { useEffect } from "react";
import { useParams } from "react-router-dom";
import KycEditWidget from "../../../widgets/kyc/edit";
export interface KycParamsType {
  _id: string;
}
export const KycDetailsPage = () => {
  const { _id } = useParams<KycParamsType>();
  useEffect(() => {
    document.title = "Хэрэглэгчийн баталгаажуулалт дэлгэрэнгүй";
  }, []);
  return (
    <div className="page">
      <KycEditWidget _id={_id} />
    </div>
  );
};
