import { message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { GetOrderListsQuery, OrderListStatus } from "../../graphql";
import sdk from "../../sdk";

export const useOrderLists = ({ status }: { status: OrderListStatus }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetOrderListsQuery>();
  const [page, setPage] = useState(0);

  const getOrderLists = useCallback(() => {
    setLoading(true);
    sdk
      .getOrderLists({ page, limit: 30, status })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        message.warn(err.response?.errors[0]?.message, 2);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [page, status]);

  useEffect(() => {
    getOrderLists();
  }, [getOrderLists, page]);
  return {
    loading,
    data: data?.orderLists,
    setPage,
    getOrderLists,
  };
};
