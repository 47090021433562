import { Button, Form, Input, Modal } from "antd";
import { Dispatch, useEffect } from "react";
import { Config } from "../../../graphql";
import { useEditConfig } from "./useEditConfig";

export const EditConfigWidget = ({
  config,
  getConfigs,
  setConfig,
}: {
  config: Config | null;
  getConfigs: () => void;
  setConfig: Dispatch<Config | null>;
}) => {
  const { form, loading, editConfig } = useEditConfig({
    selectedConfigId: config?._id!,
    getConfigs,
  });
  useEffect(() => {
    if (config) {
      form.setFields([
        {
          name: "key",
          value: config?.key,
        },
        {
          name: "value",
          value: config?.value,
        },
        {
          name: "description",
          value: config?.description,
        },
      ]);
    }
  }, [form, config]);
  return (
    <Modal
      width={700}
      title="Тохиргоо засах"
      visible={!!config}
      onCancel={() => setConfig(null)}
      footer={[
        <Button key="back" disabled={loading} onClick={() => setConfig(null)}>
          Болих
        </Button>,
        <Button
          key="submit"
          disabled={loading}
          type="primary"
          onClick={() => form.validateFields().then(editConfig)}
        >
          Засах
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={editConfig}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item label="Нэр" name="key">
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item label="Тайлбар" name="description">
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item label="Дүн" name="value">
          <Input disabled={loading} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
