import { Button, Form, Input, Modal, Select } from "antd";
import { Dispatch, useEffect } from "react";
import { ImagePicker } from "../../../components/ImagePicker";
import { Image, Stock, StockType } from "../../../graphql";
import { Option } from "../../user/modal/create";
import { useEditStock } from "../edit/useEditStock";

export const EditStockWidget = ({
  stock,
  getStocks,
  setStock,
}: {
  stock: Stock | null;
  getStocks: () => void;
  setStock: Dispatch<Stock | null>;
}) => {
  const { form, image, loading, editStock, setImage } = useEditStock({
    selectedStockId: stock?._id!,
  });

  useEffect(() => {
    if (stock) {
      form.setFields([
        { name: "name", value: stock.name },
        { name: "symbol", value: stock.symbol },
        { name: "type", value: stock.type },
      ]);
      setImage(stock.image ?? null);
    }
  }, [form, setImage, stock]);

  return (
    <Modal
      width={700}
      title="Үнэт цаас засах"
      visible={!!stock}
      onCancel={() => setStock(null)}
      footer={[
        <Button key="back" disabled={loading} onClick={() => setStock(null)}>
          Болих
        </Button>,
        <Button
          key="submit"
          disabled={loading}
          type="primary"
          onClick={() => form.validateFields().then(editStock)}
        >
          Засах
        </Button>,
      ]}
    >
      <Form
        form={form}
        onFinish={editStock}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Нэр"
          name="name"
          rules={[{ required: true, message: "Нэр оруулна уу" }]}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Symbol"
          name="symbol"
          rules={[{ required: true, message: "Symbol оруулна уу" }]}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Төрөл"
          name="type"
          rules={[{ required: true, message: "Төрөл оруулна уу" }]}
        >
          <Select disabled={loading} loading={loading}>
            {Object.values(StockType).map((stockType) => (
              <Option key={stockType} value={stockType}>
                {stockType}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <ImagePicker
          setImage={setImage}
          image={image}
          handler={(value: Image) => setImage({ path: value.path })}
        />
      </Form>
    </Modal>
  );
};
