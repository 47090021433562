import nodataImgUrl from "@/assets/nodata.png";

export function NoData() {
  return (
    <>
      <img width="250" src={nodataImgUrl} alt="no-data" />
      <p>Мэдээлэл олдсонгүй</p>
    </>
  );
}
