import { useState } from "react";
import ExcelJS from "exceljs";

import { WithdrawalOrderList } from "@/graphql";

const columns = [
  { header: "AssetType", key: "assets" },
  { header: "Symbol", key: "symbol" },
  { header: "AssetName", key: "name" },
  { header: "Quantity", key: "quantity" },
];

export function useGenerateExcel() {
  const [loading, setLoading] = useState(false);

  const generateExcel = async (data: WithdrawalOrderList) => {
    setLoading(true);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Refund");

    worksheet.columns = [
      ...columns.map((column) => ({
        ...column,
        width: Math.max(12, column.header.length),
      })),
    ];
    data.bondTotals.map((bond) => {
      worksheet.addRow({
        assets: "Bond",
        symbol: bond.asset.symbol,
        name: bond.asset.name,
        quantity: bond.totalAmount.toFixed(2),
      });
    });
    data.stockTotals.map((stock) => {
      worksheet.addRow({
        assets: "Stock",
        symbol: stock.stock.symbol,
        name: stock.stock.name,
        quantity: stock.totalQuantity,
      });
    });
    data.estateTotals.map((estate) => {
      worksheet.addRow({
        assets: "Estate",
        symbol: estate.asset.bankName,
        name: estate.asset.bankName,
        quantity: estate.totalAmount,
      });
    });

    const blob = new Blob([await workbook.xlsx.writeBuffer()], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    setLoading(false);
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  return { generateExcel, loading };
}
