import moment from "moment";
import Search from "antd/lib/input/Search";
import { DatePicker, Space } from "antd";
import { PropsWithChildren } from "react";

export function OrderFilters({
  search,
  onSearchChange,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  children,
}: PropsWithChildren<{
  search: string;
  onSearchChange: (value: string) => void;
  startDate: number;
  onStartDateChange: (value: number) => void;
  endDate: number;
  onEndDateChange: (value: number) => void;
}>) {
  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <Space style={{ marginRight: 10, display: "block" }}>
        <p style={{ margin: 0 }}>Хэрэглэгчээр хайх</p>
        <Search
          defaultValue={search}
          placeholder="Хайх утгаа оруулна уу."
          onChange={(event) => onSearchChange(event.target.value)}
        />
      </Space>
      <Space style={{ marginRight: 10, display: "block" }}>
        <p style={{ margin: 0 }}>Эхлэх огноо</p>
        <DatePicker
          allowClear={false}
          value={moment(startDate)}
          onChange={(_, dateString) =>
            onStartDateChange(new Date(dateString).setHours(0, 0, 0, 0))
          }
        />
      </Space>
      <Space style={{ marginRight: 10, display: "block" }}>
        <p style={{ margin: 0 }}>Дуусах огноо</p>
        <DatePicker
          allowClear={false}
          value={moment(endDate)}
          onChange={(_, dateString) =>
            onEndDateChange(new Date(dateString).setHours(23, 59, 59))
          }
        />
      </Space>
      {children}
    </div>
  );
}
