import { Button, Space, Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useConfigs } from "./useConfigs";
import { useState } from "react";
import { Config } from "../../graphql";
import { EditConfigWidget } from "./modal";
import { formatAmount } from "../../utils/formatters";

export const ConfigWidget = () => {
  const [config, setConfig] = useState<Config | null>(null);
  const { loading, data, getConfigs } = useConfigs();
  return (
    <>
      <EditConfigWidget
        config={config}
        setConfig={setConfig}
        getConfigs={getConfigs}
      />
      <div className="page-header">
        <h3>Тохиргооны жагсаалт</h3>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data ?? []}
            size="small"
            loading={loading}
            pagination={false}
            bordered
            columns={[
              {
                title: "Нэр",
                dataIndex: "key",
                key: "key",
                render: (text) => <span>{text}</span>,
              },
              {
                title: "Дүн",
                dataIndex: "value",
                key: "value",
                render: (text) => <span>{formatAmount({ amount: text })}</span>,
              },
              {
                title: "Тайлбар",
                dataIndex: "description",
                key: "description",
                render: (text) => <span>{text}</span>,
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "45px",
                render: (_, record) => {
                  return (
                    <Space>
                      <Button
                        type="primary"
                        shape="circle"
                        disabled={loading}
                        icon={<EditOutlined />}
                        onClick={() => setConfig(record ?? null)}
                      />
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
