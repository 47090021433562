import { Form, message } from "antd";
import { useCallback, useState } from "react";
import sdk from "../../sdk";
import { NullableQuestion } from "./create";

export const useEditSurvey = ({
  selectedSurveyId,
}: {
  selectedSurveyId?: string;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<(NullableQuestion | null)[]>([
    {
      question: null,
      localizedQuestion: {
        en: null,
        mn: null,
      },
      answers: [
        {
          value: null,
          durationPoints: null,
          riskPoints: null,
          localizedValue: {
            en: null,
            mn: null,
          },
        },
      ],
    },
  ]);

  const editSurvey = useCallback(
    (values) => {
      setLoading(true);
      if (selectedSurveyId) {
        sdk
          .editSurvey({
            _id: selectedSurveyId,
            survey: {
              active: values.active,
              name: values.name_mn,
              localizedName: {
                en: values.name_en,
                mn: values.name_mn,
              },
              introText: values.introText_mn,
              localizedIntroText: {
                en: values.introText_en,
                mn: values.introText_mn,
              },
              completeText: values.introText_mn,
              localizedCompleteText: {
                en: values.completeText_en,
                mn: values.completeText_mn,
              },
              questions: questions.map((question) => ({
                question: question?.question!,
                localizedQuestion: question?.localizedQuestion!,
                answers: question?.answers.map((answer) => ({
                  durationPoints: Number(answer?.durationPoints),
                  value: answer?.value!,
                  riskPoints: Number(answer?.riskPoints),
                  localizedValue: answer?.localizedValue!,
                })),
              })),
            },
          })
          .then(() => {
            message.success("Амжилттай засагдлаа", 2);
            setLoading(false);
          })
          .catch((err) => {
            message.warn(err.response?.errors[0]?.message, 2);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    },
    [questions, selectedSurveyId]
  );
  const addSurvey = useCallback(
    (values) => {
      setLoading(true);
      sdk
        .addSurvey({
          survey: {
            active: values.active,
            name: values.name_mn,
            localizedName: {
              en: values.name_en,
              mn: values.name_mn,
            },
            introText: values.introText_mn,
            localizedIntroText: {
              en: values.introText_en,
              mn: values.introText_mn,
            },
            completeText: values.introText_mn,
            localizedCompleteText: {
              en: values.completeText_en,
              mn: values.completeText_mn,
            },
            questions: questions.map((question) => ({
              question: question?.question!,
              localizedQuestion: question?.localizedQuestion!,
              answers: question?.answers.map((answer) => ({
                durationPoints: Number(answer?.durationPoints),
                value: answer?.value!,
                riskPoints: Number(answer?.riskPoints),
                localizedValue: answer?.localizedValue!,
              })),
            })),
          },
        })
        .then(() => {
          message.success("Амжилттай нэмэгдлээ", 2);
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [form, questions]
  );

  return {
    form,
    loading,
    addSurvey,
    editSurvey,
    questions,
    setQuestions,
  };
};
