import { Button, Form, Modal, Select } from "antd";
import { Dispatch } from "react";
import { useCallback } from "react";
import { GetBondsQuery } from "../graphql";
import { BondForm } from "../widgets/orderlist/confirm/bonds";
import { Option } from "../widgets/user/modal/create";

export const ChooseBond = ({
  show,
  setShow,
  bonds,
  submit,
}: {
  show: boolean;
  setShow: Dispatch<boolean>;
  bonds: GetBondsQuery["getBonds"] | undefined;
  submit: (bond: BondForm) => void;
}) => {
  const [form] = Form.useForm();
  const onSubmit = useCallback(
    (values) => {
      const result = bonds?.bonds?.find((bond) => bond._id === values.bond);
      if (result)
        submit({
          _id: result._id!,
          name: result.name,
          symbol: result.symbol,
          interestRate: result.interestRate,
          amount: 0,
          fee: 0,
        });
      form.resetFields();
      setShow(false);
    },
    [bonds, form, setShow, submit]
  );
  return (
    <Modal
      width={500}
      title="Бонд нэмэх"
      visible={show}
      onCancel={() => setShow(false)}
      footer={[
        <Button key="back" onClick={() => setShow(false)}>
          Болих
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.validateFields().then(onSubmit)}
        >
          Нэмэх
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item label="Бонд" name="bond" required>
          <Select>
            {bonds?.bonds?.map((bond) => (
              <Option key={bond._id!} value={bond?._id ?? 0}>
                {bond.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
