import moment from "moment";
import { Form, message } from "antd";
import { useCallback, useEffect, useState } from "react";

import sdk from "../../sdk";

export const useEditSaving = ({
  selectedSavingId,
}: {
  selectedSavingId: string | null;
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const addSaving = useCallback(
    (saving) => {
      setLoading(true);
      sdk
        .addSaving({
          saving: {
            ...saving,
            balance: 0,
            startDate: new Date(saving.startDate).getTime(),
            endDate: new Date(saving.endDate).getTime(),
            interestRate: Number(saving.interestRate),
          },
        })
        .then((result) => {
          if (result) {
            message.success("Амжилттай нэмэгдлээ", 2);
          }
          form.resetFields();
          setLoading(false);
        })
        .catch((err) => {
          message.warn(err.response?.errors[0]?.message, 2);
          setLoading(false);
        })
        .finally(() => setLoading(false));
    },
    [form]
  );

  const getSaving = useCallback(() => {
    setLoading(true);
    if (selectedSavingId) {
      sdk
        .getSaving({ _id: selectedSavingId })
        .then((result) => {
          form.setFields([
            {
              name: "bankName",
              value: result.getSaving.bankName,
            },
            {
              name: "accountNo",
              value: result.getSaving.accountNo,
            },
            {
              name: "currency",
              value: result.getSaving.currency,
            },
            {
              name: "interestRate",
              value: result.getSaving.interestRate,
            },
          ]);
        })
        .catch((err) => {
          setLoading(false);
          message.warn(err.message);
        })
        .finally(() => setLoading(false));
    }
  }, [selectedSavingId, form]);

  const editSaving = useCallback(
    (saving) => {
      setLoading(true);
      if (selectedSavingId) {
        sdk
          .editSaving({
            _id: selectedSavingId,
            saving: Object.assign(saving, {
              balance: Number(saving.balance),
              startDate: new Date(saving.startDate).getTime(),
              endDate: new Date(saving.endDate).getTime(),
              interestRate: Number(saving.interestRate),
            }),
          })
          .then(() => {
            message.success("Амжилттай засагдлаа", 2);

            setLoading(false);
          })
          .catch((err) => {
            message.warn(err.response?.errors[0]?.message, 2);
            setLoading(false);
          })
          .finally(() => setLoading(false));
      }
    },
    [selectedSavingId]
  );

  useEffect(() => {
    if (selectedSavingId) {
      getSaving();
    }
  }, [getSaving, selectedSavingId]);

  return {
    form,
    loading,
    addSaving,
    editSaving,
  };
};
