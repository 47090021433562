import { Space, Button, Table, Tooltip } from "antd";
import { EyeOutlined, HistoryOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { useBalances } from "./useBalances";
import { formatBalance } from "../../utils/formatters";
import Search from "antd/lib/input/Search";
import { debounce } from "ts-debounce";
import { useBalanceDetail } from "./useBalanceDetail";

export const BalanceWidget = () => {
  const { loading, data, setPage, setLimit, limit, setSearch } = useBalances();

  const {
    data: url,
    loading: generating,
    generateBalancesDetail,
  } = useBalanceDetail();

  const handleChange = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearch(value);
    },
    500
  );
  return (
    <>
      <div className="page-header">
        <h3>Дансны жагсаалт</h3>

        <Space style={{ marginRight: 10 }}>
          {url ? (
            <Button>
              <a href={url} download>
                Дэлгэрэнгүй файл татах
              </a>
            </Button>
          ) : (
            <Button
              disabled={loading || generating}
              loading={loading || generating}
              onClick={() => generateBalancesDetail()}
            >
              {generating ? "Түр хүлээнэ үү..." : "Файлын хүсэлт илгээх"}
            </Button>
          )}

          <Search
            disabled={loading}
            loading={loading}
            enterButton
            onChange={handleChange}
            placeholder="Утасны дугаараар хайх"
          />
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data?.balances ?? []}
            size="small"
            loading={loading}
            pagination={{
              pageSize: limit,
              total: Number(data?.totalPages) * limit,
              showSizeChanger: true,
              onChange: (page, pageSize) => {
                setLimit(pageSize!);
                setPage(page - 1);
              },
            }}
            onChange={(pagination) => setPage(Number(pagination?.current) - 1)}
            bordered
            columns={[
              {
                title: "Утасны дугаар",
                dataIndex: "phoneNo",
                key: "phoneNo",
                render: (_, record) => <span>+976 {record.user?.phoneNo}</span>,
              },
              {
                title: "Нийт хөрөнгө",
                dataIndex: "netAssetValue",
                key: "netAssetValue",
                sorter: {
                  compare: (prev, next) =>
                    prev.netAssetValue - next.netAssetValue,
                  multiple: 1,
                },
                render: (text) => (
                  <span>{formatBalance({ balance: text })} ₮</span>
                ),
              },
              {
                title: "Нийт шимтгэл",
                dataIndex: "fee",
                key: "fee",
                render: (text) => (
                  <span>{formatBalance({ balance: text })} ₮</span>
                ),
              },
              {
                title: "Нийт бэлэн мөнгө",
                dataIndex: "cashAmount",
                key: "cashAmount",
                render: (text) => (
                  <span>{formatBalance({ balance: text })} ₮</span>
                ),
              },
              {
                title: "Нийт битүүмжлэгдсэн дүн",
                dataIndex: "holdAmount",
                key: "holdAmount",
                render: (text) => (
                  <span>{formatBalance({ balance: text })} ₮</span>
                ),
              },
              {
                title: "Үнэт цаас",
                dataIndex: "stocksLength",
                key: "stocksLength",
                render: (_, record) => (
                  <span>{record.stocks?.length ?? 0}</span>
                ),
              },
              {
                title: "Бонд",
                dataIndex: "bondsLength",
                key: "bondsLength",
                render: (_, record) => <span>{record.bonds?.length ?? 0}</span>,
              },
              {
                title: "Үл хөдлөх хөрөнгө",
                dataIndex: "savingsLength",
                key: "savingsLength",
                render: (_, record) => (
                  <span>{record.savings?.length ?? 0}</span>
                ),
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "90px",
                render: (_, record) => {
                  return (
                    <Space>
                      <Link to={`balancedetail/${record._id}`}>
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          icon={<EyeOutlined />}
                        />
                      </Link>
                      <Tooltip placement="topRight" title="History">
                        <Link to={`balanceHistory/${record._id}`}>
                          <Button
                            type="primary"
                            shape="circle"
                            disabled={loading}
                            icon={<HistoryOutlined />}
                          />
                        </Link>
                      </Tooltip>
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
