import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { EditStockWidget } from "../../../widgets/stock/edit";
export interface StockParams {
  _id: string;
}
export const EditStockPage = () => {
  const { _id } = useParams<StockParams>();
  useEffect(() => {
    document.title = "Үнэт цаас засах";
  }, []);
  return (
    <div className="page">
      <EditStockWidget _id={_id} />
    </div>
  );
};
