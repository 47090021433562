import { Space, Button, Table, Popconfirm, Tag } from "antd";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { usePortfolios } from "./usePortfolios";

export const PortfolioWidget = () => {
  const { setPage, loading, data, deletePortfolio } = usePortfolios();

  return (
    <>
      <div className="page-header">
        <h3>Багцын задаргаа</h3>
        <Space>
          <Link to="/portfolios/create">
            <Button type="primary" icon={<PlusOutlined />}>
              Багц нэмэх
            </Button>
          </Link>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Table
            scroll={{ x: 900 }}
            dataSource={data?.portfolios ?? []}
            size="small"
            loading={loading}
            pagination={{
              pageSize: 20,
              total: Number(data?.totalPages) * 20,
            }}
            onChange={(pagination) => setPage(Number(pagination?.current) - 1)}
            bordered
            columns={[
              {
                title: "Багцын дугаар",
                dataIndex: "index",
                key: "index",
                sorter: {
                  compare: (prev, next) => prev.index - next.index,
                  multiple: 1,
                },
                render: (_, record) => (
                  <span>
                    {record.index} - {record.localizedName?.mn}
                  </span>
                ),
              },
              {
                title: "Эрсдэлийн түвшин",
                dataIndex: "riskLevel",
                key: "riskLevel",
                sorter: {
                  compare: (prev, next) => prev.riskLevel - next.riskLevel,
                  multiple: 1,
                },
                render: (text) => <span>{text}-р түвшин</span>,
              },
              {
                title: "Хугацаа",
                dataIndex: "duration",
                key: "duration",
                render: (text) => <Tag>{text}</Tag>,
              },
              {
                title: "",
                dataIndex: "button",
                key: "button",
                fixed: "right",
                width: "135px",
                render: (_, record) => {
                  return (
                    <Space
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Link to={`/portfoliodetail/${record._id}`}>
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          icon={<EyeOutlined />}
                        />
                      </Link>
                      <Link to={`/portfolios/${record._id}`}>
                        <Button
                          type="primary"
                          shape="circle"
                          disabled={loading}
                          icon={<EditOutlined />}
                        />
                      </Link>
                      <Popconfirm
                        disabled={loading}
                        title="Та устгахдаа итгэлтэй байна уу?"
                        okText="Тийм"
                        cancelText="Үгүй"
                        onConfirm={() => {
                          if (record._id) {
                            deletePortfolio(record._id);
                          }
                        }}
                      >
                        <Button
                          size="middle"
                          shape="circle"
                          disabled={loading}
                          danger
                          type="primary"
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </Space>
                  );
                },
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
