import { Link } from "react-router-dom";
import {
  Space,
  Button,
  Table,
  Row,
  Col,
  Statistic,
  Tooltip,
  InputNumber,
} from "antd";
import {
  DoubleRightOutlined,
  RightOutlined,
  CloseOutlined,
  DeleteOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import { useCreateOrderList } from "./useCreateOrderList";
import { Balance } from "../../../graphql";
import { PrepareOrderListModal } from "../modal/PrepareOrderListModal";

export const CreateOrderListWidget = () => {
  const {
    data,
    usdRate,
    loading,
    excelData,
    balances,
    setPage,
    setUsdRate,
    setBalances,
    setExcelData,
    createOrderList,
    prepareOrderList,
  } = useCreateOrderList();

  return (
    <>
      <PrepareOrderListModal data={excelData} setData={setExcelData} />
      <div className="page-header">
        <h3>Захиалгын хуудас үүсгэх</h3>
        <Space>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label>Долларын ханш</label>
            <InputNumber
              style={{ marginLeft: 10, minWidth: 150 }}
              prefix="₮"
              value={usdRate}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              onChange={(value) => setUsdRate(Number(value))}
            />
          </div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={createOrderList}
          >
            Захиалгын хуудас үүсгэх
          </Button>
          <Link to="/orderlists">
            <Button type="dashed" icon={<ArrowLeftOutlined />}>
              Буцах
            </Button>
          </Link>
        </Space>
      </div>
      <div className="page-body">
        <div className="page-table">
          <Row>
            <Col span={6}>
              <Statistic title="Хэрэглэгчдийн тоо" value={balances.length} />
            </Col>
            <Col span={6}>
              <Statistic
                title="Нийт мөнгөн дүн"
                value={`${balances
                  .reduce((a, b) => a + b.cashAmount, 0)
                  .toLocaleString("en-US")}₮`}
              />
            </Col>
          </Row>

          <Row style={{ marginTop: "30px", justifyContent: "space-between" }}>
            <Col style={{ width: "49%" }}>
              <Table
                title={() => <Button type="text">Боломжит хэрэглэгчид</Button>}
                dataSource={data?.balances ?? []}
                size="small"
                loading={loading}
                pagination={{
                  pageSize: 40,
                  total: Number(data?.totalPages) * 40,
                  onChange: (page) => {
                    setPage(page - 1);
                  },
                }}
                bordered
                columns={[
                  {
                    title: "Багцын дугаар",
                    dataIndex: "chosenPortfolio",
                    key: "chosenPortfolio",
                    render: (_, record) => (
                      <span>{record.user.chosenPortfolio?.index}</span>
                    ),
                  },
                  {
                    title: "Утасны дугаар эсвэл и-мейл хаяг",
                    dataIndex: "user",
                    key: "user",
                    render: (text) => <span>{text.phoneNo ?? text.email}</span>,
                  },
                  {
                    title: "Бэлэн мөнгө",
                    dataIndex: "cashAmount",
                    key: "cashAmount",
                    render: (text) => (
                      <span>
                        {String(text).replace(
                          /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g,
                          "$1,"
                        )}
                        ₮
                      </span>
                    ),
                  },
                  {
                    title: () => (
                      <Tooltip title="Бүгдийг сонгох">
                        <Button
                          type="primary"
                          disabled={
                            loading ||
                            balances.length === data?.balances?.length
                          }
                          icon={<DoubleRightOutlined />}
                          onClick={() =>
                            setBalances(data?.balances as Balance[])
                          }
                        />
                      </Tooltip>
                    ),
                    dataIndex: "button",
                    key: "button",
                    fixed: "right",
                    width: "45px",
                    render: (_, record) => {
                      return (
                        <Space>
                          <Button
                            type="primary"
                            disabled={
                              loading ||
                              balances.some((value) => value._id === record._id)
                            }
                            icon={<RightOutlined />}
                            onClick={() => {
                              setBalances((value) => [
                                ...value,
                                record as Balance,
                              ]);
                            }}
                          />
                        </Space>
                      );
                    },
                  },
                ]}
              />
            </Col>
            <Col style={{ width: "49%" }}>
              <Table
                title={() => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Button type="text">Сонгосон хэрэглэгчид</Button>
                    <Button
                      type="dashed"
                      icon={<EyeOutlined />}
                      onClick={prepareOrderList}
                    >
                      Задаргаа харах
                    </Button>
                  </div>
                )}
                loading={loading}
                dataSource={balances}
                size="small"
                rowKey={(record) => record._id!}
                pagination={{
                  pageSize: 40,
                  total: Number(data?.totalPages) * 40,
                  onChange: (page) => {
                    setPage(page - 1);
                  },
                }}
                bordered
                columns={[
                  {
                    title: "Багцын дугаар",
                    dataIndex: "chosenPortfolio",
                    key: "chosenPortfolio",
                    render: (_, record) => (
                      <span>{record.user.chosenPortfolio?.index}</span>
                    ),
                  },
                  {
                    title: "Утасны дугаар эсвэл и-мейл хаяг",
                    dataIndex: "user",
                    key: "user",
                    render: (text) => <span>{text.phoneNo ?? text.email}</span>,
                  },
                  {
                    title: "Бэлэн мөнгө",
                    dataIndex: "cashAmount",
                    key: "cashAmount",
                    render: (text) => (
                      <span>
                        {String(text).replace(
                          /(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g,
                          "$1,"
                        )}
                        ₮
                      </span>
                    ),
                  },
                  {
                    title: () => (
                      <Tooltip title="Бүгдийг арилгах">
                        <Button
                          type="primary"
                          danger
                          disabled={loading || balances.length === 0}
                          icon={<DeleteOutlined />}
                          onClick={() => setBalances([])}
                        />
                      </Tooltip>
                    ),
                    dataIndex: "button",
                    key: "button",
                    fixed: "right",
                    width: "45px",
                    render: (_, record) => {
                      return (
                        <Space>
                          <Button
                            type="primary"
                            danger
                            disabled={loading}
                            icon={<CloseOutlined />}
                            onClick={() => {
                              setBalances((value) =>
                                value.filter(
                                  (balance) => balance._id !== record._id
                                )
                              );
                            }}
                          />
                        </Space>
                      );
                    },
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
