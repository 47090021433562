import moment from "moment";
import { Col, Modal, Row, Table } from "antd";

import { formatAccountNo, formatBalance } from "@/utils/formatters";
import { PurchaseDataFragment, PurchaseStockDataFragment } from "@/graphql";

function StocksSection({
  data,
  title = "Үнэт цаас",
}: {
  data: PurchaseStockDataFragment[];
  title?: string | null;
}) {
  return (
    <>
      {title && (
        <Col span={24}>
          <strong>{title}</strong>
        </Col>
      )}
      <Col span={24}>
        <Table
          dataSource={data}
          size="small"
          pagination={false}
          columns={[
            {
              title: "Нэр",
              dataIndex: "name",
              key: "name",
              render: (_, record) => <span>{record.stock.name}</span>,
            },
            {
              title: "Тоо ширхэг",
              dataIndex: "totalAmount",
              key: "totalAmount",
              render: (text) => <span>{text.toFixed(6)}</span>,
            },
            {
              title: "Хулалдаж авсан дүн",
              dataIndex: "totalPrice",
              key: "totalPrice",
              render: (text) => (
                <span>{formatBalance({ balance: text })} ₮</span>
              ),
            },
            {
              title: "Ширхэгийн үнэ",
              dataIndex: "unitPrice",
              key: "unitPrice",
              render: (text) => (
                <span>{formatBalance({ balance: text })} ₮</span>
              ),
            },
            {
              title: "Шимтгэл",
              dataIndex: "fee",
              key: "fee",
              render: (text) => (
                <span>{formatBalance({ balance: text })} ₮</span>
              ),
            },
          ]}
        />
      </Col>
    </>
  );
}

export function HistoryModal({
  data,
  onClose,
}: {
  data: PurchaseDataFragment | null;
  onClose: () => void;
}) {
  const stocks = data?.stocks.filter((stock) => stock.totalAmount > 0) ?? [];
  const previousPurchaseStocks =
    data?.previousPurchases?.map(({ stocks, ...previousPurchase }) => ({
      stocks: stocks.filter((stock) => stock.totalAmount > 0),
      ...previousPurchase,
    })) ?? [];
  return (
    <Modal
      width={1200}
      title="Худалдан авалтын дэлгэрэнгүй"
      open={data !== null}
      onCancel={onClose}
      footer={false}
    >
      <Row gutter={[12, 12]}>
        {stocks.length > 0 && <StocksSection data={stocks} />}
        <Col span={24}>
          <strong>Бонд</strong>
        </Col>
        <Col span={24}>
          <Table
            dataSource={data?.bonds ?? []}
            size="small"
            pagination={false}
            columns={[
              {
                title: "Нэр",
                dataIndex: "name",
                key: "name",
                render: (_, record) => <span>{record.bond.name}</span>,
              },

              {
                title: "Худалдаж авсан дүн",
                dataIndex: "principleAmount",
                key: "principleAmount",
                render: (text) => (
                  <span>{formatBalance({ balance: text })} ₮</span>
                ),
              },
              {
                title: "Шимтгэл",
                dataIndex: "fee",
                key: "fee",
                render: (text) => (
                  <span>{formatBalance({ balance: text })} ₮</span>
                ),
              },
            ]}
          />
        </Col>

        <Col span={24}>
          <strong>Хадгаламж</strong>
        </Col>
        <Col span={24}>
          <Table
            dataSource={data?.savings ?? []}
            size="small"
            pagination={false}
            columns={[
              {
                title: "Банкны нэр",
                dataIndex: "bankName",
                key: "bankName",
                render: (_, record) => <span>{record.saving.bankName}</span>,
              },
              {
                title: "Данс",
                dataIndex: "accountNo",
                key: "accountNo",
                render: (_, record) => (
                  <span>
                    {formatAccountNo({
                      accountNo: record.saving.accountNo,
                    })}
                  </span>
                ),
              },
              {
                title: "Худалдаж авсан дүн",
                dataIndex: "principleAmount",
                key: "principleAmount",
                render: (text) => (
                  <span>{formatBalance({ balance: text })} ₮</span>
                ),
              },
              {
                title: "Шимтгэл",
                dataIndex: "fee",
                key: "fee",
                render: (text) => (
                  <span>{formatBalance({ balance: text })} ₮</span>
                ),
              },
            ]}
          />
        </Col>

        {previousPurchaseStocks.map(
          (previousPurchase) =>
            previousPurchase.stocks.length > 0 && (
              <StocksSection
                key={previousPurchase.createdAt}
                data={previousPurchase.stocks}
                title={`${moment(new Date(previousPurchase.createdAt)).format(
                  "YYYY-MM-DD"
                )}-ны худалдан авалтын үнэт цаас`}
              />
            )
        )}
      </Row>
    </Modal>
  );
}
