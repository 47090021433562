export const formatBalance = ({ balance }: { balance: number }) => {
  if (balance === 0) return "0.00";
  let number = new Intl.NumberFormat().format(balance);
  return splitFormater({ number: number });
};
export const formatInput = ({ balance }: { balance: number }) => {
  if (balance === 0) return "0.00";
  let number = new Intl.NumberFormat().format(balance);
  return number;
};
export const formatAccountNo = ({ accountNo }: { accountNo: string }) => {
  let result = "";
  result = `${result} ${accountNo?.substring(0, 3)} ${accountNo?.substring(
    3,
    6
  )} ${accountNo?.substring(6, 10)}`;
  return result;
};

export const formatAmount = ({
  amount,
  decimals,
}: {
  amount: number;
  decimals?: number;
}) => {
  if (amount) {
    let result = amount.toString();
    if (decimals) {
      const index = result.indexOf(".");
      if (index !== -1) {
        result = result.substring(0, decimals + index + 1);
      } else {
        result = result.substring(0);
      }
    }
    result = result.replace(/\d+/, (n) => {
      return n.replace(/(\d)(?=(\d{3})+$)/g, ($1) => {
        return `${$1},`;
      });
    });
    return splitFormater({ number: result });
  }
  return "0.00";
};

export const splitFormater = ({ number }: { number: string }) => {
  const obj = number?.split(".");
  const text = (
    <>
      <label style={{ fontWeight: 500 }}>{obj[0]}</label>.
      <label style={{ opacity: "0.5" }}>{obj[1] ?? "00"}</label>
    </>
  );
  return text;
};
