import { useEffect } from "react";
// import { StockWidget } from "../../widgets/stock";
import { AddStockWidget } from "../../../widgets/stock/add";
export const AddStockPage = () => {
  useEffect(() => {
    document.title = "Үнэт цаас нэмэх";
  }, []);
  return (
    <div className="page">
      <AddStockWidget />
    </div>
  );
};
