import { useEffect } from "react";
import { KYCWidget } from "../../widgets/kyc";

export const KYCPage = () => {
  useEffect(() => {
    document.title = "KYC";
  }, []);
  return (
    <div className="page">
      <KYCWidget />
    </div>
  );
};
