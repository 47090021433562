import { useEffect } from "react";
import { EditFAQWidget } from "../../widgets/faq/edit";

export const FAQEditPage = () => {
  useEffect(() => {
    document.title = "Түгээмэл асуулт хариулт засах";
  }, []);
  return (
    <div className="page">
      <EditFAQWidget />
    </div>
  );
};
