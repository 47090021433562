import { useEffect } from "react";
import { BalanceWidget } from "../../widgets/balance";

export const BalancePage = () => {
  useEffect(() => {
    document.title = "Данснууд";
  }, []);
  return (
    <div className="page">
      <BalanceWidget />
    </div>
  );
};
